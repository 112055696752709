import { axiosRequest } from '../../axiosRequest';

const adapterPaymentResponse = ({ data, success }) => {
	if (success === false) return { error: true, data };

	return data;
};

export const updatePaymentIntent = async ({
	hash,
	paymentMethodId,
	priceId,
}) => {
	let options = {
		method: 'put',
		url: `stripe/subscription/${hash}`,
		information: {
			paymentMethodId,
			priceId,
		},
	};

	const response = await axiosRequest(options);
	const paymentIntent = adapterPaymentResponse(response);

	return paymentIntent;
};
