import moment from 'moment';
import { axiosRequest } from '../../axiosRequest';

const adapterUserResponse = ({ data, success }) => {
	if (success === false) return { error: true, data };

	let user = {
		information: data.admin,
	};
	let birthDay = user.information.birth_date;
	if (birthDay) {
		user.information.birth_date = moment(birthDay, 'YYYY-MM-DD').format('MM/DD/YYYY');
	}
	return user;
};

export const getUser = async () => {
	const options = {
		method: 'get',
		url: 'dashboard/me',
	};

	const response = await axiosRequest(options);
	const user = adapterUserResponse(response);

	return user;
};
