import React, { useEffect, useState, useContext } from 'react';
import DashboardLayout from '../../components/dashboard/dashboardLayout';
import './applications.scss';
import InputSearch from '../../components/inputs/Search';
import OptionsButtons from '../../components/optionsButtons';
import OptionButton from '../../components/optionsButtons/optionButton';
import ApplicationsList from './applicationsList';
import NobelSelect from '../../components/nobleSelect';
import useRefesh from '../../hooks/useRefresh';
import Loader from '../../components/loader';
import { getApplicationsList } from '../../services/api/applications/getApplications';
import { getTotalApplications } from '../../services/api/applications/getTotalApplications';
import { AppContext, AuthContext } from '../../context';
import { getTierOptions } from '../../services/api/form/getTier';
import { APP_STATUS, PERMISSIONS } from '../../helpers/constants';
import { useNavigate } from 'react-router-dom';
import { userHasPermission } from 'helpers/hasPermission';

function Applications() {
	const {
		applications,
		setApplications,
		applicationsSelected,
		setApplicationsSelected,
		totalApps,
		setTotalApps,
		setLoading,
		loading,
	} = useContext(AppContext);
	const { user } = useContext(AuthContext);

	const navigate = useNavigate();
	useEffect(() => {
		if (user && !userHasPermission(user, PERMISSIONS.ACCESS_TO_APPLICATIONS_PAGE)) {
			if (userHasPermission(user, PERMISSIONS.ACCESS_TO_MEMBERS_PAGE)) navigate('/members');
			if (!userHasPermission(user, PERMISSIONS.ACCESS_TO_MEMBERS_PAGE)) navigate('/access-denied');
		}
	},[user, navigate])

	const [optionSelectedTierFilter, setOptionSelectedTierFilter] =
		useState('all');
	const [searchTerm, setSearchTerm] = useState('');
	const [options, setOptions] = useState([
		{ value: 'all', label: 'All Membership tiers' },
	]);

	useEffect(() => {
		async function getOptions() {
			setLoading(true);
			const tierOptions = await getTierOptions();
			if (!tierOptions.error) {
				setOptions([...options, ...tierOptions]);
			} else {
				console.log(tierOptions.data);
			}
			setLoading(false);
		}
		if (options.length === 1) getOptions();
	}, [options, setOptions, setLoading]);

	useEffect(() => {
		async function getApplications() {
			setLoading(true);
			const applications = await getApplicationsList(APP_STATUS.NEW);
			const totalApps = await getTotalApplications();
			if (!applications.error) {
				setApplications(applications);
				setApplicationsSelected({
					selected: APP_STATUS.NEW,
					applications: applications,
				});
			} else {
				console.log(applications.data);
			}
			if (!totalApps.error) {
				setTotalApps(totalApps);
			} else {
				console.log(totalApps.data);
			}
			setLoading(false);
		}
		if (
			!applicationsSelected.applications.length &&
			applicationsSelected.selected === ''
		) {
			setApplicationsSelected({
				...applicationsSelected,
				selected: APP_STATUS.NEW,
			});
			getApplications();
		}
	}, [
		applicationsSelected,
		setApplicationsSelected,
		setApplications,
		applications,
		setTotalApps,
		setLoading,
	]);

	useRefesh();

	const filteredApplicationsByTier = () => {
		switch (optionSelectedTierFilter) {
			case 'all':
				setApplicationsSelected((prevState) => ({
					selected: prevState.selected,
					applications: [...applications].filter(
						(application) =>
							application.status === applicationsSelected.selected,
					),
				}));
				break;
			default:
				setApplicationsSelected((prevState) => ({
					selected: prevState.selected,
					applications: [...applications].filter(
						(application) =>
							application.tier_type_id === optionSelectedTierFilter &&
							application.status === applicationsSelected.selected,
					),
				}));
				break;
		}
	};

	const searchHandler = (e) => {
		const searchTermValue = e.target.value;
		const searchTermValueLowercase = searchTermValue.toLowerCase();
		setSearchTerm(searchTermValueLowercase);
		if (searchTermValue === '') {
			setApplicationsSelected((prevState) => ({
				selected: prevState.selected,
				applications: applications,
			}));
		}
		if (searchTermValue && searchTerm !== '') {
			setApplicationsSelected((prevState) => ({
				selected: prevState.selected,
				applications: [...applications].filter(
					(application) =>
						(
							application.fullname
								?.toLowerCase()
								.match(searchTermValueLowercase) ||
							application.tier_type
								?.toLowerCase()
								.match(searchTermValueLowercase) ||
							application.application_date
								?.toLowerCase()
								.match(searchTermValueLowercase) ||
							application.update_status_date
								?.toLowerCase()
								.match(searchTermValueLowercase)) &&
						application.status === prevState.selected,
				),
			}));
		} else {
			setApplicationsSelected((prevState) => ({
				selected: prevState.selected,
				applications: prevState.applications.filter(
					(application) => application.status === prevState.selected,
				),
			}));
		}
	};

	useEffect(() => {
		filteredApplicationsByTier();
	}, [optionSelectedTierFilter]);

	const getApplications = async (status) => {
		setLoading(true);
		setSearchTerm('');
		const applications = await getApplicationsList(status);
		if (!applications.error) {
			setApplications(applications);
			setApplicationsSelected({
				selected: status,
				applications: applications,
			});
		} else {
			console.log(applications.data);
		}
		setLoading(false);
	};

	const handleSelectedTierList = (e) => {
		setOptionSelectedTierFilter(e.value);
		return { value: e.value };
	};

	return (
		<DashboardLayout
			layoutClass="Applications"
			layoutTitle="Applications"
			contentTotal={totalApps.total}
			approvalsLeft={totalApps.approvals_left}
			>
			<div className="Applications__menu">
				<OptionsButtons>
					<OptionButton
						onClick={() => getApplications(APP_STATUS.NEW)}
						active={applicationsSelected.selected === APP_STATUS.NEW}>
						New {totalApps.new}
					</OptionButton>
					<OptionButton
						onClick={() => getApplications(APP_STATUS.WAITLIST)}
						active={applicationsSelected.selected === APP_STATUS.WAITLIST}>
						Waitlist {totalApps.waitlist}
					</OptionButton>
					<OptionButton
						onClick={() => getApplications(APP_STATUS.REJECTED)}
						active={applicationsSelected.selected === APP_STATUS.REJECTED}>
						Rejected {totalApps.rejected}
					</OptionButton>
				</OptionsButtons>
				<div className="Applications__menu__filter">
					<NobelSelect
						options={options}
						onChange={(e) => handleSelectedTierList(e)}
					/>
				</div>
				<div className="Applications__menu__search">
					<InputSearch
						id="search"
						type="search"
						name="search"
						placeholder="Search"
						value={searchTerm}
						onChange={(e) => searchHandler(e)}
					/>
				</div>
			</div>
			<ApplicationsList applications={applicationsSelected.applications} />
			{loading && <Loader />}
		</DashboardLayout>
	);
}

export default Applications;
