import React, { useCallback, useEffect, useState, useContext } from 'react';
import DashboardLayout from '../../components/dashboard/dashboardLayout';
import './userProfile.scss';
import InputField from '../../components/inputField';
import CustomDatePicker from '../../components/inputs/CustomDatePicker';
import InputEditProfile from './inputEditProfile';
import Button from '../../components/button';
import Loader from '../../components/loader';
import {
	emailValidation,
	isValidPhoneNumber,
	passwordValidation,
	isValidFullName,
} from '../../services/validations';
import { toast } from 'react-toastify';
import useRefesh from '../../hooks/useRefresh';
import { AuthContext } from '../../context/AuthContext';
import { AppContext } from '../../context';
import { updateUser } from '../../services/api/user/updateUser';
import { AsYouType } from 'libphonenumber-js';
import { formattedPhoneNumber, validatePhoneNumberUSA } from '../../helpers/formattedPhoneNumber';
import InputPhoneNumber from '../../components/inputs/PhoneNumber';

const toastOptions = {
	position: 'bottom-center',
	autoClose: 7000,
	hideProgressBar: true,
	closeOnClick: true,
	pauseOnHover: true,
	draggable: true,
	progress: undefined,
	theme: 'dark',
};

function UserProfile() {
	const { user, setUser } = useContext(AuthContext);
	const { setLoading, loading } = useContext(AppContext);
	// STATES
	const [enabledEdit, setEnabledEdit] = useState([
		{
			value: false,
			id: 'password',
		},
		{
			value: false,
			id: 'email',
		},
	]);
	const [isFormValid, setIsFormValid] = useState(false);
	const [userProfileForm, setUserProfileForm] = useState({
		full_name: {
			value: undefined,
			valid: undefined,
		},
		birth_day: {
			value: undefined,
			valid: undefined,
		},
		phone_number: {
			value: undefined,
			valid: undefined,
		},
		email: { value: undefined, valid: undefined },
		password: { value: undefined, valid: undefined },
	});

	useRefesh();
	useEffect(() => {
		if (user && userProfileForm.full_name.value === undefined) {
			setUserProfileForm({
				...userProfileForm,
				full_name: {
					value: user.full_name,
					valid: true,
				},
				birth_day: {
					value: user.birth_date ? user.birth_date : undefined,
					valid: user.birth_date ? true : undefined,
				},
				phone_number: {
					value: user.phone_number ? formattedPhoneNumber(user.phone_number) : undefined,
					valid: user.phone_number ? validatePhoneNumberUSA(user.phone_number) : undefined,
				},
				email: { value: user.email, valid: true },
			});
		}
	}, [user, userProfileForm, setUserProfileForm]);

	// FUNCTION SET AND SEND
	const userProfileSetValues = (e) => {
		let fieldName = e.target.name;
		let fieldValue = e.target.value;

		if (fieldName === 'phone_number') {
			// Check if the field is cleared
			if (fieldValue === '') {
				// Handle the case when the field is cleared
				fieldValue = '';
			} else {
				// Try to parse the input as a phone number
				const parsedNumber = new AsYouType('US').input(fieldValue);

				// Check if the parsed number is valid
				if (fieldValue.length > 6) {
					fieldValue = parsedNumber;
				}
			}
		}
		setUserProfileForm((prevState) => ({
			...prevState,
			[fieldName]: {
				value: fieldValue,
				valid: validateField(fieldName, fieldValue),
			},
		}));
	};

	const setDataPicker = (newDate) => {
		setUserProfileForm((prevState) => ({
			...prevState,
			birth_day: { value: newDate, valid: true },
		}));
	};

	const userProfileSumbit = async (e) => {
		e.preventDefault();
		if (isFormValid) {
			setLoading(true);
			const user = await updateUser(userProfileForm);
			if (!user.error) {
				setUser(user.information);
			} else {
				console.log(user.data);
			}
			setLoading(false);
			notifyApplication('success');
		}
	};

	const notifyApplication = (notificationType) => {
		switch (notificationType) {
			case 'success':
				toast.success(
					`User: ${userProfileForm?.full_name?.value} was correctly modified`,
					toastOptions,
				);
				break;
			case 'error':
				toast.error(
					`User: ${userProfileForm?.full_name?.value} could not be edited`,
					toastOptions,
				);
				break;
			default:
				break;
		}
	};

	// VALIDATIONS
	const validateField = (fieldName, value) => {
		switch (fieldName) {
			case 'email':
				return emailValidation(value);
			case 'password':
				return passwordValidation(value);
			case 'full_name':
				return isValidFullName(value);
			case 'phone_number':
				return value && validatePhoneNumberUSA(value);
			default:
				return undefined;
		}
	};

	const validationInputs = (isDisabledEditButton, objectIsValid) => {
		const { email, password, full_name, phone_number } = objectIsValid;
		let fieldErrors = [];

		if (isDisabledEditButton) {
			if (email.isEnabled && !email.isValid) fieldErrors.push('email');
			if (password.isEnabled && !password.isValid) fieldErrors.push('password');
		}

		if (full_name.isEnabled && !full_name.isValid) fieldErrors.push('fullname');

		// Optional fields
		if (
			userProfileForm.phone_number.value &&
			phone_number.isEnabled &&
			!phone_number.isValid
		)
			fieldErrors.push('phone number');
		
		if (fieldErrors.length) return setIsFormValid(false);
		return setIsFormValid(true);
	};

	// MEMOIZE BUTTONACTIVED VALIDATE
	const buttonActivated = useCallback(() => {
		const isEnabledInputsEdit = enabledEdit.some((edit) => edit.value);

		const isEnabled = (field) =>
			enabledEdit.some((edit) => edit.id === field && edit.value);

		const isFieldDisabled = (fieldValid) => fieldValid !== undefined;

		const objectValidations = {
			email: {
				isValid: userProfileForm.email.valid,
				isEnabled: isEnabled('email'),
			},
			password: {
				isValid: userProfileForm.password.valid,
				isEnabled: isEnabled('password'),
			},
			full_name: {
				isValid: userProfileForm.full_name.valid,
				isEnabled: isFieldDisabled(userProfileForm.full_name.valid),
			},
			phone_number: {
				isValid: userProfileForm.phone_number.valid,
				isEnabled: isFieldDisabled(userProfileForm.phone_number.valid),
			},
		};

		validationInputs(isEnabledInputsEdit, objectValidations);
	}, [enabledEdit, userProfileForm]);

	// USEEFFCETS
	useEffect(() => {
		buttonActivated();
	}, [buttonActivated]);

	return (
		<DashboardLayout layoutClass="UserProfile" layoutTitle="Edit Profile">
			<form id="editProfileForm" className="UserProfile__form">
				<InputEditProfile
					displayLabel="Email"
					info={
						userProfileForm.email.value
							? userProfileForm.email.value
							: 'useremail@email.com'
					}
					setEnabledEdit={setEnabledEdit}
					enabledEdit={enabledEdit}
					value={userProfileForm.email.value}
					onChange={userProfileSetValues}
					name="email"
					id="email"
					isValid={userProfileForm.email.valid === false ? true : false}
				/>
				{userProfileForm.email.valid === false && (
					<small className="UserProfile__form__section__input-error">
						Please enter a valid email
					</small>
				)}

				<InputEditProfile
					displayLabel="Password"
					info="******************"
					setEnabledEdit={setEnabledEdit}
					enabledEdit={enabledEdit}
					value={userProfileForm.password.value}
					onChange={userProfileSetValues}
					name="password"
					id="password"
					isValid={userProfileForm.password.valid === false ? true : false}
				/>
				{userProfileForm.password.valid === false && (
					<small className="UserProfile__form__section__input-error">
						Please enter a valid password
					</small>
				)}

				<InputField
					displayLabel="Full Name"
					id="full_name"
					name="full_name"
					type="text"
					onChange={userProfileSetValues}
					onBlur={userProfileSetValues}
					defaultValue={userProfileForm.full_name.value}
					value={userProfileForm.full_name.value}
					autoComplete="off"
					inputError={userProfileForm.full_name.valid === false ? true : false}
				/>
				{userProfileForm.full_name.valid === false && (
					<small className="UserProfile__form__section__input-error">
						Please enter a valid full name
					</small>
				)}

				<CustomDatePicker
					id="birth_day"
					name="birth_day"
					label="BirthDay"
					onChange={setDataPicker}
					value={userProfileForm.birth_day.value}
				/>
				{userProfileForm.birth_day.valid === false && (
					<small className="UserProfile__form__section__input-error">
						Please enter a valid birth day
					</small>
				)}

				<InputPhoneNumber
					displayLabel="Phone"
					id="phone_number"
					name="phone_number"
					onChange={userProfileSetValues}
					onBlur={userProfileSetValues}
					value={userProfileForm.phone_number?.value}
					autoComplete="off"
					inputError={
						userProfileForm.phone_number.valid === false ? true : false
					}
				/>
				{userProfileForm.phone_number.valid === false && (
					<small className="UserProfile__form__section__input-error">
						Please enter a valid phone number
					</small>
				)}

				<Button
					displayLabel="Save Changes"
					color="white"
					size="full"
					type="submit"
					buttonValidation={isFormValid}
					onClick={userProfileSumbit}
				/>
			</form>
			{loading && <Loader />}
		</DashboardLayout>
	);
}

export default UserProfile;
