// url dashboard/member/:memberId (delete)

import { axiosRequest } from '../../axiosRequest';

const adapterDeleteMemberResponse = ({ data, success }) => {
	if (success === false) return { error: true, data };

	return data;
};

export const deleteMemberById = async (id) => {
	const options = {
		method: 'delete',
		url: `dashboard/member/${id}`,
	};
	
	const response = await axiosRequest(options);
	const deletedMember = adapterDeleteMemberResponse(response);

	return deletedMember;
};
