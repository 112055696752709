import React, { useContext, useEffect, useState } from 'react';
import InputField from '../../../inputField';
import Button from '../../../button';
import {
	emailValidation,
	isValidBirthDate,
	isValidPhoneNumber,
	requiredValidation,
	stringMaxLength,
	hasNoNumbersAndSpecialCharacters,
	validMemberNumber,
} from '../../../../services/validations';
import { toast } from 'react-toastify';
import { AppContext, AuthContext } from '../../../../context';

import './editSpouseInfo.scss';
import CustomDatePicker from '../../../inputs/CustomDatePicker/index.jsx';
import { updateSpouseById } from 'services/api/spouses/updateMember';
import {
	AsYouType,
	isValidNumber as isValidNumberLibJs,
} from 'libphonenumber-js';
import {
	deformatPhoneNumber,
	formattedPhoneNumber,
} from '../../../../helpers/formattedPhoneNumber.js';
import FormDisplayImage from '../../../formDisplayImage/index.js';
import InputPhoneNumber from '../../../inputs/PhoneNumber/index.jsx';
import { PERMISSIONS } from 'helpers/constants';
import { userHasPermission } from 'helpers/hasPermission';

const toastOptions = {
	position: 'bottom-center',
	autoClose: 7000,
	hideProgressBar: true,
	closeOnClick: true,
	pauseOnHover: true,
	draggable: true,
	progress: undefined,
	theme: 'dark',
};

function EditSpouseInfo({ onRequestClose, ...rest }) {
	const [isFormValid, setIsFormValid] = useState(false);

	const [dataLoaded, setDataLoaded] = useState(false);

	const { member, setRefetchMembers, setLoading,  } =
		useContext(AppContext);
	const { user } = useContext(AuthContext);

	const typeErrors = {
		phoneNumber: 'Phone Number',
		firstName: 'First Name',
		lastName: 'Last Name',
		birthDate: 'Birthday',
		email: 'Email',
		memberNumber: 'Member Number',
	};

	const [editMemberForm, setEditMemberForm] = useState({
		firstName: {
			value: member?.first_name || '',
			valid: undefined,
			change: false,
		},
		lastName: {
			value: member?.last_name || '',
			valid: undefined,
			change: false,
		},
		birthDate: {
			value: member?.birth_date || '',
			valid: undefined,
			change: false,
		},
		phoneNumber: {
			value: member?.phone_number || '',
			valid: undefined,
			change: false,
		},
		email: { value: member?.email || '', valid: undefined, change: false },
		profilePictureUrl: {
			value:
				member?.profile_picture_url ||
				'https://i.pinimg.com/736x/c0/74/9b/c0749b7cc401421662ae901ec8f9f660.jpg',
			valid: undefined,
			change: false,
		},
		memberNumber: { value: member?.member_number || '', valid: undefined, change: false },
	});


	// use effect validations
	let formErrors = [];
	const addError = (typeError) => {
		formErrors = [...formErrors, [`Error in ${typeError} field.`]];
	};

	const validateForm = () => {
		formErrors = [];
		if (formErrors.length) return;
		// Required fields
		if (!editMemberForm.firstName.valid) addError(typeErrors.firstName);
		if (!editMemberForm.lastName.valid) addError(typeErrors.lastName);
		if (!editMemberForm.email.valid) addError(typeErrors.email);
		// Optional fields
		if (editMemberForm.birthDate.value && !editMemberForm.birthDate.valid) 
			addError(typeErrors.birthDate);
		if (editMemberForm.phoneNumber.value && !editMemberForm.phoneNumber.valid)
			addError(typeErrors.phoneNumber);
		if (editMemberForm.memberNumber.value && !editMemberForm.memberNumber.valid)
			addError(typeErrors.memberNumber);

		if (!editMemberForm.phoneNumber.value) addError(typeErrors.phoneNumber);
		if (formErrors.length) {
			return setIsFormValid(false);
		}
		setIsFormValid(true);
	};

	useEffect(() => {
		validateForm();
	}, [editMemberForm, setIsFormValid]);

	useEffect(() => {
		if (!dataLoaded && member) {
			setEditMemberForm((prev) => ({
				...prev,
				firstName: {
					value: member.first_name,
					valid:
						requiredValidation(member.first_name) &&
						stringMaxLength(member.first_name, 100),
					change: false,
				},
				lastName: {
					value: member.last_name,
					valid:
						requiredValidation(member.last_name) &&
						stringMaxLength(member.last_name, 100),
					change: false,
				},
				birthDate: {
					value: member.birth_date || '',
					valid: isValidBirthDate(member.birth_date),
					change: false,
				},
				phoneNumber: {
					value: formattedPhoneNumber(member.phone_number) || '',
					valid: isValidPhoneNumber(member.phone_number),
					change: false,
				},
				email: {
					value: member.email || '',
					valid: emailValidation(member.email),
					change: false,
				},
				memberNumber: {
					value: member.member_number,
					valid:
						member.member_number &&
						validMemberNumber(member?.member_number) &&
						stringMaxLength(member?.member_number, 7),
					change: false,
				},
			}));
			setDataLoaded(true);
		}
	}, [
		member,
		dataLoaded,
	]);

	const editMemberSetValues = (e) => {
		const fieldName = e.target.name;
		let fieldValue = e.target.value;
		const regularFields = {
		};
		if (fieldName === 'phoneNumber') {
			// Check if the field is cleared
			if (fieldValue === '') {
				// Handle the case when the field is cleared
				fieldValue = '';
			} else {
				// Try to parse the input as a phone number
				const parsedNumber = new AsYouType('US').input(fieldValue);

				// Check if the parsed number is valid
				if (isValidNumberLibJs(parsedNumber, 'US')) {
					fieldValue = parsedNumber;
				}
			}
		}

		if (fieldName[regularFields]) validateField(fieldName, fieldValue);

		setEditMemberForm((prev) => ({
			...prev,
			[fieldName]: {
				value: fieldValue,
				valid:
					requiredValidation(fieldValue) &&
					validateField(fieldName, fieldValue),
				change: true,
			},
		}));
	};

	const setDataPicker = (newDate) => {
		setEditMemberForm((prevState) => ({
			...prevState,
			birthDate: {
				value: newDate,
				valid:
					requiredValidation(newDate) && validateField('birthDate', newDate),
				change: true,
			},
		}));
	};

	const editMemberSubmit = async (e) => {
		e.preventDefault();
		validateForm();
		if (isFormValid) {
			if (!userHasPermission(user, PERMISSIONS.CAN_EDIT_MEMBERS))
			return toast.error(
				`Your role does not have permission for this action.`,
				toastOptions,
			);
			setLoading(true);
			const updatedMember = await updateSpouseById(member?.id, editMemberForm);
			if (!updatedMember.error) {
				setRefetchMembers(true);
				notifyAdmin('success');
				onRequestClose();
			} else {
				notifyAdmin('error-data', updatedMember.data.response_description);
			}
			setLoading(false);
		} else {
			notifyAdmin('error');
		}
	};

	const notifyAdmin = (notificationType, data) => {
		switch (notificationType) {
			case 'success':
				toast.success(
					`${editMemberForm.firstName.value} ${editMemberForm.lastName.value} was correctly edited.`,
					toastOptions,
				);
				break;
			case 'error':
				toast.error(
					`${
						formErrors.length ? formErrors[0] : ''
					} Could not edit member user. Please correct the error and try again.`,
					toastOptions,
				);
				break;
			case 'error-data':
				toast.error(data, toastOptions);
				break;
			default:
				break;
		}
	};

	// VALIDATIONS
	const validateField = (fieldName, value) => {
		switch (fieldName) {
			case 'email':
				return (
					requiredValidation(value) &&
					emailValidation(value) &&
					stringMaxLength(value, 80)
				);
			case 'firstName':
				return (
					requiredValidation(value) &&
					hasNoNumbersAndSpecialCharacters(value) &&
					stringMaxLength(value, 100)
				);
			case 'lastName':
				return (
					requiredValidation(value) &&
					hasNoNumbersAndSpecialCharacters(value) &&
					stringMaxLength(value, 100)
				);
			case 'phoneNumber':
				return isValidPhoneNumber(deformatPhoneNumber(value));
			case 'birthDate':
				return isValidBirthDate(value);
			case 'memberNumber':
				return validMemberNumber(value) && stringMaxLength(value, 7);
			default:
				return undefined;
		}
	};

	return (
		<div className="EditMemberInfo" {...rest}>
			<form className="EditMemberInfo__form">
				<div className="EditMemberInfo__form__section">
						<FormDisplayImage
							url={editMemberForm?.profilePictureUrl?.value}
							disabled={true}
						/>
					<div className="section-dual">
						<InputField
							id="memberNumber"
							name="memberNumber"
							displayLabel="Member Number"
							onChange={editMemberSetValues}
							onBlur={editMemberSetValues}
							value={editMemberForm?.memberNumber.value || ''}
							inputError={
								editMemberForm?.memberNumber.valid === false ? true : false
							}
						/>
					</div>
					<div className="section-dual">
						<InputField
							id="firstName"
							name="firstName"
							displayLabel="First Name*"
							onChange={editMemberSetValues}
							onBlur={editMemberSetValues}
							value={editMemberForm?.firstName.value || ''}
							inputError={
								editMemberForm?.firstName.valid === false ? true : false
							}
						/>
						<InputField
							id="lastName"
							name="lastName"
							displayLabel="Last Name*"
							onChange={editMemberSetValues}
							onBlur={editMemberSetValues}
							value={editMemberForm?.lastName.value || ''}
							inputError={
								editMemberForm?.lastName.valid === false ? true : false
							}
						/>
					</div>
				</div>
				<div className="EditMemberInfo__form__section">
					<InputField
						id="email"
						name="email"
						displayLabel="Email*"
						onChange={editMemberSetValues}
						onBlur={editMemberSetValues}
						value={editMemberForm?.email.value || ''}
						inputError={editMemberForm?.email.valid === false ? true : false}
					/>
					<InputPhoneNumber
						id="phoneNumber"
						name="phoneNumber"
						displayLabel="Phone Number*"
						onChange={editMemberSetValues}
						value={editMemberForm?.phoneNumber.value || ''}
						inputError={
							editMemberForm?.phoneNumber.valid === false ? true : false
						}
					/>
					<CustomDatePicker
						id="birthDate"
						name="birthDate"
						label="Birthday*"
						onChange={setDataPicker}
						value={editMemberForm?.birthDate.value || ''}
						inputError={
							editMemberForm?.birthDate.valid === false ? true : false
						}
					/>
				</div>
				<div className="EditMemberInfo__form__section buttons_section">
					<Button
						buttonWrapperClass="cancel"
						displayLabel="Cancel"
						color="black"
						size="full"
						onClick={onRequestClose}
					/>
					<Button
						buttonWrapperClass="save"
						displayLabel="Save"
						color="white"
						size="full"
						onClick={(e) => editMemberSubmit(e)}
					/>
				</div>
			</form>
		</div>
	);
}

export default EditSpouseInfo;
