import React from 'react';
import './loader.scss';

export default function Loader() {
	return (
		<div className="sub-loader" aria-label="Loading" role="alert" aria-busy="true">
			<div className="loadingio-spinner-rolling-3nwocq145a2">
				<div className="ldio-kiv8subw9ue">
					<div></div>
				</div>
			</div>
		</div>
	);
}
