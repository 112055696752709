import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../components/button';
import InputField from '../../components/inputField';
import LoginLayout from '../../components/loginLayout';
import Loader from '../../components/loader';
import {
	requiredValidation,
	passwordValidation,
} from '../../services/validations';
import './resetPassword.scss';
import { AppContext } from '../../context';
import { resetPassword } from '../../services/api/admins';
import { validateForgotCode } from '../../services/api/admins/validateForgotCode';

function ResetPassword() {
	const { loading, setLoading } = useContext(AppContext);
	const [isFormValid, setIsFormValid] = useState(false);
	const [passwordChanged, setPasswordChanged] = useState(false);
	const [resetForm, setResetForm] = useState({
		password: { value: undefined, valid: undefined },
	});
	const navigate = useNavigate();

	const { code } = useParams();

	useEffect(() => {
		if (resetForm.password.valid) {
			setIsFormValid(true);
		} else {
			setIsFormValid(false);
		}
	}, [resetForm, code, navigate]);

	useEffect(() => {
		if (code) {
			(async () => {
				setLoading(true);
				const validate = await validateForgotCode(code);
				setLoading(false);
				if (validate.error) {
					navigate('/login');
				}
			})();
		}
	}, [code, navigate, setLoading]);

	const resetSetValues = (e) => {
		const fieldName = e.target.name;
		const fieldValue = e.target.value;
		setResetForm((prevState) => ({
			...prevState,
			[e.target.name]: {
				value: e.target.value,
				valid:
					fieldName === 'password' &&
					requiredValidation(fieldValue) &&
					passwordValidation(fieldValue),
			},
		}));
	};

	const resetFieldOnFocus = (e) => {
		const fieldName = e.target.name;
		const fieldValue = e.target.value;
		setResetForm((prevState) => ({
			...prevState,
			[fieldName]: { valid: undefined, value: fieldValue },
		}));
	};

	const resetSubmit = async (e) => {
		e.preventDefault();
		if (isFormValid) {
			setLoading(true);
			const reset = await resetPassword({
				password: resetForm.password.value,
				resetCode: code,
			});
			if (!reset.error) {
				setPasswordChanged(true);
			}
			setLoading(false);
		}
	};

	return (
		<LoginLayout layoutClass="ResetPassword" layoutTitle="Enter a new Password">
			{!passwordChanged && (
				<>
					<form>
						<InputField
							displayLabel="Create Password"
							id="password"
							name="password"
							type="password"
							onChange={resetSetValues}
							onBlur={resetSetValues}
							onFocus={resetFieldOnFocus}
							autoComplete="off"
						/>
						{resetForm.password.valid === false && (
							<small className="inputError">
								Must be at least 8 characters
							</small>
						)}
						<Button
							displayLabel="Reset Password"
							color="white"
							size="full"
							type="submit"
							buttonValidation={isFormValid}
							onClick={resetSubmit}
						/>
					</form>
					<a className="ResetPassword__link" href="/login">
						Login
					</a>
				</>
			)}
			{passwordChanged && (
				<>
					<p className="ResetPassword__text">
						You set up a new password for your account.
					</p>
					<Button
						displayLabel="Login"
						color="white"
						size="full"
						type="submit"
						onClick={() => navigate('/login')}
					/>
				</>
			)}
			<p className="ResetPassword__text">
				If you encounter any issues, please contact your manager.
			</p>
			{loading && <Loader />}
		</LoginLayout>
	);
}

export default ResetPassword;
