import React from 'react';
import cn from 'classnames';
import ImgWithFallback from '../../components/img';
import DashboardLogo from '../../assets/images/logo_dashboard.webp';
import DashboardLogoPNG from '../../assets/images/logo_dashboard.png';
import './loginLayout.scss';
import config from "../../config"

function LoginLayout({
	layoutTitle = 'Admin Dashboard',
	layoutClass = '',
	children,
}) {
	const urlViedo = `${config.CDN_MEDIA}login_video.mp4`;
	return (
		<div className={cn(['LoginLayout', { [layoutClass]: layoutClass }])}>
			<div className="LoginLayout__mainBox">
				<div className="LoginLayout__logo-and-title">
					<ImgWithFallback img={DashboardLogo} fallbackImg={DashboardLogoPNG} />
					<p>{layoutTitle}</p>
				</div>
				{children}
			</div>
			<div className="LoginLayout__video">
				<video
					autoPlay
					poster="/bg-video-posterimage.jpg"
					loop="loop"
					muted="muted"
					volume="0"
					preload="none">
					<source src={urlViedo} type="video/mp4" />
				</video>
			</div>
		</div>
	);
}

export default LoginLayout;
