import { axiosRequest } from '../../axiosRequest';

const adapterMembershipResponse = ({ data, success }) => {
	if (success === false) return { error: true, data };

	return data;
};

export const getMembership = async ({ hash }) => {
	let options = {
		method: 'get',
		url: `stripe/membership/${hash}`,
	};

	const response = await axiosRequest(options);
	const membership = adapterMembershipResponse(response);

	return membership;
};
