import { axiosRequest } from '../../axiosRequest';

const adapterResetPasswordResponse = ({ success, data }) => {
	if (success === false) return { error: true, data };

	return data;
};

// url dashboard/reset-password (post)
export const resetPassword = async (resetPasswordForm) => {
	const options = {
		method: 'put',
		url: '/dashboard/reset-password',
		information: resetPasswordForm,
	};

	const response = await axiosRequest(options);

	const resetPasswordResponse = adapterResetPasswordResponse(response);

	return resetPasswordResponse;
};
