import moment from 'moment';
import { axiosRequest } from '../../axiosRequest';

const adapterDiningHistory = ({ success, data }) => {
	if (success === false) return { error: true, data };

	const { diningHistory } = data;
	if (!diningHistory) return [];

	diningHistory.forEach((history) => {
		const date = moment(history.date);
		const formatDate = date.format('MM/DD/YY');
		history.date = formatDate;
	});

	return diningHistory;
};

export const getDiningHistoryByid = async (id, isSpouse) => {
	const pathUrl =
		isSpouse === undefined
			? `/dashboard/member/${id}/dining-history`
			: `/dashboard/member/${id}/${isSpouse}/dining-history`;
	const options = {
		method: 'get',
		url: pathUrl,
	};

	const response = await axiosRequest(options);
	const dinningHistory = adapterDiningHistory(response);

	return dinningHistory;
};
