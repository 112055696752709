import React, { useContext, useEffect, useState } from 'react';
import LoginLayout from '../../components/loginLayout';
import InputField from '../../components/inputField';
import Button from '../../components/button';
import Loader from '../../components/loader';
import {
	emailValidation,
	passwordValidation,
	requiredValidation,
} from '../../services/validations';
import { useNavigate, useParams } from 'react-router-dom';
import { AppContext, AuthContext } from '../../context';
import { login } from '../../services/api/user/login';
import {
	setPassword,
	getAdminEmailByResetCode,
} from '../../services/api/admins';
import { getUser } from '../../services/api/user/getUser';

import './CreatePasswordAdmin.scss';

function CreatePasswordAdmin() {
	const [isFormValid, setIsFormValid] = useState(false);
	const [createPasswordForm, setCreatePasswordForm] = useState({
		email: { value: undefined, valid: undefined },
		password: { value: undefined, valid: undefined },
	});
	const { code } = useParams();
	const { accessToken, setAccessToken, user, setUser } =
		useContext(AuthContext);
	const { loading, setLoading } = useContext(AppContext);
	const navigate = useNavigate();	

	useEffect(() => {
		if (!accessToken && code) {
			async function getEmailByResetCode() {
				const email = await getAdminEmailByResetCode(code);
				if (!email.error) {
					setCreatePasswordForm((prev) => ({
						...prev,
						email: {
							value: email,
							valid: requiredValidation(email) && emailValidation(email),
						},
					}));
				} else {
					navigate('/login');
				}
			}
			getEmailByResetCode();
		}
		if (accessToken && !user) {
			async function getUserData() {
				setLoading(true);
				const user = await getUser();
				if (!user.error) {
					setUser(user.information);
				} else {
					console.log(user.data);
				}
				setLoading(false);
			}
			getUserData();
		}
		if (accessToken && user) {
			navigate(`/applications`);
		}
	}, [
		accessToken,
		user,
		setUser,
		setLoading,
		navigate,
		code,
		setCreatePasswordForm,
	]);

	useEffect(() => {
		if (createPasswordForm.email.valid && createPasswordForm.password.valid) {
			setIsFormValid(true);
		} else {
			setIsFormValid(false);
		}
	}, [createPasswordForm]);

	const createPasswordSetValues = (e) => {
		const fieldName = e.target.name;
		const fieldValue = e.target.value;
		if (fieldName === 'email') {
			setCreatePasswordForm((prevState) => ({
				...prevState,
				[fieldName]: {
					value: fieldValue,
					valid: requiredValidation(fieldValue) && emailValidation(fieldValue),
				},
			}));
		}
		if (fieldName === 'password') {
			setCreatePasswordForm((prevState) => ({
				...prevState,
				[fieldName]: {
					value: fieldValue,
					valid:
						requiredValidation(fieldValue) && passwordValidation(fieldValue),
				},
			}));
		}
	};

	const createPasswordFieldOnFocus = (e) => {
		const fieldName = e.target.name;
		const fieldValue = e.target.value;
		setCreatePasswordForm((prevState) => ({
			...prevState,
			[fieldName]: { valid: prevState.valid, value: fieldValue },
		}));
	};

	const createPasswordSubmit = async (e) => {
		e.preventDefault();
		if (isFormValid) {
			setLoading(true);
			const password = await setPassword(createPasswordForm, code);
			if (!password.error) {
				const user = await login(createPasswordForm);
				if (!user.error) {
					setAccessToken(user.token);
					setUser(user.information);
				} else {
					console.log(user.data);
				}
			} else {
				console.log('ERROR: ', password.data);
			}
			setLoading(false);
		} else {
			console.log('INVALID FORM');
		}
		return;
	};

	const onHandleChange = (e) => {
		let emailValue = e.target.value;
		setCreatePasswordForm((prev) => ({
			...prev,
			email: {
				value: emailValue,
				valid: requiredValidation(emailValue) && emailValidation(emailValue),
			},
		}));
	};

	return (
		<LoginLayout layoutClass="CreatePassword">
			<form id="loginForm" className="CreatePassword__form">
				<InputField
					displayLabel="Email"
					id="email"
					name="email"
					type="email"
					value={createPasswordForm.email.value || ''}
					onChange={onHandleChange}
					onBlur={createPasswordSetValues}
					onFocus={createPasswordFieldOnFocus}
					required
					autoComplete="off"
				/>
				{createPasswordForm.email.valid === false && (
					<small className="inputError">Please enter a valid email</small>
				)}
				<InputField
					displayLabel="Create Password"
					id="password"
					name="password"
					type="password"
					onBlur={createPasswordSetValues}
					onFocus={createPasswordFieldOnFocus}
					required
					autoComplete="off"
					inputError={
						createPasswordForm.password.valid === false ? true : false
					}
				/>
				<small
					className={`inputHelper ${
						createPasswordForm.password.valid === false ? 'error' : ''
					}`}>
					Must be at least 8 characters
				</small>
				<Button
					displayLabel="Continue"
					color="white"
					size="full"
					type="submit"
					buttonValidation={isFormValid}
					onClick={createPasswordSubmit}
				/>
			</form>

			<span className="CreatePassword__footer">
				If you encounter any issues while creating an account, please contact
				your manager.
			</span>
			{loading && <Loader />}
		</LoginLayout>
	);
}

export default CreatePasswordAdmin;
