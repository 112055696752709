import ImgWithFallback from 'components/img';
import DashboardLogo from '../../assets/images/logo_dashboard.webp';
import DashboardLogoPNG from '../../assets/images/logo_dashboard.png';

import './verifyMembership.scss';
import { useEffect, useState } from 'react';
import FormDisplayImage from 'components/formDisplayImage';
import FormDisplayGroup from 'components/formDisplayGroup';
import { useNavigate, useParams } from 'react-router-dom';
import { getVerifyMembership } from 'services/api/members/verifyMembership';
import { MEMBER_STATUS } from './verify-membership';
import Loader from 'pages/PayForMembership/loader';

function VerifyMembership() {
	const [member, setMember] = useState(null);
	const [status, setStatus] = useState(undefined);
	const [isLoading, setisLoading] = useState(false);
	let { hash } = useParams();

	const navigate = useNavigate();

	useEffect(() => {
		if (!member && hash) {
			setisLoading(true);
			(async () => {
				const memberInfo = await getVerifyMembership(hash);
				if (!memberInfo.error) {
					const status = MEMBER_STATUS.find(
						(st) => st.name === memberInfo.status.name,
					);
					setStatus(status);
					setMember(memberInfo);
					setisLoading(false);
				}
				if (memberInfo.error) {
					setisLoading(false);
					navigate('/access-denied');
				}
			})();
		}
	}, [hash, member, setMember, setisLoading, navigate]);

	return (
		<div className="VerifyMembership">
			{isLoading === true && <Loader />}
			{isLoading === false && (
				<>
					<div className="VerifyMembership__logo">
						<ImgWithFallback
							img={DashboardLogo}
							fallbackImg={DashboardLogoPNG}
						/>
					</div>
					<div className="VerifyMembership__col">
						<FormDisplayImage url={member?.profile_pricture_url} />
						<div className="VerifyMembership__col__left">
							<FormDisplayGroup
								fieldLabel="Full Name"
								fieldValue={member?.full_name}
							/>
							<FormDisplayGroup
								fieldLabel="Member Number"
								fieldValue={member?.member_number}
							/>
							<FormDisplayGroup
								fieldLabel="Birthday"
								fieldValue={member?.birth_date}
							/>
							<FormDisplayGroup
								fieldLabel="Status"
								fieldValue={member?.status.description.type === '' ? status?.label : member?.status.description.type}
								classValue={status?.class}
							/>
						</div>
					</div>
				</>
			)}
		</div>
	);
}

export default VerifyMembership;
