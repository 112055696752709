import { axiosRequest } from '../../axiosRequest';

const adapterAppResponse = ({ data, success }) => {
	if (success === false) return { error: true, data };
	return true;
}; // url dashboard/admin/:adminId (delete)

export const deleteAdminById = async (appId) => {
	var options = {
		method: 'delete',
		url: 'dashboard/admin',
		params: appId,
	};

	const response = await axiosRequest(options);
	const deletedApp = adapterAppResponse(response);

	return deletedApp;
};
