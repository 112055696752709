import { createContext, useState } from 'react';
import useStorage from '../hooks/useStorage';
const AuthContext = createContext();

const AuthContextProvider = (props) => {
	const { children } = props;
	const [accessToken, setAccessToken, cookieLoaded] = useStorage('CookieAuth', '');
	const [user, setUser] = useState(null);

	const contextValue = {
		accessToken,
		setAccessToken,
		user,
		setUser,
		cookieLoaded
	};

	return (
		<AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
	);
};

export { AuthContext, AuthContextProvider };
