import React from 'react';
import NobelPopUpModal from '../nobelPopUpModal';
import Button from '../../button';
import NobleIcon from '../../nobleIcon';
import './deleteModal.scss';

const description = `Approving an application entails irreversible changes that may incur costs for the application's owner. Please ensure you verify user details thoroughly before granting approval.`;

function ApproveModal({
	isApproveModalOpen,
	onCloseHandler,
	approveHandler,
	clickedItem,
	approveItemType = '',
	descriptionApprove = description,
}) {
	return (
		<NobelPopUpModal
			isOpen={isApproveModalOpen}
			onRequestClose={onCloseHandler}
			className="ApproveModal">
			<div className="ApproveModal__header">
				<h3>
					Approve {`${clickedItem?.first_name} ${clickedItem?.last_name}`}{' '}
					{' ?'}
				</h3>
			</div>
			<div className="ApproveModal__content">
				<p>{descriptionApprove}</p>
			</div>
			<div className="ApproveModal__buttons">
				<Button
					onClick={() =>
						approveHandler(
							clickedItem?.id ? clickedItem.id : clickedItem.app_id,
						)
					}
					displayLabel={`Approve ${approveItemType}`}
					buttonWrapperClass="ApproveModal__buttons__button"
					color="white"
					size="full">
					<NobleIcon name="person_add" />
				</Button>
				<Button
					displayLabel={`No, don't approve`}
					buttonWrapperClass="ApproveModal__buttons__button ApproveModal__buttons__button--not--approve"
					size="full"
					onClick={onCloseHandler}
				/>
			</div>
		</NobelPopUpModal>
	);
}

export default ApproveModal;
