import React from 'react';
import './button.scss';
import cn from 'classnames';

function Button({
	displayLabel,
	color = 'black',
	size = 'default',
	buttonValidation = true,
	buttonWrapperClass = '',
	children,
	...rest
}) {
	return (
		<div className={'buttonWrapper ' + buttonWrapperClass}>
			<button
				className={cn([
					'buttonWrapper__btn',
					{ 'buttonWrapper__btn--black': color === 'black' },
					{ 'buttonWrapper__btn--white': color === 'white' },
					{ 'buttonWrapper__btn--size-auto': size === 'auto' },
					{ 'buttonWrapper__btn--size-full': size === 'full' },
					{ 'buttonWrapper__btn--size-default': size === 'default' },
					{ 'buttonWrapper__btn--disabled': !buttonValidation },
				])}
				onClick={() => window.location.reload()}
				{...rest}>
				{children}
				{displayLabel}
			</button>
		</div>
	);
}

export default Button;
