// url dashboard/admin (post)
import { axiosRequest } from '../../axiosRequest';
import { deformatPhoneNumber } from '../../../helpers/formattedPhoneNumber';

const adapterAddAdmin = (data) => {
	const keysAdmin = Object.keys(data);

	let dataPost = {};

	for (let i = 0; i < keysAdmin.length; i++) {
		if (
			(data[keysAdmin[i]].value.length > 0 || data[keysAdmin[i]].value !== 0) &&
			data[keysAdmin[i]].valid !== undefined
		) {
			if (keysAdmin[i] === 'phoneNumber') {
				dataPost[keysAdmin[i]] = deformatPhoneNumber(data[keysAdmin[i]].value);
			} else {
				dataPost[keysAdmin[i]] = data[keysAdmin[i]].value;
			}
		}
	}

	return dataPost;
};

const adapterAddAdminResponse = ({ data, success }) => {
	if (success === false) return { error: true, data };

	return data;
};

export const addAdmin = async (addAdminForm) => {
	const dataAddAdmin = adapterAddAdmin(addAdminForm);

	const options = {
		method: 'post',
		url: '/dashboard/admin',
		information: dataAddAdmin,
	};


	const response = await axiosRequest(options);
	const addAdminResponse = adapterAddAdminResponse(response);

	return addAdminResponse;
};
