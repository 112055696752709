import React from 'react';
import './optionsButtons.scss';

function OptionsButtons({ children, ...rest }) {
	return (
		<div className="OptionsButtons" {...rest}>
			{children}
		</div>
	);
}

export default OptionsButtons;
