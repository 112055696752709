// url dashboard/member/${id}/cancel-membership (put)

import { axiosRequest } from 'services/axiosRequest';

const adapter = ({ data, success }) => {
	if (success === false) return { error: true, data };

	return data;
};

export const cancelMembershipById = async (id) => {
	const options = {
		method: 'put',
		url: `dashboard/member/${id}/cancel-membership`,
	};
	
	const response = await axiosRequest(options);
	const deletedMember = adapter(response);

	return deletedMember;
};
