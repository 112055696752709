import Cookies from 'js-cookie';
import { axiosRequest } from '../../axiosRequest';

const adapterLoginResponse = ({ data, success }) => {
	if (success === false) return { error: true, data };
	let user = {
		information: data.admin,
		token: data?.access_token,
	};
	return user;
};

export const login = async (loginForm) => {
	const email = loginForm.email.value;
	const password = loginForm.password.value;

	const options = {
		method: 'post',
		url: 'dashboard/login',
		information: {
			email: email,
			password: password,
		},
		isJson: true,
	};

	const response = await axiosRequest(options);
	const user = adapterLoginResponse(response);

	if (user) { 
		localStorage.setItem('CookieAuth', user.token); 
		Cookies.set('CookieAuth', user.token, { expires: 7 }); 
	}

	return user;
};
