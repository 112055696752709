import { axiosRequest } from '../../axiosRequest';

import moment from 'moment';

const adapterAppResponse = ({ data, success }) => {
	if (success === false) return { error: true, data };
	const updatedApp = {
		response_code: data?.response_code,
		response_description: data?.response_description,	
        id: data?.application?.id,
		fullname: `${data?.application?.first_name} ${data?.application?.last_name}`,
		phone: data?.application?.phone_number,
		email: data?.application?.email,
		ig_contact: data?.application?.instagram_handle,
		address: data?.application?.address_line_1,
		company: data?.application?.company,
		ocupation: data?.application?.occupation,
		gross_income: data?.application?.gross_income,
		referred_by: data?.application?.referred_by,
		status: data?.application?.status,
		tier_type: data?.application?.tier ? data?.application?.tier?.type : null,
		primary_restaurant: data?.application?.venue
			? data?.application?.venue.primary
			: null,
		birthdate: data?.application?.birth_date
			? moment(data?.application?.birth_date).format('L')
			: null,
		application_date: data?.application?.created_at
			? moment(data?.application?.created_at).format('L')
			: null,
		update_status_date: data?.application?.status_updated_at
			? moment(data?.application?.status_updated_at).format('L')
			: null,
	};
	return updatedApp;
};

export const updateApplicationById = async (appId, status) => {
	var options = {
		method: 'put',
		url: 'dashboard/application',
		params: appId,
		information: { status },
	};
    
	const response = await axiosRequest(options);
	const updatedApp = adapterAppResponse(response);

	return updatedApp;
};
