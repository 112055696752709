import React, { useState, useContext } from 'react';
import {
	Table,
	Header,
	HeaderRow,
	Body,
	Row,
	Cell,
} from '@table-library/react-table-library/table';
import {
	useSort,
	HeaderCellSort,
} from '@table-library/react-table-library/sort';
import { useTheme } from '@table-library/react-table-library/theme';
import UnfoldMoreOutlinedIcon from '@mui/icons-material/UnfoldMoreOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import NobleIcon from '../../../components/nobleIcon';
import {
	blackColor,
	darkGreyColor,
	whiteColor,
} from '../../../helpers/colorConstants';

import './adminList.scss';
import CustomOptionsMenu from '../../../components/customOptionsMenu';
import DeleteModal from '../../../components/modals/deleteModal';
import { deleteAdminById } from '../../../services/api/admins/deleteAdmin';
import { toast } from 'react-toastify';
import { AppContext } from '../../../context';
import { getAdminsList } from '../../../services/api/admins';
import { deleteAdminMessage } from '../../../services/constants';

const toastOptions = {
	position: 'bottom-center',
	autoClose: 7000,
	hideProgressBar: true,
	closeOnClick: true,
	pauseOnHover: true,
	draggable: true,
	progress: undefined,
	theme: 'dark',
};

function AdminList({ data, setSection, setIsOpen, setClickedItemId, setRefetch, ...rest }) {
	const { setLoading } = useContext(AppContext);
	// SORT
	const sort = useSort(
		data,
		{
			onChange: onSortChange,
		},
		{
			sortIcon: {
				margin: '0px',
				iconDefault: <UnfoldMoreOutlinedIcon fontSize="small" />,
				iconUp: <KeyboardArrowUpOutlinedIcon fontSize="small" />,
				iconDown: <KeyboardArrowDownOutlinedIcon fontSize="small" />,
			},
			sortFns: {
				FULLNAME: (array) =>
					array.sort((a, b) => a.full_name.localeCompare(b.full_name)),
				BIRTHDAY: (array) =>
					array.sort((a, b) => new Date(a.birth_date) - new Date(b.birth_date)),
				EMAIL: (array) => array.sort((a, b) => a.email.localeCompare(b.email)),
				ROLE: (array) =>
					array.sort((a, b) => a.role.name.localeCompare(b.role.name)),
			},
		},
	);

	function onSortChange(action, state) {
		console.log(action, state);
	}

	const [isOpenDeleteAdminModal, setisOpenDeleteAdminModal] = useState(false);
	const [clickedAdmin, setClickedAdmin] = useState(false);

	// STYLES
	const customTheme = {
		Table: `
			--data-table-library_grid-template-columns:  30% 15% 33% 22%;
			z-index: 0;
			position: relative;
			padding-top: 60px;
    		margin-top: -30px;
			@media (max-width: 767px) {
				--data-table-library_grid-template-columns:  40% 40% 65% 50%;
			}
		`,
		BaseCell: `
			color: ${whiteColor};
			font-weight: lighter;
			padding: 18px 10px 18px 10px;
			&:nth-of-type(1) {
				@media (max-width: 767px) {
					left: -1px;
					border-right: 1px solid ${darkGreyColor};
					background-color: ${blackColor};
				}
			}

			> div:first-of-type {
				overflow: visible;
			}
		  `,
		Row: `
			padding: 20px;
			background-color: red;
			&:not(:last-child) {
				border-bottom: 1px solid ${darkGreyColor};
			}
			&.active {
				> * {
					background-color: ${darkGreyColor};
				}
			}
		`,
		HeaderCell: `
			border-bottom: 1px solid ${darkGreyColor};
			background-color: ${blackColor};
			letter-spacing: .55px;
			font-size: 20px;
			font-weight: normal;
			line-height: 23px;
		`,
		Cell: `
			background-color: ${blackColor};
			border-bottom: .45px solid ${darkGreyColor};
			letter-spacing: .55px;
			font-weight: lighter;
			font-size: 14px;
			line-height: 16.1px;
		`,
	};

	const theme = useTheme(customTheme);

	const openEditAdmin = (value) => {
		setSection('edit-admin');
		setClickedItemId(value);
		setIsOpen(true);
	};

	const openDeleteAdminHanlder = (admin) => {
		setClickedAdmin(admin);
		setisOpenDeleteAdminModal(true);
	};
	const closeHandlerDeleteAdminModal = () => {
		setisOpenDeleteAdminModal(false);
	};

	const deleteAdmin = async (adminId) => {
		setLoading(true);
		const response = await deleteAdminById(adminId);
		if (!response.error) {
			setisOpenDeleteAdminModal(false);
			setRefetch(true);
			toast.success(`Admin deleted`, toastOptions);
		} else {
			setisOpenDeleteAdminModal(false);
			toast.error(`Error deleting Admin`, toastOptions);
		}
		setLoading(false);
	};

	return (
		<div className="AdminList" {...rest}>
			<Table
				data={data}
				theme={theme}
				sort={sort}
				layout={{
					custom: true,
					fixedHeader: true,
				}}>
				{(tableList) => (
					<>
						<Header>
							<HeaderRow>
								<HeaderCellSort sortKey="FULLNAME" resize pinLeft>
									Name
								</HeaderCellSort>
								<HeaderCellSort sortKey="BIRTHDAY" resize>
									Birthday
								</HeaderCellSort>
								<HeaderCellSort sortKey="EMAIL" resize>
									Email
								</HeaderCellSort>
								<HeaderCellSort sortKey="ROLE">Role</HeaderCellSort>
							</HeaderRow>
						</Header>
						<Body>
							{tableList.map((item) => (
								<Row key={'adminKey_' + item?.id} item={item}>
									<Cell pinLeft>{item?.full_name}</Cell>
									<Cell>{item?.birth_date || '-'}</Cell>
									<Cell>{item?.email}</Cell>
									<Cell>
										<div className="AdminList__role">
											<span>{item?.role.name}</span>
											<CustomOptionsMenu
												id={item.id}
												menuContent={
													<>
														<div
															className="ThreeDotsOptionsMenu__item-edit"
															onClick={() => openEditAdmin(item.id)}>
															<NobleIcon name="edit" />
															Edit
														</div>
														<button
															onClick={() => openDeleteAdminHanlder(item)}
															className="ThreeDotsOptionsMenu__item-delete">
															<NobleIcon name="delete" />
															Delete Admin
														</button>
													</>
												}
											/>
										</div>
									</Cell>
								</Row>
							))}
						</Body>
					</>
				)}
			</Table>
			<DeleteModal
				isDeleteModalOpen={isOpenDeleteAdminModal}
				onCloseHandler={closeHandlerDeleteAdminModal}
				descriptionDelete={deleteAdminMessage}
				deleteHandler={deleteAdmin}
				clickedItem={clickedAdmin}
				deleteItemType="Admin"
			/>
		</div>
	);
}

export default AdminList;
