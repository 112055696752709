import React, { useEffect } from 'react';
import NobleIcon from '../nobleIcon';
import cn from 'classnames';
import './threeDotsOptionsMenu.scss';

function ThreeDotsOptionsMenu({
	toggleMenu,
	setToggleMenu,
	outsideClickRef,
	menuContent,
}) {
	useEffect(() => {
		const checkIfClickedOutside = (e) => {
			// If the menu is open and the clicked target is not within the menu,
			// then close the menu
			if (
				toggleMenu &&
				outsideClickRef.current &&
				!outsideClickRef.current.contains(e.target)
			) {
				setToggleMenu(false);
			}
		};

		document.addEventListener('mousedown', checkIfClickedOutside);

		return () => {
			// Cleanup the event listener
			document.removeEventListener('mousedown', checkIfClickedOutside);
		};
	}, [toggleMenu, outsideClickRef, setToggleMenu]);
	return (
		<div
			className={cn(['ThreeDotsOptionsMenuWrapper', { clicked: toggleMenu }])}>
			<NobleIcon
				onClick={(e) => {
					e.stopPropagation();
					setToggleMenu(!toggleMenu);
				}}
				name="menuDots"
			/>
			{toggleMenu && (
				<div className="ThreeDotsOptionsMenu" ref={outsideClickRef}>
					{menuContent}
				</div>
			)}
		</div>
	);
}

export default ThreeDotsOptionsMenu;
