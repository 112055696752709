import {
	blackColor,
	tertiaryBGColor,
	darkGreyColor,
	whiteColor,
} from '../../../helpers/colorConstants';

// STYLES
export const getTheme = (selected) => {
	const stylesColumnsFor = {
		xl: selected
			? '14% 8% 16% 9% 9% 12% 9% 9% 16%'
			: '14% 6% 14% 7% 7% 12% 12% 7% 7% 14%',
		lg: selected
			? '16% 10% 23% 15% 18% 10% 10% 10% 20%'
			: '16% 10% 23% 15% 18% 10% 10% 10% 10% 20%',
		md: selected
			? '35% 20% 40% 22% 22% 24% 18% 18% 30%'
			: '35% 20% 40% 22% 22% 24% 26% 18% 18% 30%',
		sm: selected
			? '45% 26% 70% 35% 45% 32% 32% 35% 70%'
			: '45% 26% 70% 35% 45% 32% 32% 32% 35% 70%',
	};

	const customTheme = {
		Table: `
		  --data-table-library_grid-template-columns: ${stylesColumnsFor.xl} !important;
		  z-index: 0;
		  overflow: hidden;
		  position: relative;
		  width: 100%;
		  height: auto;
		  max-width: 100%;
		  overflow-x: auto;
		  padding-top: 30px;
		  margin-top: -30px;
	
		  @media (max-width: 1200px) {
			  --data-table-library_grid-template-columns: ${stylesColumnsFor.lg}  !important;
		  }
	
		  @media (max-width: 767px) {
			 --data-table-library_grid-template-columns: ${stylesColumnsFor.md} !important;
			 overflow-x: auto;
		  }
	
		  @media (max-width: 480px) {
			  --data-table-library_grid-template-columns: ${stylesColumnsFor.sm} !important;
		  }
		`,
		BaseCell: `
		  color: ${whiteColor};
		  font-weight: lighter;
		  padding: 18px 10px 18px 10px;
	
		  &:nth-of-type(1) {
			 @media (max-width: 767px) {
				left: -1px;
				border-right: 1px solid ${darkGreyColor};
				background-color: ${blackColor};
			 }
		  }
	
		  &:last-of-type,
		  &:nth-of-type(6) {
			 > div:first-of-type {
				overflow: visible;
			 }
		  }
		`,
		Row: `
		  padding: 20px;
		  &:not(:last-child) {
			 border-bottom: 1px solid ${darkGreyColor};
		  }
	
		  &:hover {
			 > * {
				background-color: ${darkGreyColor};
			 }
		  }
	
		  &.active {
			 > * {
				background-color: ${darkGreyColor};
			 }
		  }
		  &:nth-of-type(1) {
			.ThreeDotsOptionsMenu {
				bottom: -290%;
				right: 115%;
			}
		  }
		  &:nth-of-type(2) {
			.ThreeDotsOptionsMenu {
				bottom: -170%;
				right: 115%;
			}
		  }
		  &:nth-of-type(3) {
			.ThreeDotsOptionsMenu {
				bottom: -170%;
				right: 115%;
			}
		  }
		`,
		HeaderCell: `
		  border-bottom: 1px solid ${darkGreyColor};
		  background-color: ${tertiaryBGColor};
		  letter-spacing: .55px;
		  font-size: 14px;
		  font-weight: 400;
		  color: rgba(255, 255, 255, 0.6);
		  line-height: 23px;
		  padding: 5px 10px 5px 10px;
		  &:hover {
			> * {
				color: ${whiteColor};
			}
		  }
		  span {
			 color: ${whiteColor};
		  }
		`,
		Cell: `
		  background-color: ${blackColor};
		  border-bottom: .45px solid ${darkGreyColor};
		  letter-spacing: .55px;
		  font-weight: 400;
		  font-size: 14px;
		  line-height: 16.1px;
		  overflow: visible;
		  padding: 3px 10px 3px 10px;
		`,
	};
	return customTheme;
};
