import React, { useCallback, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import cn from 'classnames';
import NobleIcon from '../../nobleIcon';
import './dashboardNavigation.scss';
import { AppContext, AuthContext } from '../../../context';
import { getTotalApplications } from '../../../services/api/applications/getTotalApplications';
import { getTotalMemebers } from '../../../services/api/members/getTotalMembers';
import { userHasPermission } from 'helpers/hasPermission';
import { PERMISSIONS } from 'helpers/constants';

function DashboardNavigation({ active }) {
	const location = useLocation();
	const {
		applicationsSelected,
		totalApps,
		setTotalApps,
		setLoading,
		totalMembers,
		setTotalMembers,
	} = useContext(AppContext);

	const { user } = useContext(AuthContext);

	const fetchGetTotalsApplications = useCallback(async () => {
		setLoading(true);
		// TOTAL APPLICATIONS
		if (!totalApps.total && applicationsSelected.selected === '' && user) {
			const totalApps = await getTotalApplications();
			if (!totalApps.error) {
				setTotalApps(totalApps);
			} else {
				console.log(totalApps.data);
			}
		}
		setLoading(false);
	}, [user, applicationsSelected.selected, setLoading, setTotalApps, totalApps.total]);

	const fetchGetTotalsMembers = useCallback(async () => {
		setLoading(true);
		// TOTAL MEMBERS
		if (!totalMembers) {
			const totalMember = await getTotalMemebers();
			if (!totalMember.error) {
				setTotalMembers(totalMember);
			} else {
				console.log('ERROR: ', totalMember.data);
			}
		}
		setLoading(false);
	}, [setLoading, setTotalMembers, totalMembers])

	useEffect(() => {
		fetchGetTotalsApplications();
		fetchGetTotalsMembers();
	}, [fetchGetTotalsApplications, fetchGetTotalsMembers]);

	return (
		<nav className="DashboardNavigation">
			{userHasPermission(user, PERMISSIONS.CAN_SHOW_APPLICATIONS) && (
				<a
					href="/applications"
					className={cn([
						'DashboardNavigation__item',
						{ active: location.pathname === '/applications' },
					])}>
					<NobleIcon name="personAdd" />
					{active && <span>Applications</span>}
					{active && (
						<span className="DashboardNavigation__item--total">
							{totalApps.new}
						</span>
					)}
				</a>
			)}	
			<a
				href="/members"
				className={cn([
					'DashboardNavigation__item',
					{ active: location.pathname === '/members' },
				])}>
				<NobleIcon name="members" />
				{active && <span>Members</span>}
				{active && (
					<span className="DashboardNavigation__item--total">
						{userHasPermission(user, PERMISSIONS.CAN_SHOW_MEMBER_NUMBERS) &&
							totalMembers?.active_members}
					</span>
				)}
			</a>
			{userHasPermission(user, PERMISSIONS.CAN_SHOW_ADMINS) && (
				<div className="DashboardNavigation__item__section-admin">
					<a
						href="/admins"
						className={cn([
							'DashboardNavigation__item',
							{ active: location.pathname === '/admins' },
						])}>
						<NobleIcon name="admins" />
						{active && 'Manage Admins'}						
					</a>
				</div>
			)}
		</nav>
	);
}

export default DashboardNavigation;