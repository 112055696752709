import { useEffect, useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { AppContext } from '../context';
import { getUser } from '../services/api/user/getUser';
import { useLocation, useNavigate } from 'react-router-dom';

export default function useRefesh() {
	const { accessToken, user, setUser } = useContext(AuthContext);
	const { setLoading } = useContext(AppContext);
	const navigate = useNavigate();
	const location = useLocation();
	useEffect(() => {
		if (!accessToken) {
			const protectedUrls = [
				'/',
				'/applications',
				'/members',
				'/admins',
			];
			if (protectedUrls.includes(location?.pathname)) navigate('/login');
		}
		if (accessToken && !user) {
			async function getUserData() {
				setLoading(true);
				const admin = await getUser();
				if (!admin.error) {
					setUser(admin.information);
				} else {
					return navigate('/logout');
				}
				setLoading(false);
			}
			getUserData();
		}
	}, [accessToken, user, setUser, setLoading, navigate, location]);
}
