import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
	CardCvcElement,
	CardExpiryElement,
	CardNumberElement,
	useStripe,
	useElements,
} from '@stripe/react-stripe-js';
import classNames from 'classnames';
// Context
import { AppContext } from '../../../context/AppContext';
// Components
import Loader from '../loader';
import InputField from '../../../components/inputField';
import SubscriptionType from '../subscriptionType';
// Data
import { updatePaymentIntent } from 'services/api/stripe/updatePaymentIntent';
import { somethingWentWrong } from 'services/constants';
// Styles
import {
	stripeNumbersOptions,
	stripeExpiryOptions,
	stripeCVCOptions,
} from '../stripeOptions';
import { isValidFullName } from 'services/validations';
import { createPaymentIntent } from 'services/api/stripe/createPaymentIntent';
export default function CheckoutForm({ handleSuccess, tier, hasSpouse, hasSubscription }) {
	const { hash } = useParams();
	const { loading } = useContext(AppContext);
	const stripe = useStripe();
	const elements = useElements();
	const [isLoading, setIsLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);
	const [priceId, setPriceId] = useState('');
	const [customerDetails, setCustomerDetails] = useState({
		name: '',
		valid: false,
	});

	const handleSubmit = async (event) => {
		event.preventDefault();
		if (!isValidFullName(customerDetails.name))
			return setErrorMessage('No special characters allowed.');
		if (!stripe || !elements || errorMessage) return;

		setIsLoading(true);
		try {
			const { error: stripeError, paymentMethod } =
				await stripe.createPaymentMethod({
					type: 'card',
					card: elements.getElement(CardNumberElement),
					billing_details: {
						name: customerDetails.name,
					},
				});
			if (stripeError) throw stripeError.message;
			// Send payment method to backend
			const request = hasSubscription
				? await updatePaymentIntent({
						hash,
						paymentMethodId: paymentMethod.id,
						priceId,
				  })
				: await createPaymentIntent({
						hash,
						paymentMethodId: paymentMethod.id,
						priceId,
				  });

			if (!request.error) {
				const { client_secret: clientSecret } = request;

				const { error, paymentIntent } = await stripe.confirmPayment({
					clientSecret,
					type: 'card',
					redirect: 'if_required',
				});

				if (error) throw error.message;
				handleSuccess && handleSuccess(paymentIntent);
			} else {
				throw request?.data?.response_description ?? somethingWentWrong;
			}
		} catch (error) {
			setErrorMessage(error);
			setIsLoading(false);
		}
		setIsLoading(false);
	};

	const handleSubscriptionChange = (priceId) => {
		setPriceId(priceId);
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setErrorMessage(null);
		setCustomerDetails({
			...customerDetails,
			[name]: value,
			valid: !isValidFullName(value),
		});
	};

	const clearErrors = () => setErrorMessage(null);

	return (
		<form className="PaymentForMembership__form" onSubmit={handleSubmit}>
			<SubscriptionType
				hash={hash}
				tier={tier}
				hasSpouse={hasSpouse}
				handleSubscriptionChange={handleSubscriptionChange}
			/>
			<h1 className="PaymentForMembership__title">Credit Card</h1>
			<fieldset>
				<InputField
					displayLabel="Full Name"
					id="name"
					name="name"
					type="text"
					value={customerDetails.name}
					onChange={handleInputChange}
					onBlur={handleInputChange}
					required
					autoComplete="off"
					placeholder="Full Name"
					inputError={customerDetails.valid}
				/>
			</fieldset>
			<fieldset>
				<div className="inputField">
					<label className="inputField__label">Card Number</label>
					<div className="inputField__wrapper">
						<CardNumberElement
							options={stripeNumbersOptions}
							onChange={clearErrors}
							onBlur={clearErrors}
							placeholder={'333'}
						/>
					</div>
				</div>
			</fieldset>
			<fieldset className="inputField--group">
				<div className="inputField">
					<label className="inputField__label">Expiration Date</label>
					<div className="inputField__wrapper">
						<CardExpiryElement
							options={stripeExpiryOptions}
							onChange={clearErrors}
							onBlur={clearErrors}
						/>
					</div>
				</div>
				<div className="inputField">
					<label className="inputField__label">CVC</label>
					<div className="inputField__wrapper">
						<CardCvcElement options={stripeCVCOptions} onChange={clearErrors} onBlur={clearErrors} />
					</div>
				</div>
			</fieldset>
			<button
				className={classNames([
					'PaymentForMembership__btn',
					{ 'PaymentForMembership__btn--loading': isLoading },
				])}
				disabled={isLoading || !stripe || !elements}
				id="submit">
				<span className="button__text">ACTIVATE MEMBERSHIP</span>
			</button>
			{errorMessage && (
				<div className="PaymentForMembership__message">{errorMessage}</div>
			)}
			{loading && <Loader />}
		</form>
	);
}
