import moment from 'moment';

export const handleSeatingOptions = (seatingOptions) => {
	return Object.keys(seatingOptions).map((name) => ({
		name,
		value: seatingOptions[name],
	}));
};

export const formatTime = (timeValue) => {
	if (!timeValue.hours) return moment(timeValue, 'LT').format('hh:mm a');

	let timeString = `${formatTimeValue(timeValue.hours)}:${formatTimeValue(
		timeValue.minutes,
	)} ${formatTimeValue(timeValue.timePeriod)}`;
	return timeString;
};

const formatTimeValue = (timeValue) => {
	let returnValue = timeValue.length === 1 ? `0${timeValue}` : timeValue;
	return returnValue;
};

export const adapterPhoneToShow = (phone) => {
	let adaptedPhone = null;
	if (!phone) return adaptedPhone;
	if (phone[0] === '+') phone = phone.slice(1);
	let firstPart = [...phone].slice(0, 3).join('');
	let secondPart = [...phone].slice(3, 6).join('');
	let thirdPart = [...phone].slice(6).join('');
	adaptedPhone = `${firstPart}-${secondPart}-${thirdPart}`;
	return adaptedPhone;
};

export const capitalizeText = (text) => {
	return text.replace(/\b\w/g, function (l) {
		return l.toUpperCase();
	});
}