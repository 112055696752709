import { axiosRequest } from '../../axiosRequest';

const adapterAppResponse = ({ data, success }) => { 
	if (success === false) return { error: true, data };
	return true;
};

export const deleteApplicationById = async (appId) => {
    var options = {
		method: 'delete',
		url: 'dashboard/application',
		params: appId,
	};
    
	const response = await axiosRequest(options);
	const deletedApp = adapterAppResponse(response);

	return deletedApp;
};
