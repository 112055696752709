import React from 'react';
import cn from 'classnames';
import './imgWithFallback.scss';

export default function ImgWithFallback({
	img,
	fallbackImg,
	type = 'image/webp',
	fallbackType = 'image/png',
	accesibilityTitle,
	wrapperClassName,
	...rest
}) {
	return (
		<picture className={cn(['ImgWithFallback', wrapperClassName])}>
			<source srcSet={img} type={type} />
			{fallbackImg && <source srcSet={fallbackImg} type={fallbackType} />}
			<img alt="" loading={'lazy'} {...rest} />
			<span className="sr-only">{accesibilityTitle}</span>
		</picture>
	);
}
