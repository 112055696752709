import { useContext, useState } from 'react';
import ThreeDotsOptionsMenu from '../threeDotsOptionsMenu';
import { AppContext } from '../../context';

import './customOptionsMenu.scss';

function CustomOptionsMenu({ id, menuContent }) {
	const [toggleMenu, setToggleMenu] = useState(false);
	const { clickOutsideMenuRef } = useContext(AppContext);

	return (
		<div className="CustomOptionsMenu">
			<ThreeDotsOptionsMenu
				toggleMenu={toggleMenu}
				setToggleMenu={setToggleMenu}
				outsideClickRef={clickOutsideMenuRef}
				menuContent={menuContent}
			/>
			<div data-id={id} />
		</div>
	);
}

export default CustomOptionsMenu;
