// url dashboard/admin/:adminId (put)

import { axiosRequest } from '../../axiosRequest';
import { deformatPhoneNumber } from '../../../helpers/formattedPhoneNumber';

const adapterDataUpdatedAdminById = (data) => {
	const keysAdmin = Object.keys(data);

	let dataUpdate = {};

	for (let i = 0; i < keysAdmin.length; i++) {
		if (
			((typeof data[keysAdmin[i]].value === 'string' &&
				data[keysAdmin[i]].value !== '') ||
				(typeof data[keysAdmin[i]].value === 'number' &&
					data[keysAdmin[i]].value !== 0)) &&
			data[keysAdmin[i]].valid !== undefined
		) {
			if (keysAdmin[i] === 'phoneNumber') {
				dataUpdate[keysAdmin[i]] = deformatPhoneNumber(data[keysAdmin[i]].value);
			} else {
				dataUpdate[keysAdmin[i]] = data[keysAdmin[i]].value;
			}
		}
	}

	return dataUpdate;
};

const adapterUpdateAdminByIdResponse = ({ success, data }) => {
	if (success === false) return { error: true, data };

	return data;
};

export const updateAdminById = async (id, formUpdatedAdmin) => {
	const informationUpdatedAdmin = adapterDataUpdatedAdminById(formUpdatedAdmin);

	const options = {
		method: 'put',
		url: '/dashboard/admin',
		params: id,
		information: informationUpdatedAdmin,
	};

	const response = await axiosRequest(options);
	const updatedAdmin = adapterUpdateAdminByIdResponse(response);

	return updatedAdmin;
};
