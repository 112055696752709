import React from 'react';
import './formDisplayImage.scss';
import config from '../../config';

function FormDisplayImage({ url, disabled, isInvestor = false, previewUrl }) {
    const baseUrl = config.CDN_MEDIA;
    const defaultProfilePicture = isInvestor ? "uploads/default_profile_photo.png" : "uploads/strip.png";
    return (
        <div className="FormDisplayImage">
            <img
                src={url ? `${baseUrl}${url}` : previewUrl ? previewUrl : `${baseUrl}${defaultProfilePicture}`}
                onError={(e) => {
                    e.target.src = `${baseUrl}${defaultProfilePicture}`
                }}
                alt="current-headshot"
                className={`FormDisplayImage__img ${disabled ? 'disabled' : ''}`}
                />
        </div>
    );
}

export default FormDisplayImage;
