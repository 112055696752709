import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { AuthContext } from '../../context';

export default function Home() {
	const { accessToken } = useContext(AuthContext);
	const navigate = useNavigate();

	useEffect(() => {
		if (accessToken.length === 0) return navigate(`/login`);
		return navigate(`/login`);
		// eslint-disable-next-line
	}, [accessToken]);
	return <></>;
}
