import { createContext } from 'react';
import { browserIsSafari } from '../services/isSafari';

const BrowserContex = createContext();

export function BrowserContexProvider(props) {
	const { children } = props;

	const isSafari = browserIsSafari();

	const data = { isSafari };
	return (
		<BrowserContex.Provider value={data}>{children}</BrowserContex.Provider>
	);
}

export { BrowserContex };
