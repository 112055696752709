import { axiosRequest } from '../../axiosRequest';

const adapterTierOptionsResponse = ({ data, success }) => {
	if (success === false) return { error: true, data };
	const tierOptions = data?.tiers?.map((i) => {
		let tierOption = { value: i.id, label: i.name };
		return tierOption;
	});
	return tierOptions;
};

export const getTierOptions = async () => {
	var options = {
		method: 'get',
		url: 'dashboard/form/tiers',
	};

	const response = await axiosRequest(options);
	const tiersOptions = adapterTierOptionsResponse(response);

	return tiersOptions;
};
