import { axiosRequest } from '../../axiosRequest';

// url dashboard/set-manager-password (put)
const adapterInformationCreatePassword = (data) => {
	const keysAdmin = Object.keys(data);

	let dataPut = {};

	for (let i = 0; i < keysAdmin.length; i++) {
		if (
			data[keysAdmin[i]].value !== '' &&
			data[keysAdmin[i]].valid !== undefined
		) {
			dataPut[keysAdmin[i]] = data[keysAdmin[i]].value;
		}
	}

	return dataPut;
};

const adapterSetPasswordResponse = ({ success, data }) => {
	if (success === false) return { error: true, data };

	return data;
};

export const setPassword = async (createPasswordForm, resetCode) => {
	const informationCreatePassword =
		adapterInformationCreatePassword(createPasswordForm);

	const options = {
		method: 'put',
		url: '/dashboard/set-manager-password',
		information: { ...informationCreatePassword, resetCode },
	};

	const response = await axiosRequest(options);
	const setPassword = adapterSetPasswordResponse(response);

	return setPassword;
};
