import { axiosRequest } from '../../axiosRequest';

import moment from 'moment';
import { capitalizeText } from '../../helper';
import config from '../../../config/index';

// url dashboard/member/:memberId
const adapterMemberResponse = ({ data, success }) => {
	if (success === false) return { error: true, data };

	const member = {
		...data.member,
		tier: {
			...data.member.tier,
			type: data.member.tier.type.replace('Member', ''),
		},
		birth_date:
			data.member?.birth_date &&
			moment(data.member?.birth_date, 'YYYY-MM-DD').format('MM/DD/YYYY'),
		gross_income: data.member?.gross_income?.name,
		gross_income_id: data.member?.gross_income?.id,
		grm_referral: data.member?.grm_referral ? data.member?.grm_referral : '--',
		member_since:
			data.member?.member_since &&
			moment(data.member?.member_since, 'YYYY-MM-DD').format('MM/DD/YYYY'),
		has_referral: !data.member.referred_by ? false : true,
		payment_status: capitalizeText(data.member.payment_status),
		deep_link: data.member?.user_id
			? `${config.DEEPLINK_URL}/signin?hash=${data.member?.hash}`
			: `${config.DEEPLINK_URL}/signup?hash=${data.member?.hash}`,
		membership_link: data.member?.hash
			? `${window.location.origin}/membership/${data.member?.hash}`
			: null,
	};

	return member;
};

export const getMemberById = async (id, isSpouse) => {	
	let options = {
		method: 'get',
		url: 'dashboard/member',
		params: isSpouse === undefined ? `${id}` : `${id}/${isSpouse}`,
	};

	const response = await axiosRequest(options);

	const member = adapterMemberResponse(response);

	return member;
};
