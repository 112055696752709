import { axiosRequest } from '../../axiosRequest';

// url dashboard/roles
const adapterRoleOptionsResponse = ({ data, success }) => {
	if (success === false) return { error: true, data };

	let rolesOptions = [...data.roles]

	rolesOptions.forEach((i) => {
		i.value = i.id;
		i.label = i.name;
	});	
	
	return rolesOptions;
};

export const getRoleOptions = async () => {
	let options = {
		method: 'get',
		url: 'dashboard/roles',
	};

	const response = await axiosRequest(options);
	const roleOptions = adapterRoleOptionsResponse(response);

	return roleOptions;
};
