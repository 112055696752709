export const controlStyles = {
    width: '100%',
    outline: "unset",
    borderColor: '#454A4B',
    backgroundColor: '#2B3033',
    color: '#F7F7F7',
    minWidth: "167px",
    boxShadow: "none"
}
export const defaultSelectStyles = {
    control: (baseStyles) => ({
        ...baseStyles,
        ...controlStyles
    }),
    menu: (styles) => {
        return {
            ...styles,
            marginTop: 0,
            border: "1px solid #454a4b",
            borderTop: 0
        }
    },
    option: (styles) => {
        return {
            ...styles,
            padding: "9px 20px",
            fontSize: "14px",
            fontFamily: "var(--fontFamily)",
            lineHeight: "16px",
            fontWeight: 400
        }
    }
}