import React from 'react';
import { Routing } from '..';
import {
	AppContextProvider,
	AuthContextProvider,
	BrowserContexProvider,
} from '../../context';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import './app.scss';

function App({ rootEl }) {
	return (
		<>
			<AppContextProvider rootEl={rootEl}>
				<AuthContextProvider>
					<BrowserContexProvider>
						<LocalizationProvider dateAdapter={AdapterMoment}>
							<div className="App">
								<Routing />
							</div>
						</LocalizationProvider>
					</BrowserContexProvider>
				</AuthContextProvider>
			</AppContextProvider>
		</>
	);
}

export default App;
