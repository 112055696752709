import moment from 'moment';
import { axiosRequest } from '../../axiosRequest';
import { deformatPhoneNumber } from '../../../helpers/formattedPhoneNumber';

// url dashboard/member/:memberId (put)
const mandatoryParameters = {
	firstName: 'firstName',
	lastName: 'lastName',
	city: 'city',
	state: 'state',
	zipCode: 'zipCode',
	hearAboutUs: 'hearAboutUs',
	grossIncome: 'grossIncome',
	tierTypeId: 'tierTypeId',
	primaryVenueId: 'primaryVenueId',
	addressLine1: 'addressLine1',
};

const adapterUpdateDataMemberById = (prevData) => {
	let data = {};
	const oldMemberKeys = Object.keys(prevData);
	for (let i = 0; i < oldMemberKeys.length; i++) {
		const key = oldMemberKeys[i];
		if (prevData[key]?.change || mandatoryParameters[key]) {
			data[key] = {
				value: prevData[key].value,
				valid: prevData[key].valid,
			};
		}
	}

	let dataUpdate = {};
	const keysMember = Object.keys(data);
	for (let i = 0; i < keysMember.length; i++) {
		const key = keysMember[i];
		const value = data[key].value;

		if (
			(value !== undefined ||
				(typeof value === 'string' && value.trim() !== '')) &&
			data[key].valid !== undefined
		) {
			if (
				(key === 'instagramHandle' && value === '@') ||
				(key.endsWith('Id') && value === 0) ||
				(key === 'referredBy' && value.trim() === '')
			) {
				continue;
			}

			if (key === 'phoneNumber') {
				dataUpdate[key] = deformatPhoneNumber(value);
			} else if (key === 'birthDate' || key === 'memberSince') {
				dataUpdate[key] = moment(value).format('YYYY-MM-DD');
			} else {
				dataUpdate[key] = value;
			}
		}
	}

	return dataUpdate;
};

const adapterUpdatedMemberResponse = ({ data, success }) => {
	if (success === false) return { error: true, data };

	return data;
};

export const updateMemberById = async (id, formUpdateMember) => {
	const dataAdapter = adapterUpdateDataMemberById(formUpdateMember);

	const options = {
		method: 'put',
		url: 'dashboard/member',
		params: id,
		information: dataAdapter,
	};

	const response = await axiosRequest(options);
	const updatedMember = adapterUpdatedMemberResponse(response);

	return updatedMember;

};
