import moment from 'moment';
import { axiosRequest } from '../../axiosRequest';
import { deformatPhoneNumber } from '../../../helpers/formattedPhoneNumber';

const adapterUserRequest = (data) => {
	const { email, password, full_name, phone_number, birth_day } = data;
	let userForm = {};

	if (email.value && email.valid) userForm.email = email.value;
	if (password.value && password.valid) userForm.password = password.value;
	if (full_name.value && full_name.valid) userForm.fullName = full_name.value;
	if (birth_day.value && birth_day.valid)
		userForm.birthDate = moment(birth_day.value).format('YYYY-MM-DD');
	if (phone_number.value && phone_number.valid)
		userForm.phoneNumber = deformatPhoneNumber(phone_number.value);

	return userForm;
};

const adapterUserResponse = ({ data, success }) => {
	if (success === false) return { error: true, data };
	let updatedUser = {
		information: data.admin,
	};
	let birthDay = updatedUser.information.birth_date;
	if (birthDay) {
		updatedUser.information.birth_date = moment(birthDay).format('MM/DD/YYYY');
	}

	return updatedUser;
};

export const updateUser = async (data) => {
	const userForm = adapterUserRequest(data);
	var options = {
		method: 'put',
		url: 'dashboard/me',
		information: userForm,
	};

	const response = await axiosRequest(options);
	const updatedUser = adapterUserResponse(response);

	return updatedUser;
};
