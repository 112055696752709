// Dependencies
import React from 'react';
import cn from 'classnames';

// Assets
import Icons from "../../assets/images/icons.svg";
import "./icons.scss";

const Icon = ({ className, id, ...rest }) => {
	return (
		<svg className={cn(["icon", {[`${className}`]: className}])} {...rest}>
			<use xlinkHref={`${Icons}#${id}`} />
		</svg>
	);
};

export default Icon;
