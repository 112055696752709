import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { AuthContext, AppContext } from '../../context';
import Cookies from 'js-cookie';

export default function Logout() {
	const { accessToken, setAccessToken } = useContext(AuthContext);
	const { setLoading } = useContext(AppContext);
	const navigate = useNavigate();

	useEffect(() => {
		setLoading(false);
		if (accessToken.length !== 0) {
			setAccessToken('');
			Cookies.remove('CookieAuth');
			return navigate(`/`);
		}
		navigate(`/login`);
		// eslint-disable-next-line
	}, [accessToken]);
	return <></>;
}
