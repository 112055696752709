import { useState, useEffect, createContext, useRef } from 'react';
import { debounce } from 'lodash';

const AppContext = createContext();

const AppContextProvider = (props) => {
	const { children, rootEl } = props;
	const [screenDimensions, setScreenDimensions] = useState(window.innerWidth);
	const [loading, setLoading] = useState(false);

	// MEMBERS
	const [members, setMembers] = useState([]);
	const [membersSelected, setMembersSelected] = useState({
		selected: 'active',
		members: members,
	});
	const [refetchMembers, setRefetchMembers] = useState(true);
	const [member, setMember] = useState(null);
	const [totalMembers, setTotalMembers] = useState(null);
	// APPLICATIONS
	const [application, setApplication] = useState(null);
	const [totalApps, setTotalApps] = useState({
		total: 0,
		new: 0,
		waitlist: 0,
		rejected: 0,
		approved: 0,
	});
	const [applications, setApplications] = useState([]);
	const [applicationsSelected, setApplicationsSelected] = useState({
		selected: '',
		applications: [],
	});
	// ADMINS
	const [admins, setAdmins] = useState(null);
	const [adminById, setAdminById] = useState(null);

	// SELECTS
	const [selects, setSelects] = useState(null);

	useEffect(() => {
		const handleResize = debounce(() => {
			setScreenDimensions(window.innerWidth);
		}, 100);

		window.addEventListener('resize', handleResize);

		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const clickOutsideMenuRef = useRef();

	const contextValue = {
		rootEl,
		screenDimensions,
		setLoading,
		loading,
		clickOutsideMenuRef,
		members,
		setMembers,
		membersSelected,
		setMembersSelected,
		totalMembers,
		setTotalMembers,
		member,
		setMember,
		application,
		setApplication,
		applications,
		setApplications,
		applicationsSelected,
		setApplicationsSelected,
		totalApps,
		setTotalApps,
		admins,
		setAdmins,
		adminById,
		setAdminById,
		refetchMembers,
		setRefetchMembers,
		selects,
		setSelects,
	};

	if (process.env.NODE_ENV === 'development') {
	}

	return (
		<AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
	);
};

export { AppContext, AppContextProvider };
