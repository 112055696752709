import { axiosRequest } from '../../axiosRequest';

import moment from 'moment';

const adapterAppResponse = ({ data, success }) => {
	if (success === false) return { error: true, data };

	const application = {
		// id: data?.application?.id,
		// phone: data?.application?.phone_number,
		// email: data?.application?.email,
		// ig_contact: data?.application?.instagram_handle,
		// address: data?.application?.address_line_1,
		// company: data?.application?.company,
		// ocupation: data?.application?.occupation,
		// gross_income: data?.application?.gross_income,
		// referred_by: data?.application?.referred_by,
		// status: data?.application?.status,
		...data.application,
		tier_type: data?.application?.tier ? data?.application?.tier?.type?.replace("Member", "") : null,
		primary_restaurant: data?.application?.venue
			? data?.application?.venue.primary
			: null,
		birthdate: data?.application?.birth_date
			? moment(data?.application?.birth_date, 'YYYY-MM-DD').format('MM/DD/YYYY')
			: null,
		application_date: data?.application?.created_at
			? moment(data?.application?.created_at).format('L')
			: null,
		update_status_date: data?.application?.status_updated_at
			? moment(data?.application?.status_updated_at, 'YYYY-MM-DD').format(
				'YYYY-MM-DD',
			)
			: null,
	};

	return application;
};

export const getApplicationById = async (appId) => {
	var options = {
		method: 'get',
		url: 'dashboard/application',
		params: appId,
	};

	const response = await axiosRequest(options);

	const application = adapterAppResponse(response);

	return application;
};
