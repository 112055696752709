export const userHasPermission = (user, permission) => {
	if (!user || !permission) return false;
	if (!user.role) return false;
	const { permissions } = user.role;

	if (!permissions[permission]) return false;
    
    if (permissions[permission] === '0') return false;
    if (permissions[permission] === '1') return true;
};
