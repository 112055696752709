import React, { useEffect } from "react";
import cn from 'classnames';

import "./phoneNumber.scss";
import { formattedPhoneNumber } from "../../../helpers/formattedPhoneNumber";

function InputPhoneNumber({ displayLabel, id, inputError, value, ...rest }) {

    // useEffect(() => {
    //     formattedPhoneNumber(value);
    // }, [value])

    return (
        <div className="inputPhoneNumber">
            {displayLabel && (
                <label className="inputPhoneNumber__label" htmlFor={id}>
                    {displayLabel}
                </label>
            )}
            <input
                id={id}
                className={cn([
                    'inputPhoneNumber__input',
                    { 'inputPhoneNumber__input-error': inputError },
                ])}
                {...rest}
                value={value ? value : ''}
            />
        </div>
    )
}

export default InputPhoneNumber;