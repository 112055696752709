import { axiosRequest } from '../../axiosRequest';

// url dashboard/member/send-payment-reminder (put)

const adapterResponse = ({ data, success }) => {
	if (success === false) return { error: true, data };
	return data;
};

export const sendPaymentReminder = async (id, isSpouse) => {
	const options = {
		method: 'put',
		url: 'dashboard/member/send-payment-reminder',
		params: isSpouse === undefined ? `${id}` : `${id}/${isSpouse}`,
	};

	const response = await axiosRequest(options);
	const updatedMember = adapterResponse(response);

	return updatedMember;

};
