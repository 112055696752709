import { STATES } from './constants';
import {
   isValidNumber as isValidPhoneNumberUs,
   parsePhoneNumberFromString,
} from 'libphonenumber-js';

export const isValidDate = (date) => date?.match(/^\d{4}-\d{2}-\d{2}$/);
export const isValidTime = (time) =>
   time?.match(/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/);
// export const isValidParameter = (name, value) => {
// 	switch (name) {
// 		case date:
// 			return isValidDate(value);
// 		case time:
// 			return isValidTime(value);
// 		case party_size:
// 			return !isNaN(value);
// 		case table:
// 			return true;
// 		default:
// 			return false;
// 	}
// };

export const requiredValidation = (fieldValue) => (fieldValue ? true : false);
export const isNotEmpty = (fieldValue) => (fieldValue !== '' ? true : false);
export const isBoolean = (fieldValue) => (fieldValue === true || fieldValue === false);
export const passwordValidation = (fieldValue) =>
   fieldValue.length >= 8 ? true : false;

// Taked from http://emailregex.com/
export const emailValidation = (email) => {
   const exp =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
   return exp.test(email);
};

export const isValidPhoneNumber = (phoneNumber) => {
   const exp =
      /^(\+{0,})(\d{0,})([(]{1}\d{1,3}[)]{0,}){0,}(\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}(\s){0,}$/gm;
   return exp.test(phoneNumber);
};

export const validatePhoneNumber = (phoneNumber, countryCode = 'US') => {
   const phoneNumberObj = parsePhoneNumberFromString(phoneNumber, countryCode);
   return phoneNumberObj ? isValidPhoneNumberUs(phoneNumberObj) : false;
};

export const isValidFullName = (fullName) => {
   const exp = /^[a-z ,.'-]+$/i;
   return exp.test(fullName);
};

export const stringMinLength = (string, minLength) => {
   if (typeof string !== 'string') return false;
   if (string.length < minLength) return false;
   return true;
};

export const stringMaxLength = (string, maxLength) => {
   if (typeof string !== 'string') return false;
   if (string.length > maxLength) return false;
   return true;
};

export const isValidNumber = (value) => {
   if (typeof value !== 'number') return false;
   return true;
};

export const isValidBirthDate = (date) => {
   let pattern = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/(19|20)\d\d$/;
   return pattern.test(date);
};

export const isValidStateCode = (stateId) => {
   const arrModel = STATES;
   if (!stateId || !arrModel) return false;
   if (!Array.isArray(arrModel)) return false;
   if (typeof stateId !== 'string') return false;

   return arrModel.some((state) => state.id === stateId);
};

export const isValidStateCodeAndZipCode = (stateId, zipCode) => {
   const arrModel = STATES;
   if (!stateId || !zipCode || !arrModel) return false;
   if (!Array.isArray(arrModel)) return false;
   if (typeof stateId !== 'string') return false;
   if (typeof zipCode !== 'string') return false;

   zipCode = parseInt(zipCode);
   if (typeof zipCode !== 'number') return false;

   let isValid = false;
   let arrZipCodes = [];
   for (var i = 0; i < arrModel.length; i++) {
      if (arrModel[i].id === stateId) {
         arrZipCodes = [...arrModel[i].zip];
      }
   }

   if (!arrZipCodes.length > 0) return false;
   for (var j = 0; j < arrZipCodes.length; j++) {
      if (zipCode >= arrZipCodes[j][0] && zipCode <= arrZipCodes[j][1]) {
         isValid = true;
      }
   }

   return isValid;
};

export const isValidZipCode = (zipCodeValue) => {
   return zipCodeValue.length === 5 && zipCodeValue.match(/^\d{5}$/);
}

export const hasNoSpecialCharacters = (value) => {
   const regex =
      /^[\w\s\d.,?!'"():;&%$@ÁÉÍÓÚáéíóúÀÈÌÒÙàèìòùÄËÏÖÜäëïöüÂÊÎÔÛâêîôû]+$/u;
   return regex.test(value);
};

export const hasNoNumbersAndSpecialCharacters = (value) => {
   const regex = /^[A-Za-zÀ-ÖØ-öø-ÿ\s]*$/;
   return regex.test(value);
}

export const validCompanyName = (value) => {
   const regex = /^[A-Za-z0-9]([A-Za-z0-9@.#&!\s-])*$/;
   return regex.test(value);
}

export const validUserIg = (value) => {
   const regex = /[a-z\d-_]{1,255}\s*$/i;
   return regex.test(value);
}

export const validLinkedin = (value) => {
   return value.toLowerCase().includes('linkedin.');
}

export const validMemberNumber = (value) => {
   const regex = /^\d{7}$/g;
   return regex.test(value);
}
