import React, { useContext, useEffect, useState } from 'react';
import NobelDashboardModal from '../nobelDashboardModal';

import './userAdminModal.scss';
import InputField from '../../inputField';
import SectionRadioButton from './sectionRadioButton';
import Button from '../../button';
import {
	emailValidation,
	isValidFullName,
	requiredValidation,
} from '../../../services/validations';
import {
	addAdmin,
	getAdminsList,
	getAdminById,
	updateAdminById,
} from '../../../services/api/admins';
import { toast } from 'react-toastify';
import { AppContext } from '../../../context';
import Loader from '../../loader';

import {
	validatePhoneNumberUSA,
	formattedPhoneNumber,
} from '../../../helpers/formattedPhoneNumber';

import { AsYouType } from 'libphonenumber-js';

import InputPhoneNumber from '../../inputs/PhoneNumber';
import { getRoleOptions } from 'services/api/roles';

const toastOptions = {
	position: 'bottom-center',
	autoClose: 7000,
	hideProgressBar: true,
	closeOnClick: true,
	pauseOnHover: true,
	draggable: true,
	progress: undefined,
	theme: 'dark',
};

function UserAdminModal(props) {
	const { isOpen, onRequestClose, section, clickedItemId, setClickedItemId } =
		props;
	const { setAdmins, adminById, setAdminById, setLoading } =
		useContext(AppContext);

	const [isFormValid, setIsFormValid] = useState(false);

	const [loadingClicked, setLoadingClicked] = useState(false);

	const [adminForm, setAdminForm] = useState({
		fullName: { value: adminById?.full_name || '', valid: undefined },
		email: { value: adminById?.email || '', valid: undefined },
		phoneNumber: { value: adminById?.phone_number || '', valid: undefined },
		roleId: { value: adminById?.role?.id || 0, valid: undefined },
	});
	const rolesTitles = {
		1: 'Admin',
		2: 'General Manager',
		4: 'General Relations Manager',
	}
	let requestError = '';

	const [options, setOptions] = useState([]);

	useEffect(() => {
		async function getOptions() {
			setLoading(true);
			const tierOptions = await getRoleOptions();
			if (!tierOptions.error) {
				setOptions([...options, ...tierOptions]);
			} else {
				console.log(tierOptions.data);
			}
			setLoading(false);
		}
		if (!options.length) getOptions();
	}, [options, setOptions, setLoading]);

	// USEFFECTS VALIDATIONS
	useEffect(() => {
		if (section) {
			if (
				adminForm.fullName?.valid &&
				adminForm.email?.valid &&
				adminForm.roleId?.valid
			) {
				if (adminForm.phoneNumber.value && adminForm.phoneNumber.valid) return setIsFormValid(true);
				if (adminForm.phoneNumber.value && !adminForm.phoneNumber.valid) return setIsFormValid(false);
				setIsFormValid(true);			
			} else {
				setIsFormValid(false);
			}
		}
		if (clickedItemId && !adminById) {
			(async () => {
				setLoadingClicked(true);
				const admin = await getAdminById(clickedItemId);
				if (!admin?.error) {
					setAdminById(admin);
					setAdminForm((prev) => ({
						fullName: {
							value: admin.full_name || '',
							valid:
								admin?.full_name && validateField('fullName', admin?.full_name),
						},
						email: {
							value: admin?.email || '',
							valid: admin?.email && validateField('email', admin?.email),
						},
						phoneNumber: {
							value: admin?.phone_number || '',
							valid:
								admin?.phone_number &&
								formattedPhoneNumber(admin?.phone_number) &&
								validateField('phoneNumber', admin?.phone_number),
						},
						roleId: { value: admin?.role?.id, valid: true },
					}));
				} else {
					console.log('ERROR: ', admin.data);
				}
				setLoadingClicked(false);
			})();
		}
	}, [
		adminForm,
		clickedItemId,
		adminById,
		section,
		setAdminById,
		setLoadingClicked,
		formattedPhoneNumber,
	]);

	const newAdminSetValues = (e) => {
		const fieldName = e.target.name;
		let fieldValue = e.target.value;

		if (fieldName === 'phoneNumber') {
			// Check if the field is cleared
			if (fieldValue === '') {
				// Handle the case when the field is cleared
				fieldValue = '';
				return setAdminForm((prev) => ({
					...prev,
					[fieldName]: {
						value: fieldValue,
						valid: undefined,
					},
				}));
			} else {
				// Try to parse the input as a phone number
				const parsedNumber = new AsYouType('US').input(fieldValue);

				// Check if the parsed number is valid
				if (fieldValue.length > 6) {
					fieldValue = parsedNumber;
				}
				return setAdminForm((prev) => ({
					...prev,
					[fieldName]: {
						value: fieldValue,
						valid:
							requiredValidation(fieldValue) &&
							validateField(fieldName, fieldValue),
					},
				}));
			}
		}

		setAdminForm((prev) => ({
			...prev,
			[fieldName]: {
				value: fieldValue,
				valid:
					requiredValidation(fieldValue) &&
					validateField(fieldName, fieldValue),
			},
		}));
	};

	const newAdminSetRole = (id) => {
		setAdminForm((prev) => ({
			...prev,
			roleId: { value: id, valid: true },
		}));
	};

	const newAdminSubmit = async (e) => {
		e.preventDefault();
		if (isFormValid) {
			setLoading(true);
			if (section === 'add-admin') {
				const responseAddAdmin = await addAdmin(adminForm);
				if (!responseAddAdmin.error) {
					let roleId = responseAddAdmin?.new_admin?.role?.id;
					let roleTitle = 'admin role';
					if (rolesTitles[roleId]) roleTitle = rolesTitles[roleId];
					let preposition = 'a';
					if (roleTitle && roleTitle[0] === 'A') preposition = 'an';
					
					notifyAdmin('success', preposition, roleTitle);
					onCloseHandler();
				} else {
					requestError = responseAddAdmin?.data?.response_description;
					notifyAdmin('request error');
				}
			}
			if (section === 'edit-admin') {
				const responseUpdatedAdmin = await updateAdminById(
					clickedItemId,
					adminForm,
				);
				if (!responseUpdatedAdmin.error) {
					notifyAdmin('success-edit');
					onCloseHandler();
				} else {
					requestError = responseUpdatedAdmin?.data?.response_description;
					notifyAdmin('request error');
				}
			}
			const admin = await getAdminsList();
			if (!admin.error) {
				setAdmins(admin);
			} else {
				requestError = admin?.data?.response_description;
				notifyAdmin('request error');
			}
			setLoading(false);
		} else {
			notifyAdmin('error');
		}
	};

	const notifyAdmin = (notificationType, preposition, roleTitle) => {
		switch (notificationType) {
			case 'success':
				toast.success(
					`${adminForm.fullName.value} has been assigned as ${preposition} ${roleTitle}. An email with details has been sent to them`,
					toastOptions,
				);
				break;
			case 'success-edit':
				toast.success(
					`${adminForm.fullName.value} was successfully updated`,
					toastOptions,
				);
				break;
			case 'error':
				toast.error(
					`Error: Make sure all required fields are filled correctly and try again.`,
					toastOptions,
				);
				break;
			default:
				toast.error(`Error: ${requestError}`, toastOptions);
				break;
		}
	};

	// VALIDATIONS
	const validateField = (fieldName, value) => {
		switch (fieldName) {
			case 'email':
				return emailValidation(value);
			case 'fullName':
				return isValidFullName(value);
			case 'phoneNumber':
				if (value.length > 0) {
					return validatePhoneNumberUSA(value);
				} else {
					return undefined;
				}
			default:
				return undefined;
		}
	};

	const onCloseHandler = () => {
		onRequestClose();
		setAdminById(null);
		setClickedItemId(null);
		setAdminForm({
			fullName: { value: '', valid: undefined },
			email: { value: '', valid: undefined },
			phoneNumber: { value: '', valid: undefined },
			roleId: { value: 0, valid: undefined },
		});
	};

	return (
		<NobelDashboardModal isOpen={isOpen} onRequestClose={onCloseHandler}>
			{loadingClicked && <Loader />}
			{!loadingClicked && (
				<div className="UserAdminModal">
					{section === 'add-admin' && (
						<h3 className="UserAdminModal__title">New Admin</h3>
					)}
					{section === 'edit-admin' && (
						<h3 className="UserAdminModal__title">Edit Admin</h3>
					)}
					<div className="UserAdminModal__form">
						<div className="UserAdminModal__form__section">
							<InputField
								displayLabel={`Full Name${section === 'add-admin' ? '*' : ''}`}
								id="fullName"
								name="fullName"
								type="text"
								onChange={newAdminSetValues}
								onBlur={newAdminSetValues}
								value={adminForm.fullName.value}
								required
								autoComplete="off"
								inputError={adminForm.fullName?.valid === false ? true : false}
							/>
							{adminForm.fullName.valid === false && (
								<small className="UserAdminModal__form__section__input-error">
									Please Full Name is required
								</small>
							)}
							<InputField
								displayLabel={`Email${section === 'add-admin' ? '*' : ''}`}
								id="email"
								name="email"
								type="email"
								onChange={newAdminSetValues}
								onBlur={newAdminSetValues}
								value={adminForm.email.value}
								required
								autoComplete="off"
								inputError={adminForm.email?.valid === false ? true : false}
							/>
							{adminForm.email.valid === false && (
								<small className="UserAdminModal__form__section__input-error">
									Please enter a valid email
								</small>
							)}
							<InputPhoneNumber
								displayLabel="Phone"
								id="phoneNumber"
								name="phoneNumber"
								type="text"
								onChange={newAdminSetValues}
								onBlur={newAdminSetValues}
								value={adminForm.phoneNumber.value}
								autoComplete="off"
								inputError={
									adminForm.phoneNumber?.valid === false ? true : false
								}
							/>
							{adminForm.phoneNumber.valid === false && (
								<small className="UserAdminModal__form__section__input-error">
									Please enter a valid phone
								</small>
							)}
						</div>
						<SectionRadioButton
							displayLabel={`Role${section === 'add-admin' ? '*' : ''}`}
							onChange={newAdminSetRole}
							isError={adminForm.roleId?.valid === false ? true : false}
							value={adminForm.roleId?.value}
							options={options}
						/>
					</div>
					<div className="UserAdminModal__buttons_section">
						<Button
							buttonWrapperClass="cancel"
							displayLabel="Cancel"
							color="black"
							size="full"
							onClick={() => onCloseHandler()}
						/>
						<Button
							buttonWrapperClass="add-admin"
							buttonValidation={isFormValid}
							displayLabel={
								section === 'add-admin'
									? 'Add Admin'
									: section === 'edit-admin'
									? 'Edit Admin'
									: ''
							}
							color="white"
							size="full"
							onClick={newAdminSubmit}
						/>
					</div>
				</div>
			)}
		</NobelDashboardModal>
	);
}

export default UserAdminModal;
