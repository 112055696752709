import React from 'react';
import NobelPopUpModal from '../nobelPopUpModal';
import Button from '../../button';
import NobleIcon from '../../nobleIcon';
import { deleteMemberWarningMessage } from 'services/constants';
import './deleteModal.scss';

const description = `Lorem ipsum dolor sit amet consectetur. Ultricies tempor elementum est
enim amet.Volutpat sed feugiat aliquam mattis consectetur dolor.Sed
sollicitudin ut faucibus neque dolor odio.Fermentum vel amet id.`

function DeleteModal({
	isDeleteModalOpen,
	onCloseHandler,
	deleteHandler,
	clickedItem,
	deleteItemType = '',
	descriptionDelete = description
}) {
	const deleteWarningDescription = clickedItem?.has_active_subscription_date ? deleteMemberWarningMessage : descriptionDelete;
	return (
		<NobelPopUpModal
			isOpen={isDeleteModalOpen}
			onRequestClose={onCloseHandler}
			className="DeleteModal">
			<div className="DeleteModal__header">
				<h3>
					Delete {clickedItem?.full_name || clickedItem?.fullname}
					{' ?'}
				</h3>
			</div>
			<div className="DeleteModal__content">
				<p>{deleteWarningDescription}</p>
			</div>
			<div className="DeleteModal__buttons">
				<Button
					onClick={() => deleteHandler(clickedItem?.id ? clickedItem.id : clickedItem.app_id)}
					displayLabel={`Delete ${deleteItemType}`}
					buttonWrapperClass="DeleteModal__buttons__button DeleteModal__buttons__button--delete"
					size="full">
					<NobleIcon name="delete" />
				</Button>
				<Button
					displayLabel={`No, Save ${deleteItemType}`}
					buttonWrapperClass="DeleteModal__buttons__button"
					color="white"
					size="full"
					onClick={onCloseHandler}
				/>
			</div>
		</NobelPopUpModal>
	);
}

export default DeleteModal;
