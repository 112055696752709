export const APP_STATUS = {
	NEW: 'new',
	REJECTED: 'rejected',
	WAITLIST: 'waitlist',
	APPROVED: 'approved',
	PAID: 'paid',
};

export const NOTIFICATION = {
	REJECT: 'reject',
	WAITLIST: 'waitlist',
	ACCEPT: 'accept',
};

export const PERMISSIONS = {
	// present in frontend and backend
	ADMIN_DASHBOARD_ACCESS: 'admin_dashboard_access',
	ADMIN_CAN_APPROVE: 'admin_can_approve',
	CAN_EDIT_MEMBERS: 'can_edit_members',
	CAN_SEND_MEMBER_REMINDERS: 'can_send_member_reminders',
	CAN_CANCEL_MEMBER_MEMBERSHIP: 'can_cancel_member_membership',
	CAN_DELETE_MEMBER: 'can_delete_member',
	// only present in frontend
	CAN_EXPORT_DATA: 'can_export_data',
	CAN_SHOW_APPLICATIONS: 'can_show_applications',
	CAN_SHOW_ADMINS: 'can_show_admins',
	CAN_SHOW_MEMBER_NUMBERS: 'can_show_member_numbers',
	CAN_SHOW_THREE_POINTS: 'can_show_three_points',
	CAN_SHOW_MULTIPLE_FILTERS: 'can_show_multiple_filters',
	CAN_SHOW_STATUS_FILTERS: 'can_show_status_filters',
	CAN_SHOW_MEMBER_OPTIONS: 'can_show_member_options',

	ACCESS_TO_APPLICATIONS_PAGE: 'access_to_applications_page',
	ACCESS_TO_MEMBERS_PAGE: 'access_to_members_page',
	ACCESS_TO_ADMINS_PAGE: 'access_to_admins_page',
};
