import React, { useState, useContext, useEffect } from 'react';
import NobelDashboardModal from '../nobelDashboardModal';
import cn from 'classnames';
import moment from 'moment';
import FormDisplayGroup from '../../formDisplayGroup';
import Button from '../../../components/button';
import './userApplicationModal.scss';
import { toast } from 'react-toastify';
import NobleIcon from '../../nobleIcon';
import ThreeDotsOptionsMenu from '../../threeDotsOptionsMenu';
import { AppContext } from '../../../context/AppContext';
import { getApplicationById } from '../../../services/api/applications/getApplication';
import { APP_STATUS } from '../../../helpers/constants';
import { updateApplicationById } from '../../../services/api/applications/updateApplication';
import { getApplicationsList } from '../../../services/api/applications/getApplications';
import { getTotalApplications } from '../../../services/api/applications/getTotalApplications';
import { deleteApplicationById } from '../../../services/api/applications/deleteApplication';
import { getTotalMemebers } from '../../../services/api/members/getTotalMembers';
import { Info } from '../../icons';

import DeleteModal from '../deleteModal';
import Loader from '../../loader';
import { formattedPhoneNumber } from '../../../helpers/formattedPhoneNumber';
import FormDisplayImage from '../../formDisplayImage';
import ApproveModal from '../approveModal';
import { deleteApplicationWarningMessage } from 'services/constants';

const toastOptions = {
	position: 'bottom-center',
	autoClose: 7000,
	hideProgressBar: true,
	closeOnClick: true,
	pauseOnHover: true,
	draggable: true,
	progress: undefined,
	theme: 'dark',
};

function UserApplicationModal(props) {
	const { isOpen, setIsOpen, onRequestClose, clickedItemId, className } = props;
	const {
		clickOutsideMenuRef,
		application,
		setApplication,
		setLoading,
		setApplications,
		applicationsSelected,
		setApplicationsSelected,
		setTotalApps,
		setTotalMembers,
	} = useContext(AppContext);
	const [toggleRejectedMenu, setToggleRejectedMenu] = useState(false);
	const [loadingClicked, setLoadingClicked] = useState(false);
	const [isOpenDeleteApplicationModal, setIsOpenDeleteApplicationModal] =
		useState(false);
	const [isOpenApproveApplicationModal, setIsOpenApproveApplicationModal] =
		useState(false);

	useEffect(() => {
		async function getApp() {
			setLoadingClicked(true);
			const application = await getApplicationById(clickedItemId);
			if (!application.error) {
				setApplication(application);
			} else {
				console.log(application.data);
			}
			setLoadingClicked(false);
		}

		if (!isOpen) setApplication(null);
		if (isOpen && !application) getApp();
	}, [clickedItemId, application, setApplication, isOpen, setLoadingClicked]);

	const refreshApps = async () => {
		const status = applicationsSelected.selected;
		setLoading(true);
		const totalApps = await getTotalApplications();
		const totalMembers = await getTotalMemebers();
		const applicationsList = await getApplicationsList(status);

		if (!totalMembers.error) {
			setTotalMembers(totalMembers);
		}
		if (!totalApps.error) {
			setTotalApps(totalApps);
		} else {
			console.log(totalApps.data);
		}
		if (!applicationsList.error) {
			setApplications(applicationsList);
			setApplicationsSelected({
				...applicationsSelected,
				applications: applicationsList,
			});
		} else {
			console.log(applicationsList.data);
		}

		setLoading(false);
	};

	const closeHandlerApproveApplicationModal = () => {
		setIsOpenApproveApplicationModal(!isOpen);
	};

	const updateApp = async (status) => {
		if (clickedItemId) {
			setLoading(true);
			const updatedApp = await updateApplicationById(clickedItemId, status);
			if (!updatedApp.error) {
				setIsOpenApproveApplicationModal(false);
				setApplication(updatedApp);
				await refreshApps();
				notifyApplication(status);
			} else {
				setIsOpenApproveApplicationModal(false);
				toast.error(updatedApp?.data?.response_description, toastOptions);
			}
			if (status === APP_STATUS.APPROVED) setIsOpen(false);
			setLoading(false);
		}
	};

	const notifyApplication = async (status) => {
		switch (status) {
			case APP_STATUS.APPROVED:
				toast.success(
					`${application.first_name} ${application.last_name} Application Successfully Approved`,
					toastOptions,
				);
				break;
			case APP_STATUS.WAITLIST:
				toast.warning(
					`${application.first_name} ${application.last_name} Application on Waitlist`,
					toastOptions,
				);
				break;
			case APP_STATUS.REJECTED:
				toast.error(
					`${application.first_name} ${application.last_name} Application Rejected`,
					toastOptions,
				);
				break;
			default:
				break;
		}
	};

	const closeHandlerDeleteApplicationModal = () => {
		setIsOpenDeleteApplicationModal(!isOpen);
	};

	const deleteApplication = async (applicationId) => {
		setLoading(true);
		const response = await deleteApplicationById(applicationId);
		if (!response.error) {
			console.log('response', response);
			setIsOpenDeleteApplicationModal(false);
			toast.success(`Application deleted`, toastOptions);
			await refreshApps();
		} else {
			console.log('response', response);
			setIsOpenDeleteApplicationModal(false);
			toast.error(`Error deleting Application`, toastOptions);
		}
		setIsOpen(false);
		setLoading(false);
	};

	return (
		<NobelDashboardModal isOpen={isOpen} onRequestClose={onRequestClose}>
			{loadingClicked && <Loader />}
			{!loadingClicked && (
				<div className={'UserApplicationModal ' + className}>
					{application?.status !== APP_STATUS.NEW && (
						<span
							className={
								'UserApplicationModal__status-badge ' + application?.status
							}>
							{application?.status}
						</span>
					)}
					<h3 className="UserApplicationModal__title">User Application</h3>
					<div className="UserApplicationModal__form">
						<div className="UserApplicationModal__form__section one">
							<div>
								<FormDisplayGroup
									fieldLabel="Full Name"
									fieldValue={`${application?.first_name} ${application?.last_name}`}
								/>
								<FormDisplayGroup
									fieldLabel="Address Line 1"
									fieldValue={application?.address_line_1}
								/>
								<FormDisplayGroup
									fieldLabel="Address Line 2"
									fieldValue={application?.address_line_2}
								/>
								<FormDisplayGroup
									fieldLabel="City"
									fieldValue={application?.city}
								/>
								<FormDisplayGroup
									fieldLabel="State"
									fieldValue={application?.state?.name}
								/>
								<FormDisplayGroup
									fieldLabel="Zip Code"
									fieldValue={application?.zip_code}
								/>
							</div>
							<div>
								<FormDisplayImage url={application?.profile_picture_url} />
							</div>
						</div>
						<div className="UserApplicationModal__form__section two-col">
							<FormDisplayGroup
								fieldLabel="Email"
								fieldValue={application?.email}
							/>
							<FormDisplayGroup
								fieldLabel="Phone Number"
								fieldValue={
									application?.phone_number
										? formattedPhoneNumber(application?.phone_number)
										: null
								}
							/>
							<FormDisplayGroup
								fieldLabel="Birthday"
								fieldValue={
									application?.birth_date
										? moment(application?.birth_date, 'YYYY-MM-DD').format(
												'MM/DD/YYYY',
										  )
										: null
								}
							/>
							<FormDisplayGroup
								fieldLabel="Instagram Handle"
								fieldValue={application?.instagram_handle}
							/>
							<FormDisplayGroup
								fieldLabel="LinkedIn (URL)"
								fieldValue={application?.linkedin_url}
							/>
							<FormDisplayGroup
								fieldLabel="Company"
								fieldValue={application?.company}
							/>
							<FormDisplayGroup
								fieldLabel="Ocupation"
								fieldValue={application?.occupation}
							/>
						</div>
						<div className="UserApplicationModal__form__section two-col">
							<FormDisplayGroup
								fieldLabel="Primary Restaurant"
								fieldValue={application?.venue?.primary}
							/>
							<FormDisplayGroup
								fieldLabel="Referred By"
								fieldValue={
									application?.referred_by && application?.referred_by
								}
							/>
							<FormDisplayGroup
								fieldLabel="Go-To Dinner Beverage"
								fieldValue={application?.go_to_dinner_beverage}
							/>
							<FormDisplayGroup
								fieldLabel="How did you hear about us?*"
								fieldValue={application?.hear_about_us}
							/>
						</div>
						<div className="UserApplicationModal__form__section two-col">
							<FormDisplayGroup
								fieldLabel="Tier Type"
								fieldValue={application?.tier_type}
								icon={<Info />}
							/>
						</div>
						<div className="UserApplicationModal__form__section two-col referral-section">
							<div className="FormDisplayGroup FormDisplayGroupReferral">
								<label className="FormDisplayGroup__label">Has Referral?</label>
								<div className="isReferral">
									<span
										className={cn([
											'isReferral__option',
											{ active: application?.referred_by },
										])}>
										Yes
									</span>
									<span
										className={cn([
											'isReferral__option',
											{ active: !application?.referred_by },
										])}>
										No
									</span>
								</div>
							</div>
							<FormDisplayGroup
								fieldLabel="GRM Referral"
								fieldValue={application?.grm?.name}
							/>
						</div>
						{application?.spouse_first_name ? (
							<div className="UserApplicationModal__form__section one">
								<div>
									<FormDisplayGroup
										fieldLabel="Spouse Full Name"
										fieldValue={`${application?.spouse_first_name} ${application?.spouse_last_name}`}
									/>
									<FormDisplayGroup
										fieldLabel="Spouse Email"
										fieldValue={application?.spouse_email}
									/>
									<FormDisplayGroup
										fieldLabel="Spouse Phone Number"
										fieldValue={
											application?.spouse_phone_number
												? formattedPhoneNumber(application?.spouse_phone_number)
												: null
										}
									/>
								</div>
							</div>
						) : (
							<></>
						)}

						<div className="UserApplicationModal__form__section buttons-section">
							<div
								className={cn([
									'UserApplicationModal__form__section__left-side-btns',
									{ not_rejected: application?.status !== APP_STATUS.REJECTED },
								])}>
								{application?.status !== APP_STATUS.REJECTED && (
									<Button
										onClick={() => {
											onRequestClose();
											updateApp(APP_STATUS.REJECTED);
										}}
										displayLabel="Reject"
									/>
								)}
								{application?.status !== APP_STATUS.WAITLIST && (
									<Button
										onClick={() => {
											onRequestClose();
											updateApp(APP_STATUS.WAITLIST);
										}}
										displayLabel="Waitlist"
									/>
								)}
								<Button
									buttonWrapperClass="accept-btn"
									displayLabel="Accept"
									color={
										application?.status !== APP_STATUS.REJECTED
											? 'white'
											: 'black'
									}
									onClick={() => {
										setIsOpenApproveApplicationModal(true);
									}}
								/>
							</div>
							{application?.status === APP_STATUS.REJECTED && (
								<ThreeDotsOptionsMenu
									toggleMenu={toggleRejectedMenu}
									setToggleMenu={setToggleRejectedMenu}
									outsideClickRef={clickOutsideMenuRef}
									menuContent={
										<>
											<button
												onClick={() => setIsOpenDeleteApplicationModal(true)}
												className="ThreeDotsOptionsMenu__item delete-option">
												<NobleIcon name="delete" />
												Delete Application
											</button>
										</>
									}
								/>
							)}
						</div>
					</div>
				</div>
			)}

			<DeleteModal
				isDeleteModalOpen={isOpenDeleteApplicationModal}
				onCloseHandler={closeHandlerDeleteApplicationModal}
				deleteHandler={deleteApplication}
				clickedItem={application}
				descriptionDelete={deleteApplicationWarningMessage}
				deleteItemType="Application"
			/>

			<ApproveModal
				isApproveModalOpen={isOpenApproveApplicationModal}
				onCloseHandler={closeHandlerApproveApplicationModal}
				approveHandler={() => updateApp(APP_STATUS.APPROVED)}
				clickedItem={application}
				approveItemType="Application"
			/>
		</NobelDashboardModal>
	);
}

export default UserApplicationModal;
