import React, { useCallback, useContext, useEffect, useState } from 'react';
import cn from 'classnames';
import moment from 'moment/moment';
import DashboardLayout from '../../components/dashboard/dashboardLayout';
import NobleSelect from '../../components/nobleSelect';
import useRefesh from '../../hooks/useRefresh';

import './members.scss';
import MembersList from './membersList';
import OptionsButtons from '../../components/optionsButtons';
import OptionButton from '../../components/optionsButtons/optionButton';
import { getMemberList } from '../../services/api/members/getMembers';
import { AppContext, AuthContext } from '../../context';
import Button from '../../components/button';
import NobleIcon from '../../components/nobleIcon';
import Loader from '../../components/loader';
import { getSelectOptions } from '../../services/api/form';
import { downloadAsCsv } from '../../helpers/csv';
import InputSearch from '../../components/inputs/Search';
import { getTotalMemebers } from 'services/api/members/getTotalMembers';

import { usePagination } from '@table-library/react-table-library/pagination';

import { exportOptions } from './constants';
import {
	defaultSelectStyles,
	controlStyles,
} from 'components/nobleSelect/constants';
import { userHasPermission } from 'helpers/hasPermission';
import { PERMISSIONS } from 'helpers/constants';
import { useNavigate } from 'react-router-dom';
import UserMemberModal from 'components/modals/userMemberModal';

function Members() {
	const {
		members,
		setMembers,
		membersSelected,
		setMembersSelected,
		totalMembers,
		setTotalMembers,
		refetchMembers,
		setRefetchMembers,
		setLoading,
		loading,
		selects,
		setSelects,
		screenDimensions,
	} = useContext(AppContext);
	const { user } = useContext(AuthContext);

	const navigate = useNavigate();

	useEffect(() => {
		if (user && !userHasPermission(user, PERMISSIONS.ACCESS_TO_MEMBERS_PAGE)) {
			navigate('/access-denied');
		}
	}, [user, navigate]);

	const [actionItem, setActionItem] = useState(null);
	const [isOpen, setIsOpen] = useState(false);
	const [clickedItem, setClickedItem] = useState(null);
	const [isModalInvestor, setIsModalInvestor] = useState(false);
	const ALL = 'all';
	const [optionsMemberTypes, setOptionsMemberTypes] = useState([
		{ value: ALL, label: 'All' },
	]);
	const [optionsTiers, setOptionsTiers] = useState([
		{ value: ALL, label: 'All' },
	]);
	const [optionsStatuses, setOptionsStatuses] = useState([
		{ value: ALL, label: 'All' },
	]);
	const [optionsGrms, setOptionsGrms] = useState([
		{ value: ALL, label: 'All' },
	]);
	const [filterParameter, setFilterParameter] = useState({
		tier: ALL,
		status: ALL,
		memberType: ALL,
		grm: ALL,
	});
	const [search, setSearch] = useState('');
	const [dashboardLoaded, setDashboardLoaded] = useState(false);
	const [totalPages, setTotalPages] = useState(0);
	const [isDesktop, setIsDesktop] = useState(false);
	const statuses = {
		0: 'overdue',
		1: 'pending',
		2: '',
		3: 'canceled',
		4: 'expired',
	};
	const LIMIT = 15;
	let data = { nodes: membersSelected.members };

	useEffect(() => {
		if (screenDimensions >= 1180) {
			setIsDesktop(true);
		} else {
			setIsDesktop(false);
		}
	}, [screenDimensions]);

	// TOTAL MEMBERS
	const fetchGetTotals = useCallback(async () => {
		setLoading(true);
		if (!totalMembers) {
			const totalMember = await getTotalMemebers();
			if (!totalMember.error) {
				setTotalMembers(totalMember);
			} else {
				console.log('ERROR: ', totalMember.data);
			}
		}
		setLoading(false);
	}, []);
	useEffect(() => {
		fetchGetTotals();
	}, [fetchGetTotals]);

	// USECALLBACK
	const fetchDataCallback = useCallback(async () => {
		setLoading(true);
		const memberList = await getMemberList('active');
		if (!memberList.error) {
			setMembers(memberList);
			setMembersSelected({
				selected: 'active',
				members: memberList,
			});
			setTotalPages(Math.ceil(memberList.length / LIMIT));
			setDashboardLoaded(true);
		}
		setLoading(false);
	}, [setMembers, setLoading, setMembersSelected]);

	const fetchSelectsCallback = useCallback(async () => {
		setLoading(true);
		const selectsResponse = await getSelectOptions();
		if (!selectsResponse.error) {
			setSelects(selectsResponse);
			setOptionsTiers((prev) => [...prev, ...selectsResponse.tiers]);
			setOptionsStatuses((prev) => [
				...prev,
				...selectsResponse.paymentStatuses,
			]);
			setOptionsMemberTypes((prev) => [
				...prev,
				...selectsResponse.memberTypes,
			]);
			setOptionsGrms((prev) => [...prev, ...selectsResponse.grms]);
		} else {
			console.error('Error fetching selects data:', selectsResponse.data);
		}
		setLoading(false);
	}, [setLoading, setSelects]);

	// USE EFFECT
	useEffect(() => {
		if (refetchMembers) {
			fetchDataCallback();
		}
		if (refetchMembers) {
			setRefetchMembers(false);
		}
	}, [fetchDataCallback, refetchMembers, setRefetchMembers, members?.length]);

	useEffect(() => {
		if (!selects) {
			fetchSelectsCallback();
		}
	}, [fetchSelectsCallback, selects]);

	useRefesh();

	data = {
		nodes: data?.nodes?.filter((item) => {
			const fullNameMatch =
				item?.full_name &&
				item.full_name.toLowerCase().includes(search.toLocaleLowerCase());
			const idMatch =
				item?.member_number &&
				item.member_number
					.toString()
					.toLowerCase()
					.includes(search.toLocaleLowerCase());
			const birthDayMatch =
				item?.birth_date &&
				item.birth_date.toLowerCase().includes(search.toLocaleLowerCase());
			const memberSinceMatch =
				item?.member_since &&
				item.member_since.toLowerCase().includes(search.toLocaleLowerCase());
			const tierTypeMatch =
				item?.tier?.type &&
				item.tier.type.toLowerCase().includes(search.toLocaleLowerCase());
			const statusMatch =
				item?.status?.title &&
				item.status.title.toLowerCase().includes(search.toLocaleLowerCase());
			const statusTypeMatch =
				item?.status?.type &&
				item.status.type.toLowerCase().includes(search.toLocaleLowerCase());
			const email =
				item?.email &&
				item.email.toLowerCase().includes(search.toLocaleLowerCase());
			return (
				fullNameMatch ||
				idMatch ||
				birthDayMatch ||
				memberSinceMatch ||
				tierTypeMatch ||
				statusMatch ||
				email ||
				statusTypeMatch
			);
		}),
	};

	const filterBySelects = () => {
		setSearch('');
		let { memberType, tier, status, grm } = filterParameter;
		let newMemberState = [...members];

		const applyTypeFilter = (state) => {
			if (memberType === 0) {
				return [...state]?.filter(
					(member) =>
						member.is_investor === Boolean(memberType) &&
						member.has_active_subscription_date === true,
				);
			}
			if (memberType === 1) {
				return [...state]?.filter(
					(member) => member.is_investor === Boolean(memberType),
				);
			}
			if (memberType === 2) {
				return [...state]?.filter(
					(member) => member.is_spouse === Boolean(memberType),
				);
			}
			if (memberType === 3) {
				return [...state]?.filter(
					(member) =>
						member.is_investor === false &&
						member.member_number &&
						member.tier?.amount === 0 &&
						member.has_active_subscription_date === true,
				);
			}
		};
		const applyTierFilter = (state) => {
			return [...state]?.filter((member) => member.tier.id === tier);
		};
		const applyStatusFilter = (state) => {
			return status === 2
				? [...state]?.filter((member) => member.status.type === statuses[2])
				: [...state]?.filter((member) =>
						member.status.type?.toLowerCase().includes(statuses[status]),
				  );
		};
		const applyGrmFilter = (state) => {
			return [...state]?.filter((member) => member.grm.id === grm);
		};

		if (memberType !== ALL) newMemberState = applyTypeFilter(newMemberState);
		if (tier !== ALL) newMemberState = applyTierFilter(newMemberState);
		if (status !== ALL) newMemberState = applyStatusFilter(newMemberState);
		if (grm !== ALL) newMemberState = applyGrmFilter(newMemberState);
		return setMembersSelected((prevState) => ({
			selected: prevState.selected,
			members: newMemberState,
		}));
	};

	useEffect(() => {
		filterBySelects();
	}, [filterParameter, membersSelected.selected]);

	const handleSelectGrmFilter = (value) => {
		setFilterParameter({
			tier: ALL,
			status: ALL,
			memberType: ALL,
			grm: value,
		});
		pagination.fns.onSetPage(0);
	};

	const openAddInvestor = () => {
		setClickedItem(null);
		setActionItem('add-investor');
		setIsModalInvestor(true);
		setIsOpen(true);
	};

	const handleSelectTierFilter = (value) => {
		setFilterParameter({
			tier: value,
			status: ALL,
			memberType: ALL,
			grm: ALL,
		});
		pagination.fns.onSetPage(0);
	};

	const handleSelectMemberTypeFilter = (value) => {
		setFilterParameter({
			tier: ALL,
			status: ALL,
			memberType: value,
			grm: ALL,
		});
		pagination.fns.onSetPage(0);
	};

	const handleSelectStatusFilter = (value) => {
		setFilterParameter({
			tier: ALL,
			status: value,
			memberType: ALL,
			grm: ALL,
		});
		pagination.fns.onSetPage(0);
	};

	const handleSearch = (event) => {
		setSearch(event.target.value);
		if (
			filterParameter.status !== ALL ||
			filterParameter.tier !== ALL ||
			filterParameter.memberType !== ALL ||
			filterParameter.grm !== ALL
		) {
			setFilterParameter({
				tier: ALL,
				status: ALL,
				memberType: ALL,
				grm: ALL,
			});
		}
		pagination.fns.onSetPage(0);
	};

	const doGet = useCallback(async (params) => {
		const { offset, limit } = params;
		if (!data) return;
		// Server logic
	}, []);

	const onPaginationChange = (action, state) => {
		doGet({
			offset: state.page * LIMIT,
			limit: LIMIT,
		});
	};

	const pagination = usePagination(
		data,
		{
			state: { page: 0, size: LIMIT },
			onChange: onPaginationChange,
		},
		{
			isServer: false,
		},
	);

	const handleExportCSV = async (e) => {
		if (loading || !dashboardLoaded) return false;
		const type = e.value;
		setLoading(true);
		let contentToExport = members;
		switch (type) {
			case 'filtered':
				contentToExport = data.nodes;
				break;
			case 'all':
			default:
				contentToExport = members;
		}
		const activeTable = [
			{ accessor: (item) => item.full_name, name: 'Name' },
			{ accessor: (item) => `\t${item.member_number}`, name: 'ID' },
			{ accessor: (item) => item.email, name: 'Email' },
			{ accessor: (item) => item.birth_date, name: 'Birthday' },
			{ accessor: (item) => item.member_since, name: 'Date Joined' },
			{
				accessor: (item) =>
					item.is_spouse
						? 'Spouse'
						: item.is_investor
						? 'Investor'
						: item.has_active_subscription_date
						? 'Paid'
						: 'N/A',
				name: 'Member Type',
			},
			{ accessor: (item) => item.tier.type, name: 'Tiers' },
			{
				accessor: (item) => item?.subscription?.type ?? 'N/A',
				name: 'Subscription Type',
			},
			{
				accessor: (item) => item?.tier?.amount ?? 'N/A',
				name: 'Cost',
			},
			{
				accessor: (item) =>
					item?.status?.title
						? `${
								item?.status?.type?.length > 0 ? item.status.type : 'Active'
						  } - ${item?.status?.title}`
						: 'N/A',
				name: 'Status',
			},
			{
				accessor: (item) => item?.venue?.name ?? 'N/A',
				name: 'Primary Restaurant',
			},
			{
				accessor: (item) => item?.grm_referral ?? 'N/A',
				name: 'GRM Referral',
			},
			{
				accessor: (item) => item?.referred_by ?? 'N/A',
				name: 'Referring member',
			},
		];
		if (membersSelected.selected === 'active')
			await downloadAsCsv(
				activeTable,
				contentToExport,
				`Noble33 - Member list - ${moment().format(`YYYY/MM/DD`)}`,
			);

		if (membersSelected.selected === 'inactive') {
			let inactiveTable = [...activeTable];
			inactiveTable.splice(7, 0, {
				accessor: (item) => item?.subscription?.canceled_at ?? 'N/A',
				name: 'Cancellation Date',
			});
			await downloadAsCsv(
				inactiveTable,
				contentToExport,
				`Noble33 - Member list - ${moment().format(`YYYY/MM/DD`)}`,
			);
		}
		setLoading(false);
	};

	const getMembersChangeActive = async (state) => {
		if (state === 'active' && membersSelected.selected !== 'active') {
			setLoading(true);
			const memberList = await getMemberList('active');
			if (!memberList.error) {
				setMembers(memberList);
				setMembersSelected({
					selected: 'active',
					members: memberList,
				});
			}
			setLoading(false);
		} else if (
			state === 'inactive' &&
			membersSelected.selected !== 'inactive'
		) {
			setLoading(true);
			const memberList = await getMemberList('inactive');
			if (!memberList.error) {
				setMembers(memberList);
				setMembersSelected({
					selected: 'inactive',
					members: memberList,
				});
			} else {
			}
			setLoading(false);
		}
	};

	const closeHandler = () => {
		setIsOpen(!isOpen);
		setClickedItem(null);
		setActionItem(null);
		setIsModalInvestor(false);
	};

	return (
		<DashboardLayout
			layoutClass="Members"
			layoutTitle="Members"
			filterTotal={membersSelected.members.length}
			contentTotal={
				userHasPermission(user, PERMISSIONS.CAN_SHOW_MEMBER_NUMBERS) &&
				(totalMembers ? totalMembers.total_members : '')
			}
			contentBtnExport={
				userHasPermission(user, PERMISSIONS.CAN_EXPORT_DATA) ? (
					<NobleSelect
						styles={{
							...defaultSelectStyles,
							control: (baseStyles) => ({
								...baseStyles,
								...controlStyles,
								minWidth: '188px',
							}),
						}}
						placeholder={
							<div className="Members__btn-export">
								<NobleIcon name="download" />
								<span>CSV Export</span>
							</div>
						}
						components={{
							IndicatorSeparator: () => false,
							DropdownIndicator: () => (
								<NobleIcon
									name="arrowDown"
									iconWrapperClass="NobleIcon__select--alt"
								/>
							),
						}}
						options={exportOptions}
						onChange={handleExportCSV}
						isSearchable={false}
						value={false}
					/>
				) : (
					<div className="Members__menu__search__two">
						<InputSearch
							id="search"
							type="search"
							name="search"
							placeholder="Search"
							value={search}
							onChange={(e) => handleSearch(e)}
						/>
					</div>
				)
			}
			contentSearch={
				isDesktop &&
				userHasPermission(user, PERMISSIONS.CAN_EXPORT_DATA) && (
					<>
						<div className="Members__menu__btn_add_member">
							<Button
								displayLabel={
									<div className="contain_add_member">
										<NobleIcon name="personAdd" />
										<span>Add Member</span>
									</div>
								}
								size="full"
								// buttonValidation={!isOpen}
								onClick={() => openAddInvestor()}
							/>
						</div>
						<div className="Members__menu__search">
							<InputSearch
								id="search"
								type="search"
								name="search"
								placeholder="Search"
								value={search}
								onChange={(e) => handleSearch(e)}
							/>
						</div>
					</>
				)
			}>
			<div className="Members__menu">
				{userHasPermission(user, PERMISSIONS.CAN_SHOW_STATUS_FILTERS) && (
					<OptionsButtons>
						<OptionButton
							onClick={() => getMembersChangeActive('active')}
							active={membersSelected.selected === 'active'}>
							Active <span>{totalMembers?.active_members}</span>
						</OptionButton>
						<OptionButton
							onClick={() => getMembersChangeActive('inactive')}
							active={membersSelected.selected === 'inactive'}>
							Inactive <span>{totalMembers?.inactive_members}</span>
						</OptionButton>
					</OptionsButtons>
				)}
				{!isDesktop && <div className="Members__menu__filter"></div>}

				{!isDesktop && userHasPermission(user, PERMISSIONS.CAN_EXPORT_DATA) && (
					<div className="Members__menu__mobile">
						<div className="Members__menu__search">
							<InputSearch
								id="search"
								type="search"
								name="search"
								placeholder="Search"
								value={search}
								onChange={(e) => handleSearch(e)}
							/>
						</div>
						<div className="Members__menu__btn_add_member">
							<Button
								displayLabel={
									<div className="contain_add_member">
										<NobleIcon name="personAdd" />
										<span>Add Member</span>
									</div>
								}
								size="full"
								// buttonValidation={!isOpen}
								onClick={() => openAddInvestor()}
							/>
						</div>
					</div>
				)}
				{userHasPermission(user, PERMISSIONS.CAN_SHOW_MULTIPLE_FILTERS) && (
					<div className="Members__menu__filter">
						<div className="Members__menu__filter__grms">
							<NobleSelect
								id="grmFilter"
								displayLabel="GRM"
								placeholder={
									optionsGrms?.find((grm) => {
										return grm.value === filterParameter.grm;
									})?.label
								}
								options={optionsGrms}
								value={filterParameter.grm}
								onChange={(e) => handleSelectGrmFilter(e.value)}
							/>
						</div>
						<div className="Members__menu__filter__member__types">
							<NobleSelect
								id="typeFilter"
								displayLabel="Type"
								placeholder={
									optionsMemberTypes?.find((memberType) => {
										return memberType.value === filterParameter.memberType;
									})?.label
								}
								options={optionsMemberTypes}
								value={filterParameter.memberType}
								onChange={(e) => handleSelectMemberTypeFilter(e.value)}
							/>
						</div>
						<div className="Members__menu__filter__tiers">
							<NobleSelect
								id="tierFilter"
								displayLabel="Tier"
								placeholder={
									optionsTiers?.find((tier) => {
										return tier.value === filterParameter.tier;
									})?.label
								}
								options={optionsTiers}
								value={filterParameter.tier}
								onChange={(e) => handleSelectTierFilter(e.value)}
							/>
						</div>
						<div className="Members__menu__filter__status">
							<NobleSelect
								id="statusFilter"
								displayLabel="Status"
								placeholder={
									optionsStatuses.find((status) => {
										return status.value === filterParameter.status;
									})?.label
								}
								options={optionsStatuses}
								value={filterParameter.status}
								onChange={(e) => {
									handleSelectStatusFilter(e.value);
								}}
							/>
						</div>
					</div>
				)}
			</div>
			<MembersList
				data={data}
				totalPages={totalPages}
				pagination={pagination}
				limit={LIMIT}
			/>
			{loading && <Loader />}
			{userHasPermission(user, PERMISSIONS.ACCESS_TO_MEMBERS_PAGE) &&
				isModalInvestor && (
					<UserMemberModal
						members={members}
						action={actionItem}
						setActionItem={setActionItem}
						isOpen={isOpen}
						// isModalOpen={false}
						onRequestClose={closeHandler}
						setClickedItem={setClickedItem}
					/>
				)}
		</DashboardLayout>
	);
}

export default Members;
