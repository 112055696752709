import React from 'react';
import InputField from '../../inputField';
import NobleIcon from '../../nobleIcon';

import './inputDisabled.scss';

function InputDisabled({ oneLine, ...rest }) {
	return (
		<div className="InputDisabled">
			<InputField {...rest} />

			{oneLine ? (
				<div className="InputDisabled__icon__relative">
					<NobleIcon name="lock" />
				</div>
			) : (
				<div className="InputDisabled__icon">
					<NobleIcon name="lock" />
				</div>
			)}
		</div>
	);
}

export default InputDisabled;
