import { axiosRequest } from '../../axiosRequest';

const adapterValidateForgotCode = ({ success, data }) => {
	if (success === false) return { error: true, data };

	return data;
};

export const validateForgotCode = async (forgotCode) => {
	const options = {
		method: 'get',
		url: '/dashboard/validate-code',
		params: forgotCode,
	};

	const response = await axiosRequest(options);

	const forgotCodeResponse = adapterValidateForgotCode(response);

	return forgotCodeResponse;
};
