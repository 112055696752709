import React from 'react';
import cn from 'classnames';
import config from '../../config'
import ImgWithFallback from '../img';
import DashboardLogo from '../../assets/images/logo_dashboard.webp';
import DashboardLogoPNG from '../../assets/images/logo_dashboard.png';
import './styles.scss';

function LeftContentLayout({
	layoutTitle = '',
	layoutClass = '',
	children,
}) {
	const urlVideo = `${config.CDN_MEDIA}login_video.mp4`;
	return (
		<div className={cn(['LeftContentLayout', { [layoutClass]: layoutClass }])}>
			<div className="LeftContentLayout__mainBox">
				<div className="LeftContentLayout__logo-and-title">
					<ImgWithFallback img={DashboardLogo} fallbackImg={DashboardLogoPNG} />
					{layoutTitle && layoutTitle?.length > 0 && <p>{layoutTitle}</p>}
				</div>
				{children}
			</div>
			<div className="LeftContentLayout__video">
				<video
					autoPlay
					poster="/bg-video-posterimage.jpg"
					loop="loop"
					muted="muted"
					volume="0"
					preload="none">
					<source src={urlVideo} type="video/mp4" />
				</video>
			</div>
		</div>
	);
}

export default LeftContentLayout;
