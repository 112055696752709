import React from 'react';
import { useState, useEffect, useContext } from 'react';
import Button from '../../components/button';
import InputField from '../../components/inputField';
import LoginLayout from '../../components/loginLayout';
import Loader from '../../components/loader';
import {
	requiredValidation,
	emailValidation,
} from '../../services/validations';
import './forgotPassword.scss';
import { AppContext } from '../../context';
import { forgotPassword } from '../../services/api/admins';

function ForgotPassword() {
	const { loading, setLoading } = useContext(AppContext);
	const [isFormValid, setIsFormValid] = useState(false);
	const [emailSended, setEmailSended] = useState(false);
	const [forgotForm, setForgotForm] = useState({
		email: { value: undefined, valid: undefined },
	});

	useEffect(() => {
		if (forgotForm.email.valid) {
			setIsFormValid(true);
		} else {
			setIsFormValid(false);
		}
	}, [forgotForm]);

	const forgotSetValues = (e) => {
		const fieldName = e.target.name;
		const fieldValue = e.target.value;
		setForgotForm((prevState) => ({
			...prevState,
			[e.target.name]: {
				value: e.target.value,
				valid:
					fieldName === 'email' &&
					requiredValidation(fieldValue) &&
					emailValidation(fieldValue),
			},
		}));
	};

	const forgotFieldOnFocus = (e) => {
		const fieldName = e.target.name;
		const fieldValue = e.target.value;
		setForgotForm((prevState) => ({
			...prevState,
			[fieldName]: { valid: undefined, value: fieldValue },
		}));
	};

	const forgotSubmit = async (e) => {
		e.preventDefault();
		if (isFormValid) {
			setLoading(true);
			const forgotPasswordSend = await forgotPassword({
				email: forgotForm.email.value,
			});
			if (!forgotPasswordSend?.error) {
				setEmailSended(true);
			} else {
				console.log('ERROR', forgotPassword.data);
			}
			setLoading(false);
		}
	};
	return (
		<LoginLayout
			layoutClass="ForgotPassword"
			layoutTitle={!emailSended ? 'Reset Your Password' : 'Check your email'}>
			{!emailSended && (
				<>
					<p className="ForgotPassword__text">
						Enter your email address and we’ll send you instructions on how to
						reset your password.
					</p>
					<form>
						<InputField
							displayLabel="Email"
							id="email"
							name="email"
							type="email"
							onChange={forgotSetValues}
							onBlur={forgotSetValues}
							onFocus={forgotFieldOnFocus}
							required
							autoComplete="off"
						/>
						{forgotForm.email.valid === false && (
							<small className="inputError">Please enter a valid email</small>
						)}
						<Button
							displayLabel="Reset Password"
							color="white"
							size="full"
							type="submit"
							buttonValidation={isFormValid}
							onClick={forgotSubmit}
						/>
					</form>
				</>
			)}
			{emailSended && (
				<>
					<p className="ForgotPassword__text">
						We just sent you an email to {forgotForm.email.value} with a link to
						reset your password.
					</p>
					<Button
						displayLabel="Resend email"
						color="white"
						size="full"
						type="submit"
						onClick={forgotSubmit}
					/>
				</>
			)}
			<a className="ForgotPassword__link" href="/login">
				Login
			</a>
			<p className="ForgotPassword__text">
				If you encounter any issues, please contact your manager.
			</p>
			{loading && <Loader />}
		</LoginLayout>
	);
}

export default ForgotPassword;
