import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
function getSavedValue(key, initialValue) {
	if (typeof window !== 'undefined') {
		let cookie = Cookies.get(key);
		if (cookie) {
			localStorage.setItem(key, cookie);
			return cookie;
		}
	}
	if (initialValue instanceof Function) return initialValue();
	return initialValue;
}
export default function useStorage(key, initialValue) {
	const [cookieLoaded, setCookieLoaded] = useState(false);
	const [value, setValue] = useState(() => {
		return getSavedValue(key, initialValue);
	});

	useEffect(() => {
		setCookieLoaded(false);
		let cookie = Cookies.get(key);
		if (cookie) {
			localStorage.setItem(key, cookie);
			setValue(cookie);
		}
		setCookieLoaded(true);
	}, [value, key]);
	return [value, setValue, cookieLoaded];
}
