import React, { useState } from 'react';

import './radio.scss';

function InputRadio({ id, name, checked, onChange, ...rest }) {
	const [selected, setSelected] = useState(null);

	const handleClickRadioButton = () => {
		onChange();
		setSelected(!selected);
	};

	const handleClick = () => {
		handleClickRadioButton();
	};

	return (
		<div className="InputRadio" {...rest}>
			<div className="InputRadio__input">
				<input
					type="radio"
					id={id}
					name={name}
					checked={checked === id ? true : false}
					onChange={handleClickRadioButton}
				/>
				<div className="InputRadio__btn" onClick={handleClick}>
					<span></span>
				</div>
			</div>
		</div>
	);
}

export default InputRadio;
