import React from 'react';
import './formDisplayGroup.scss';
import { toast } from 'react-toastify';

function FormDisplayGroup({ fieldLabel, fieldValue, icon, toCopy, classValue }) {
	const toastOptions = {
		position: 'bottom-center',
		autoClose: 7000,
		hideProgressBar: true,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: 'dark',
	};

	const handleCopy = (value) => {
		navigator.clipboard.writeText(value);
		toast.success(
			`The link has been copied to your clipboard.`,
			toastOptions,
		);
	};
	
	return (
		<div className="FormDisplayGroup">
			<label className="FormDisplayGroup__label">{fieldLabel}</label>
			{toCopy ? (
				<span
					className="FormDisplayGroup__link"
					onClick={() => handleCopy(fieldValue)}>
					{fieldValue ? fieldValue : '--'}
				</span>
			) : (
				<span className={`FormDisplayGroup__value ${classValue ? classValue : ""}`}>
					{fieldValue ? fieldValue : '--'}
					{icon && icon}
				</span>
			)}
		</div>
	);
}

export default FormDisplayGroup;
