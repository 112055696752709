// /dashboard/form/all-selects
import { axiosRequest } from '../../axiosRequest';

const adapterSelectOptionsResponse = ({ data, success }) => {
	if (success === false) return { error: true, data };
	const { selects } = data;
	const { tiers, grms, payment_statuses, venues, states, member_types } = selects;

	let selectOptions = {};
	if (tiers) selectOptions.tiers = generateOptionsSelects('tiers', tiers);
	if (grms)
		selectOptions.grms = generateOptionsSelects(
			'grms',
			grms,
		);
	if (payment_statuses)
		selectOptions.paymentStatuses = generateOptionsSelects(
			'payment_status',
			payment_statuses,
		);
	if (venues) selectOptions.venues = generateOptionsSelects('venues', venues);
	if (states) selectOptions.states = generateOptionsSelects('states', states);
	if (member_types)
		selectOptions.memberTypes = generateOptionsSelects(
			'member_types',
			member_types,
		);

	return selectOptions;
};

const generateOptionsSelects = (field, options) => {
	switch (field) {
		case 'states':
			return options.map((data) => ({
				value: data.id,
				label: data.state,
			}));
		case 'tiers':
			return options.map((data) => ({
				value: data.id,
				label: data.name.replace('Member', ''),
			}));
		case 'venues':
			return options.map((data) => ({
				value: data.id,
				label: data.name,
			}));
		case 'grms':
			return options.map((data) => ({
				value: data.id,
				label: data.name,
			}));
		case 'payment_status':
			return options.map((data) => ({
				value: data.id,
				label: data.description,
			}));
		case 'member_types':
			return options.map((data) => ({
				value: data.id,				
				label: data.description,
			}));
		default:
			break;
	}
};

export const getSelectOptions = async () => {
	var options = {
		method: 'get',
		url: 'dashboard/form/all-selects',
		query: {
			values: 'tiers|states|paymentStatuses|venues|memberTypes|grms',
		},
	};

	const response = await axiosRequest(options);
	const selectsOptions = adapterSelectOptionsResponse(response);

	return selectsOptions;
};
