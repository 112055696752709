import React, { useState } from 'react';

import './sectionRadioButton.scss';
import InputRadio from '../../../inputs/Radio';

function SectionRadioButton({ displayLabel, onChange, value, options }) {
	const [isCheckedOption, setIsCheckedOption] = useState(value);

	const handleChange = (id) => {
		setIsCheckedOption(id);
		onChange(id);
	};

	return (
		<div className="SectionRadioButton">
			{displayLabel && (
				<label className="SectionRadioButton__label">{displayLabel}</label>
			)}

			{options &&
				options.map((e) => (
					<div className="SectionRadioButton__superadmin" key={e.id}>
						<InputRadio
							id={e.id}
							name={e.name}
							checked={isCheckedOption}
							onChange={() => handleChange(e.id)}
						/>
						<div className="SectionRadioButton__superadmin__text">
							<label>{e.title}</label>
							<span>{e.description}</span>
						</div>
					</div>
				))}
		</div>
	);
}

export default SectionRadioButton;
