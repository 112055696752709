import { axiosRequest } from '../../axiosRequest';

const adapterForgotPasswordResponse = ({ success, data }) => {
	if (success === false) return { error: true, data };

	return data;
};

// url dashboard/forgot-password (post)
export const forgotPassword = async (forgotPasswordForm) => {
	const options = {
		method: 'post',
		url: '/dashboard/forgot-password',
		information: forgotPasswordForm,
	};

	const response = await axiosRequest(options);

	const forgotPasswordResponse = adapterForgotPasswordResponse(response);

	return forgotPasswordResponse;
};
