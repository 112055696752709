// Special characters export (accents):
// https://stackoverflow.com/questions/19492846/javascript-to-csv-export-encoding-issue
export const makeCsvData = (columns, data) => {
    return data.reduce((csvString, rowItem) => {
        return (
            csvString +
            columns
                .map(({ accessor }) => (accessor(rowItem)))
                .join(",") +
            "\r\n"
        );
    }, columns.map(({ name }) => (name)).join(",") + "\r\n");
};
export const downloadAsCsv = async(columns, data, filename) => {
    const csvData = makeCsvData(columns, data);
    const downloadLink = document.createElement("a");
    downloadLink.display = "none";
    downloadLink.download = filename;
    downloadLink.href = "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csvData);
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
};

