import { AsYouType, parsePhoneNumberFromString, isPossiblePhoneNumber, isValidPhoneNumber, validatePhoneNumberLength } from 'libphonenumber-js';

export function formattedPhoneNumber(value) {
    const inputValuePhoneNumber = value;
    const asYouTypeFormatter = new AsYouType('US');
    const formattedPhoneNumber = asYouTypeFormatter.input(inputValuePhoneNumber);

    return formattedPhoneNumber;
}

export function deformatPhoneNumber(formattedPhoneNumber) {
    const phoneNumberInfo = parsePhoneNumberFromString(formattedPhoneNumber, 'US');

    const deformattedPhoneNumber = phoneNumberInfo
        ? `+${phoneNumberInfo.countryCallingCode}${phoneNumberInfo.nationalNumber}`
        : '';

    return deformattedPhoneNumber;
}

export function validatePhoneNumberUSA(value) {
    const possiblePhoneNumber = isPossiblePhoneNumber(value, 'US');
    const validatePhoneNumber = isValidPhoneNumber(value, 'US');
    const validatePhoneNumberLg = validatePhoneNumberLength(value, 'US');

    if (possiblePhoneNumber & validatePhoneNumber & validatePhoneNumberLg === undefined) {
        return true
    } else {
        return false
    }
}