import React, { useContext } from 'react';
import ReactModal from 'react-modal';
import { AppContext } from '../../../context/AppContext';
import NobleIcon from '../../nobleIcon';
import './nobelDashboardModal.scss';

function NobelDashboardModal({
	isOpen,
	onRequestClose,
	contentLabel,
	className = '',
	children,
}) {
	const { rootEl } = useContext(AppContext);

	return (
		<ReactModal
			appElement={rootEl}
			isOpen={isOpen}
			onRequestClose={onRequestClose}
			contentLabel={contentLabel}
			className={className}
			style={{
				overlay: {
					backgroundColor: 'transparent',
				},
				content: {
					right: 0,
					left: 'unset',
					top: 0,
					height: '100%',
					width: '100%',
					maxWidth: '840px',
					background: 'var(--secondaryBlack)',
					border: 'none',
					borderLeft: '1px solid var(--darkGrey)',
					padding: 0,
					'@media(minWidth: 1180px)': {
						width: '80%',
					},
				},
			}}>
			<NobleIcon
				iconWrapperClass="ReactModal__close"
				onClick={onRequestClose}
				name="close"
			/>
			{children}
		</ReactModal>
	);
}

export default NobelDashboardModal;
