import axios from 'axios';
import Cookies from 'js-cookie';
const client = (token) => {
	const defaultOptions = {
		baseURL: process.env.REACT_APP_API_URL,
		headers: {
			Authorization: token
				? `Token ${token}`
				: '',
		},
	};
	return {
		get: (url, options = {}) =>
			axios.get(url, { ...defaultOptions, ...options }),
		post: (url, data, options = {}) =>
			axios.post(url, data, { ...defaultOptions, ...options }),
		put: (url, data, options = {}) =>
			axios.put(url, data, { ...defaultOptions, ...options }),
		delete: (url, options = {}) =>
			axios.delete(url, { ...defaultOptions, ...options }),
	};
};
export default client(Cookies.get('CookieAuth'));
