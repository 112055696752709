import FormDisplayImage from 'components/formDisplayImage';

import './FromDisplayImageInvestor.scss';
import { useState } from 'react';
import { ALLOWED_FILE_SIZE, ALLOWED_FILE_SIZE_MESSAGE, ALLOWED_FILE_TYPES, ALLOWED_FILE_TYPES_MESSAGE } from '../../addInvestors.constant';

const FormDisplayImageInvestor = ({ url, disabled, isInvestor = false }) => {

   const [error, setError] = useState('');
   const [previewUrl, setPreviewUrl] = useState('');

   const validateFile = (file) => {
      if (!file) return 'Please select a file';
      if (!ALLOWED_FILE_TYPES.includes(file.type)) {
         return ALLOWED_FILE_TYPES_MESSAGE
      }
      if (file.size > ALLOWED_FILE_SIZE) {
         return ALLOWED_FILE_SIZE_MESSAGE
      }
      return '';
   };

   const handleFileChange = (e) => {
      const file = e.target.files[0];
      const validationError = validateFile(file);

      if (validationError.length > 0) {
         setError(validationError);
         return;
      }

      setError('');

      const objectUrl = URL.createObjectURL(file);
      setPreviewUrl(objectUrl);

      return () => URL.revokeObjectURL(objectUrl);
   };

   const handleBrowseClick = () => {
      document.getElementById('fileInput').click();
   }

   return (
      <div className="FormDisplayImageInvestor">
         <div className="FormDisplayImageInvestor__title">
            <span>Profile Photo</span>
         </div>
         <FormDisplayImage
            url={url}
            previewUrl={previewUrl}
            disabled={disabled || false}
            isInvestor={isInvestor}
         />
         <input
            id="fileInput"
            type="file"
            accept={ALLOWED_FILE_TYPES.join(', ')}
            onChange={handleFileChange}
            style={{ display: 'none' }}
         />
         <div className="FormDisplayImageInvestor__browse_photos">
            <span onClick={handleBrowseClick}>Browse Photos</span>
         </div>
         <div className="FormDisplayImageInvestor__legend">
            <legend>
               <span>Photos are required for members to access their perks.</span>
            </legend>
         </div>
      </div>
   )
}

export default FormDisplayImageInvestor;
