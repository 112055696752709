import InputField from 'components/inputField';
import cn from 'classnames';
import React, { useContext, useEffect, useState } from 'react'
import NobelSelect from '../../../nobleSelect';
import Button from '../../../button';

import { emailValidation, hasNoNumbersAndSpecialCharacters, hasNoSpecialCharacters, isNotEmpty, isValidNumber, isValidPhoneNumber, isValidStateCode, isValidStateCodeAndZipCode, isValidZipCode, requiredValidation, stringMaxLength } from '../../../../services/validations';

import './addInvestors.scss';
import FormDisplayImageInvestor from './components/FormDisplayImageInvestor/FormDisplayImageInvestor';
import { AppContext, AuthContext } from 'context';
import { getSelectOptions } from 'services/api/form';
import { deformatPhoneNumber } from 'helpers/formattedPhoneNumber';
import { AsYouType, isValidNumber as isValidNumberLibJs } from 'libphonenumber-js';
import { userHasPermission } from 'helpers/hasPermission';
import { PERMISSIONS } from 'helpers/constants';
import { toast } from 'react-toastify';
import { addInvestor } from 'services/api/investors/addInvestor';
import Loader from 'components/loader';
import { isBoolean } from 'lodash';

const toastOptions = {
   position: 'bottom-center',
   autoClose: 7000,
   hideProgressBar: true,
   closeOnClick: true,
   pauseOnHover: true,
   draggable: true,
   progress: undefined,
   theme: 'dark',
};

const AddInvestors = ({ onRequestClose, ...rest }) => {

   const { selects, setRefetchMembers } = useContext(AppContext);
   const { user } = useContext(AuthContext);


   const [selectedIsInvestor, setSelectedIsInvestor] = useState(true);
   const [optionsTierType, setOptionsTierType] = useState(null);
   const [optionsState, setOptionsState] = useState(null);
   const [optionSelectedTier, setOptionSelectedTier] = useState(null);
   const [optionSelectedState, setOptionSelectedState] = useState(null);
   const [formErrors, setFormErrors] = useState([]);
   const [isFormValid, setIsFormValid] = useState(false);
   const [loading, setLoading] = useState(false);
   const [addInvestorForm, setAddInvestorForm] = useState({
      firstName: {
         value: '',
         valid: undefined,
         change: false,
         required: true,
      },
      lastName: {
         value: '',
         valid: undefined,
         change: false,
         required: true,
      },
      email: {
         value: '',
         valid: undefined,
         change: false,
         required: true,
      },
      phoneNumber: {
         value: '',
         valid: undefined,
         change: false,
         required: true,
      },
      tierTypeId: {
         value: 0,
         valid: undefined,
         change: false,
         required: true
      },
      state: {
         value: '',
         valid: undefined,
         change: false,
         required: true
      },
      zipCode: {
         value: '',
         valid: undefined,
         change: false,
         required: true,
      },
      addressLine1: {
         value: '',
         valid: undefined,
         change: false,
         required: true,
      },
      city: {
         value: '',
         valid: undefined,
         change: false,
         required: true,
      },
      isInvestor: {
         value: true,
         valid: true,
         change: false,
         required: true,
      },
   });

   useEffect(() => {
      if (selects) {
         !optionsTierType && setOptionsTierType(selects.tiers);
         !optionsState && setOptionsState(selects.states);

      } else {
         (async () => {
            const selectsResponse = await getSelectOptions();
            if (!selectsResponse.error) {
               setOptionsTierType(selectsResponse.tiers);
               setOptionsState(selectsResponse.states);
            } else {
               console.error('Error fetching selects data:', selectsResponse.data);
            }
         })();
      }
   }, [selects, optionsTierType, optionsState]);

   // VALIDATIONS
   const validateField = (fieldName, value) => {
      switch (fieldName) {
         case 'email':
            return (
               isNotEmpty(value) &&
               requiredValidation(value) &&
               emailValidation(value) &&
               stringMaxLength(value, 80)
            );
         case 'firstName':
            return (
               isNotEmpty(value) &&
               requiredValidation(value) &&
               hasNoNumbersAndSpecialCharacters(value) &&
               stringMaxLength(value, 100)
            );
         case 'lastName':
            return (
               isNotEmpty(value) &&
               requiredValidation(value) &&
               hasNoNumbersAndSpecialCharacters(value) &&
               stringMaxLength(value, 100)
            );
         case 'phoneNumber':
            return (
               isNotEmpty(value) &&
               isValidPhoneNumber(deformatPhoneNumber(value))
            )
         case 'tierTypeId':
            return isValidNumber(value);
         case 'state':
            return isValidStateCodeAndZipCode(value, addInvestorForm.zipCode.value);
         case 'zipCode':
            return (
               isNotEmpty(value) &&
               isValidStateCodeAndZipCode(addInvestorForm.state.value, value)
            );
         case 'addressLine1':
            return (
               isNotEmpty(value) &&
               requiredValidation(value) &&
               hasNoSpecialCharacters(value) &&
               stringMaxLength(value, 200)
            );
         case 'city':
            return (
               isNotEmpty(value) &&
               requiredValidation(value) &&
               hasNoSpecialCharacters(value) &&
               stringMaxLength(value, 80)
            );
         case 'isInvestor':
            return (
               requiredValidation(value) &&
               isBoolean(value)
            );
         default:
            return undefined;
      }
   };

   const newAddInvestorSetValues = (e) => {
      const fieldName = e.target.name;
      let fieldValue = e.target.value;

      const regularFields = {
         firstName: 'firstName',
         lastName: 'lastName',
         phoneNumber: 'phoneNumber',
         email: 'email',
         tierTypeId: 'tierTypeId',
         state: 'state',
         addressLine1: 'addressLine1',
         city: 'city',
      };

      // Formato especial para phoneNumber
      if (fieldName === 'phoneNumber') {
         if (fieldValue !== '') {
            const parsedNumber = new AsYouType('US').input(fieldValue);
            if (isValidNumberLibJs(parsedNumber, 'US')) {
               fieldValue = parsedNumber;
            }
         }
      }

      if (fieldName === 'zipCode') {
         const stateValue = addInvestorForm.state.value;
         const isValidZipState = validateStateAndZipCode(stateValue, fieldValue);

         setAddInvestorForm((prev) => {
            const updateForm = {
               ...prev,
               zipCode: {
                  value: fieldValue,
                  valid: isValidZipState,
                  change: true,
               },
               state: {
                  ...prev.state,
                  valid: stateValue ? isValidZipState : prev.state.valid,
               },
            };

            activateForm(updateForm);

            return updateForm;
         });
         return;
      }

      if (regularFields[fieldName]) {
         const isValidField =
            requiredValidation(fieldValue) && validateField(fieldName, fieldValue);

         setAddInvestorForm((prev) => {
            const updateForm = {
               ...prev,
               [fieldName]: {
                  value: fieldValue,
                  valid: isValidField,
                  change: true,
               },
            };

            activateForm(updateForm);

            return updateForm;
         });
      }
   };

   const setSelectedTierOptions = (e) => {
      const fieldValue = e.value;
      setOptionSelectedTier(fieldValue);
      setAddInvestorForm((prev) => {
         const updateForm = {
            ...prev,
            tierTypeId: {
               value: fieldValue,
               valid: requiredValidation(fieldValue) &&
                  validateField('tierTypeId', fieldValue),
               change: true,
            },
         };

         activateForm(updateForm);

         return updateForm;
      });
   }

   const setSelectedStateOptions = (e) => {
      const fieldValue = e.value;
      setOptionSelectedState(fieldValue);
      setAddInvestorForm((prev) => {
         const isValid = validateStateAndZipCode(fieldValue, prev.zipCode.value);
         const updateForm = {
            ...prev,
            state: {
               ...prev.state,
               value: fieldValue,
               valid: isValid,
               change: true,
            },
            zipCode: {
               ...prev.zipCode,
               valid: isValid,
            },
         };

         activateForm(updateForm);

         return updateForm;
      });
   }

   const validateStateAndZipCode = (stateValue, zipCodeValue) => {
      if (stateValue && zipCodeValue) {
         return isValidStateCodeAndZipCode(stateValue, zipCodeValue);
      }
      if (stateValue) {
         return isValidStateCode(stateValue);
      }
      if (zipCodeValue) {
         return isValidZipCode(zipCodeValue);
      }
      return false;
   };

   const addError = (field, message) => {
      setFormErrors([...formErrors, { field, message }]);
   }

   const validateForm = () => {
      const errors = formErrors;


      if (errors.length > 0) return;

      if (!addInvestorForm.firstName.valid) addError('name', 'Name is required');
      if (!addInvestorForm.phoneNumber.valid) addError('phone', 'Phone is required');
      if (!addInvestorForm.lastName.valid) addError('address', 'Address is required');
      if (!addInvestorForm.email.valid) addError('address', 'Email is required');
      if (!addInvestorForm.tierTypeId.valid) addError('tierTypeId', 'Tier Type is required');
      if (!addInvestorForm.state.valid) addError('state', 'State is required');
      if (!addInvestorForm.zipCode.valid) addError('zipCode', 'Zip Code is required');
      if (!addInvestorForm.addressLine1.valid) addError('addressLine1', 'Address Line 1 is required');
      if (!addInvestorForm.city.valid) addError('city', 'City is required');

      if (errors.length > 0) {
         return setIsFormValid(false)
      };
   }

   const addInvestorSubmit = async (e) => {
      e.preventDefault();
      validateForm();
      if (isFormValid) {
         if (!userHasPermission(user, PERMISSIONS.CAN_EDIT_MEMBERS)) {
            return toast.error(
               'You do not have permission to add investors',
               toastOptions
            )
         }
         setLoading(true);
         const addInvestorResponse = await addInvestor(addInvestorForm);
         if (!addInvestorResponse.error) {
            setRefetchMembers(true);
            notifyAdmin('success');
            onRequestClose();
         } else {
            notifyAdmin('error-data', addInvestorResponse.data);
         }
         setLoading(false);
      } else {
         notifyAdmin('error');
         setLoading(false);
      }
   }

   const activateForm = (formState = addInvestorForm) => {
      const requiredFieldNames = ['firstName', 'lastName','email', 'phoneNumber', 'addressLine1', 'city', 'zipCode', 'tierTypeId', 'isInvestor'];
      const formFieldsArray = Object.values(formState);

      const requiredFields = requiredFieldNames.map((fieldName) => formState[fieldName]);

      const isAnyFieldInvalid = formFieldsArray.some((field) => {
         if (field.name === 'isInvestor') {
            return false;
         }
         return (
            (field.required && (!field.valid || field.value === '')) ||
            (!field.required && field.value !== '' && !field.valid)
         );
      });

      const changedFields = formFieldsArray.filter((item) => item.change === true);

      const hasEnoughChanges = changedFields.length >= requiredFields.length;

      const allRequiredValid = requiredFields.every(
         (field) => field && field.valid === true && field.value !== ''
      );

      const isFormValid =
         !isAnyFieldInvalid &&
         hasEnoughChanges &&
         allRequiredValid;

      setIsFormValid(isFormValid);
   };


   const notifyAdmin = (notificationType, data) => {
      switch (notificationType) {
         case 'success':
            toast.success(
               `${addInvestorForm.firstName.value} ${addInvestorForm.lastName.value} was correctly added.`,
               toastOptions,
            );
            break;
         case 'error':
            toast.error(
               `${formErrors.length ? formErrors[0] : ''
               } Could not add investor user. Please correct the error and try again.`,
               toastOptions,
            );
            break;
         case 'error-data':
            toast.error(data, toastOptions);
            break;
         default:
            break;
      }
   }

   const handleSetSelectedIsInvestor = (value) => {
      setSelectedIsInvestor(value);

      setAddInvestorForm((prev) => {
         const updateForm = {
            ...prev,
            isInvestor: {
               ...prev.isInvestor,
               value: value,
            },
         };
         return updateForm;
      });
   }

   return (
      <div className="AddInvestors" {...rest}>
         {
            loading && <Loader />
         }
         {
            !loading && (
               <form className="AddInvestors__form">
                  <div className="AddInvestors__form__section separator">
                     <FormDisplayImageInvestor url={''} disabled={false} isInvestor={true} />
                     <div className="AddInvestors__form__section two-col referral-section">
                        <div className="FormDisplayGroup FormDisplayGroupReferral">
                           <label className="FormDisplayGroup__label">Member Type*</label>
                           <div className="isReferral">
                              <span
                                 onClick={() => handleSetSelectedIsInvestor(true)}
                                 className={cn([
                                    'isReferral__option',
                                    { active: selectedIsInvestor },
                                 ])}>
                                 Investors
                              </span>
                              <span
                                 onClick={() => handleSetSelectedIsInvestor(false)}
                                 className={cn([
                                    'isReferral__option',
                                    { active: !selectedIsInvestor },
                                 ])}>
                                 Non-paying
                              </span>
                           </div>
                        </div>
                     </div>
                     <div className="section-dual">
                        <InputField
                           id="firstName"
                           name="firstName"
                           displayLabel="First Name*"
                           onChange={newAddInvestorSetValues}
                           onBlur={newAddInvestorSetValues}
                           value={addInvestorForm.firstName?.value || ''}
                           inputError={
                              addInvestorForm.firstName?.valid === false ? true : false
                           }
                        />
                        <InputField
                           id="lastName"
                           name="lastName"
                           displayLabel="Last Name*"
                           onChange={newAddInvestorSetValues}
                           onBlur={newAddInvestorSetValues}
                           value={addInvestorForm.lastName?.value || ''}
                           inputError={
                              addInvestorForm.lastName?.valid === false ? true : false
                           }
                        />
                     </div>

                     <div className="section-dual">
                        <InputField
                           id="email"
                           name="email"
                           displayLabel="Email*"
                           onChange={newAddInvestorSetValues}
                           onBlur={newAddInvestorSetValues}
                           value={addInvestorForm.email?.value || ''}
                           inputError={
                              addInvestorForm.email?.valid === false ? true : false
                           }
                        />
                        <InputField
                           id="phoneNumber"
                           name="phoneNumber"
                           displayLabel="Phone Number*"
                           onChange={newAddInvestorSetValues}
                           onBlur={newAddInvestorSetValues}
                           value={addInvestorForm.phoneNumber?.value || ''}
                           inputError={
                              addInvestorForm.phoneNumber?.valid === false ? true : false
                           }
                        />
                     </div>

                     <div className="section-dual">
                        <InputField
                           id="addressLine1"
                           name="addressLine1"
                           displayLabel="Address Line 1*"
                           onChange={newAddInvestorSetValues}
                           onBlur={newAddInvestorSetValues}
                           value={addInvestorForm.addressLine1?.value || ''}
                           inputError={
                              addInvestorForm.addressLine1?.valid === false ? true : false
                           }
                        />
                        <InputField
                           id="city"
                           name="city"
                           displayLabel="City*"
                           onChange={newAddInvestorSetValues}
                           onBlur={newAddInvestorSetValues}
                           value={addInvestorForm.city?.value || ''}
                           inputError={
                              addInvestorForm.city?.valid === false ? true : false
                           }
                        />
                     </div>

                     <div className="section-dual">
                        <div className="inputField_containerSelect">
                           <label className="inputField__label__state">State*</label>
                           <NobelSelect
                              placeholder={
                                 optionsState?.find(
                                    (state) => state.value === optionSelectedState,
                                 )?.label
                              }
                              styles={{
                                 control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    width: '100%',
                                    borderColor: state.isFocused ? '#dabc75' : '#454A4B',
                                    backgroundColor: '#2B3033',
                                    color: '#F7F7F7',
                                 }),
                                 singleValue: (baseStyles) => ({
                                    ...baseStyles,
                                    color: '#cccccc',
                                 }),
                                 placeholder: (baseStyles) => ({
                                    ...baseStyles,
                                    color: "#ffffff",
                                 }),
                              }}
                              options={optionsState}
                              onChange={(e) => setSelectedStateOptions(e)}
                           />
                        </div>
                        <InputField
                           id="zipCode"
                           name="zipCode"
                           displayLabel="Zip Code*"
                           onChange={newAddInvestorSetValues}
                           onBlur={newAddInvestorSetValues}
                           value={addInvestorForm.zipCode?.value || ''}
                           inputError={
                              addInvestorForm.zipCode?.valid === false ? true : false
                           }
                        />
                     </div>
                  </div>

                  <div className="AddInvestors__form__section separator">
                     <label className="inputField__label__tierType">Tier Type*</label>
                     <NobelSelect
                        placeholder={
                           optionsTierType?.find(
                              (tier) => tier.value === optionSelectedTier,
                           )?.label
                        }
                        styles={{
                           control: (baseStyles, state) => ({
                              ...baseStyles,
                              width: '100%',
                              borderColor: state.isFocused ? '#dabc75' : '#454A4B',
                              backgroundColor: '#2B3033',
                              color: '#F7F7F7',
                           }),
                           singleValue: (baseStyles) => ({
                              ...baseStyles,
                              color: '#cccccc',
                           }),
                           placeholder: (baseStyles) => ({
                              ...baseStyles,
                              color: "#ffffff",
                           }),
                        }}
                        options={optionsTierType}
                        onChange={(e) => setSelectedTierOptions(e)}
                     />
                  </div>
                  <div className="AddInvestors__form__section buttons_section">
                     <Button
                        buttonWrapperClass="cancel"
                        displayLabel="Cancel"
                        color="black"
                        size="full"
                        onClick={onRequestClose}
                     />
                     <Button
                        buttonWrapperClass="save"
                        displayLabel="Save"
                        color="white"
                        size="full"
                        onClick={(e) => addInvestorSubmit(e)}
                        disabled={!isFormValid}
                     />
                  </div>
               </form>
            )
         }
      </div>
   );
}

export default AddInvestors;
