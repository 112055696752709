import { axiosRequest } from '../../axiosRequest';

import moment from 'moment';

const adapterAppResponse = ({ data, success }) => {
	if (success === false) return { error: true, data };

	const applications = data?.applications?.map((i) => {
		let application = {
			id: i?.id,
			fullname: `${i?.first_name} ${i.last_name}`,
			tier_type: i?.tier?.type,
			tier_type_id: i?.tier?.id,
			referred_by: i?.referred_by,
			status: i?.status,
			application_date: i?.application_date,
			update_status_date: moment(i?.status_updated_at).format('MM/DD/YYYY'),
		};
		return application;
	});

	return applications;
};

export const getApplicationsList = async (value) => {
	var options = {
		method: 'get',
		url: 'dashboard/applications',
		query: {
			status: value,
		},
	};

	if (!value) delete options.query;

	const response = await axiosRequest(options);
	const applications = adapterAppResponse(response);

	return applications;
};
