// url dashboard/admins

import moment from 'moment';
import { axiosRequest } from '../../axiosRequest';

const adapterAdminsListResponse = ({ data, success }) => {
	if (success === false) return { error: true, data };
	const adminList = data.admin_list.map((a) => {
		let admin = {
			...a,
			birth_date: a?.birth_date &&
				moment(a?.birth_date, 'YYYY-MM-DD').format('MM/DD/YYYY'),
		};
		return admin;
	});
	const admins = {
		total_admin: data.total_admin,
		admin_list: adminList,
	};

	return admins;
};

export const getAdminsList = async () => {
	let options = {
		method: 'get',
		url: 'dashboard/admins',
	};

	const response = await axiosRequest(options);

	const admins = adapterAdminsListResponse(response);

	return admins;
};
