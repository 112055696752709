import React from 'react';
import NobleIcon from '../../nobleIcon';
import InputField from '../../inputField';

import './search.scss';

function InputSearch({ ...rest }) {
	return (
		<div className="InputSearch">
			<div className="InputSearch__icon">
				<NobleIcon name="search" />
			</div>
			<InputField {...rest} autoFocus />
		</div>
	);
}

export default InputSearch;
