import { useState, useEffect, useContext } from 'react';
import {
	requiredValidation,
	passwordValidation,
	emailValidation,
} from '../../services/validations';
import { login } from '../../services/api/user/login';
import { AuthContext } from '../../context/AuthContext';
import { AppContext } from '../../context';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/button';
import InputField from '../../components/inputField';
import LoginLayout from '../../components/loginLayout';
import Loader from '../../components/loader';

import './login.scss';
import { getUser } from '../../services/api/user/getUser';

export default function Login() {
	const [isFormValid, setIsFormValid] = useState(false);
	const [submitError, setSubmitError] = useState(false);
	const [loginForm, setLoginForm] = useState({
		email: { value: undefined, valid: undefined },
		password: { value: undefined, valid: undefined },
	});
	const { accessToken, setAccessToken, user, setUser } =
		useContext(AuthContext);
	const { setLoading, loading } = useContext(AppContext);
	const navigate = useNavigate();

	useEffect(() => {
		if (accessToken && !user) {
			async function getUserData() {
				setLoading(true);
				const user = await getUser();
				if (!user.error) {
					setUser(user.information);
				} else {
					console.log(user.data);
				}
				setLoading(false);
			}
			getUserData();
		}
		if (accessToken && user) {
			// Force a refresh
			window.location.href = `/applications`;
		}
	}, [accessToken, user, setUser, setLoading, navigate]);

	useEffect(() => {
		if (loginForm.email.valid && loginForm.password.valid) {
			setIsFormValid(true);
		} else {
			setIsFormValid(false);
		}
	}, [loginForm]);

	const loginSetValues = (e) => {
		const fieldName = e.target.name;
		const fieldValue = e.target.value;
		if (fieldName === 'email') {
			setLoginForm((prevState) => ({
				...prevState,
				[fieldName]: {
					value: fieldValue,
					valid: requiredValidation(fieldValue) && emailValidation(fieldValue),
				},
			}));
		}
		if (fieldName === 'password') {
			setLoginForm((prevState) => ({
				...prevState,
				[fieldName]: {
					value: fieldValue,
					valid:
						requiredValidation(fieldValue) && passwordValidation(fieldValue),
				},
			}));
		}
	};

	const loginFieldOnFocus = (e) => {
		const fieldName = e.target.name;
		const fieldValue = e.target.value;
		setLoginForm((prevState) => ({
			...prevState,
			[fieldName]: { valid: prevState.valid, value: fieldValue },
		}));
	};

	const loginSubmit = async (e) => {
		e.preventDefault();
		if (isFormValid) {
			setLoading(true);
			const user = await login(loginForm);
			if (!user.error) {
				setAccessToken(user.token);
				setUser(user.information);
			} else {
				setSubmitError(true);
				console.log(user.data);
			}
			setLoading(false);
		} else {
			console.log('INVALID FORM');
		}
		return;
	};
	return (
		<LoginLayout layoutClass="Login">
			<form id="loginForm" className="Login__form">
				<InputField
					displayLabel="Email"
					id="email"
					name="email"
					type="email"
					onChange={loginSetValues}
					onBlur={loginSetValues}
					onFocus={loginFieldOnFocus}
					required
					autoComplete="off"
				/>
				{loginForm.email.valid === false && (
					<small className="inputError">Please enter a valid email</small>
				)}
				<InputField
					displayLabel="Password"
					id="password"
					name="password"
					type="password"
					onChange={loginSetValues}
					onBlur={loginSetValues}
					onFocus={loginFieldOnFocus}
					required
					autoComplete="off"
					inputError={loginForm.password.valid === false ? true : false}
				/>
				{loginForm.password.valid === false && (
					<small className="inputError">
						Sorry, your password must have at least 8 characters.
					</small>
				)}
				<a className="Login__forgot" href="/forgot-password">
					Forgot Password?
				</a>
				<Button
					displayLabel="Continue"
					color="white"
					size="full"
					type="submit"
					buttonValidation={isFormValid}
					onClick={loginSubmit}
				/>
				{submitError && (
					<small className="inputError">
						Sorry, your password or email was incorrect.
					</small>
				)}
			</form>
			{loading && <Loader />}
		</LoginLayout>
	);
}
