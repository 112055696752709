import React from 'react'

import "./inputEditProfile.scss";
import InputField from '../../../components/inputField';

function InputEditProfile({ displayLabel, info, name, value, id, onChange, enabledEdit = [], setEnabledEdit, isValid, ...rest }) {

    const handleEdition = (e) => {
        e.preventDefault();
        let changeValue = enabledEdit.map(edit => {
            if (edit.id === id) {
                edit.value = true
            }
            return edit;
        });
        if (setEnabledEdit) {
            setEnabledEdit(changeValue);
        }
    }

    return (
        <div className='inputEditProfile' {...rest} >
            <label htmlFor={id}>{displayLabel} </label>
            {
                enabledEdit.filter(f => f.value === true).some(e => e.id === id) ? (
                    <InputField
                        id={id}
                        name={name}
                        type={id}
                        onChange={onChange}
                        onBlur={onChange}
                        autoComplete="off"
                        inputError={isValid && isValid}
                        required={id === "password" ? true : false}
                        value={value}
                    />
                ) : (
                    <div className='inputEditProfile__data'>
                        <span>{info}</span>
                        <button className="inputEditProfile__btn" onClick={handleEdition}>edit</button>
                    </div>
                )
            }
        </div>
    )
}

export default InputEditProfile