import React from 'react';
import NobelPopUpModal from '../nobelPopUpModal';
import Button from '../../button';
import NobleIcon from '../../nobleIcon';
import { cancelMembershipMessage } from 'services/constants';
import './styles.scss';

export default function CancelMembershipModal({
	isOpen,
	onCloseHandler,
	callback,
	clickedItem,
}) {
	if(!clickedItem) return null;
	return (
		<NobelPopUpModal
			isOpen={isOpen}
			onRequestClose={onCloseHandler}
			className="CancelMembershipModal">
			<div className="CancelMembershipModal__header">
				<h3>
					Cancel Membership {' ?'}
				</h3>
			</div>
			<div className="CancelMembershipModal__content">
				<p>{cancelMembershipMessage}</p>
			</div>
			<div className="CancelMembershipModal__buttons">
				<Button
					onClick={() => callback(clickedItem?.id ? clickedItem.id : clickedItem.app_id)}
					displayLabel={`Yes, Cancel`}
					buttonWrapperClass="CancelMembershipModal__buttons__button CancelMembershipModal__buttons__button--delete"
					size="full">
					<NobleIcon name="delete" />
				</Button>
				<Button
					displayLabel={`No, Save Membership`}
					buttonWrapperClass="CancelMembershipModal__buttons__button"
					color="white"
					size="full"
					onClick={onCloseHandler}
				/>
			</div>
		</NobelPopUpModal>
	);
}