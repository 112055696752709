import { useEffect, useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import config from '../../../config';
import './styles.scss';
const tierIcon = `${config.CDN_MEDIA}nobleHatVector.svg`;

function SubscriptionType({ hash, tier, hasSpouse, handleSubscriptionChange }) {
	const [userTypeSelection, setUserTypeSelection] = useState('monthly');

	// const uniqueId = useMemo(() => hash, [hash]);

	const toggleSwitch = useCallback(
		() => setUserTypeSelection(() => {	
			if (tier?.prices['annually'] && tier?.prices['monthly']) {
				return 'monthly'
			} else if (tier?.prices['annually'] && !tier.prices['monthly']) {
				return 'annually'
			}
			return 'monthly'
		}),
		[setUserTypeSelection, tier],
	);

	useEffect(() => {
		toggleSwitch();
		handleSubscriptionChange && handleSubscriptionChange(tier?.prices[userTypeSelection]?.id ?? '')
	}, [tier.prices, handleSubscriptionChange, userTypeSelection, toggleSwitch]);
	
	const { type, prices } = tier;
	const tierClassName = type.replace(/\s+/g, '-').toLowerCase();

	return (
		<div
			className={classNames(
				'SubscriptionType',
				`SubscriptionType--${userTypeSelection}`,
			)}>
			<div className={classNames(["SubscriptionType__price", `SubscriptionType__price--${tierClassName}`])}>
				<div className="SubscriptionType__price__type">
					<img src={tierIcon} alt={type} width={24} height={30}/>
					<b className="SubscriptionType__price__tier">
						{type}
					</b>
				</div>
				<div className="SubscriptionType__price__amount">
					<span>{prices?.monthly && `$${prices?.monthly?.price_per_month}/mo`}</span>
					<span>{prices?.annually && `$${prices?.annually?.price}/yr`}</span>		
				</div>
			</div>

			{
				hasSpouse && (<p className="SubscriptionType__additional">+ 300 of spouse charge</p>)
			}

			{/* {tier?.prices?.monthly && tier?.prices?.annually && (
				<>
					<label
						className="SubscriptionType__checkbox-label"
						htmlFor={`SubscriptionType__switch-${uniqueId}`}>
						<span className="SubscriptionType__switch">{userTypeSelection}</span>
						Pay Annually
						<span className="SubscriptionType__tag">Save $300</span>
					</label>

					<input
						className="SubscriptionType__checkbox"
						id={`SubscriptionType__switch-${uniqueId}`}
						name="switch"
						onChange={toggleSwitch}
						type="checkbox"
					/>
				</>
			)} */}

		</div>
	);
}

export default SubscriptionType;
