import { axiosRequest } from '../../axiosRequest';

// url dashboard/members/total
const adapterTotalMembersResponse = ({ data, success }) => {
	if (success === false) return { error: true, data };

	const { total_members } = data;

	return total_members;
};

export const getTotalMemebers = async () => {
	let options = {
		method: 'get',
		url: 'dashboard/members/total',
		tokenFailedReload: true
	};

	const response = await axiosRequest(options);
	const totalMembers = adapterTotalMembersResponse(response);

	return totalMembers;
};
