import React, { useState, useContext } from 'react';
import NobleIcon from '../../nobleIcon';
import ThreeDotsOptionsMenu from '../../threeDotsOptionsMenu';
import { AppContext } from '../../../context/AppContext';
import { AuthContext } from '../../../context/AuthContext';
import { useLocation } from 'react-router-dom';
import cn from 'classnames';
import './dashboardUserProfile.scss';

function DashboardUserProfile({ active }) {
	const [toggleMenu, setToggleMenu] = useState(false);
	const { clickOutsideMenuRef } = useContext(AppContext);
	const { user } = useContext(AuthContext);
	const location = useLocation();

	return (
		<div className="DashboardUserProfile">
			{!active && (
				<a
					href="/user-profile"
					className={cn([
						'DashboardUserProfile__settings',
						{ active: location.pathname === '/user-profile' },
					])}>
					<NobleIcon name="config" />
				</a>
			)}
			{!active && (
				<a href="/logout" className="DashboardUserProfile__settings">
					<NobleIcon name="logOut" />
				</a>
			)}

			{active && (
				<div className="DashboardUserProfile__box">
					<div className="DashboardUserProfile__info">
						<span className="DashboardUserProfile__info__username">
							{user ? user.full_name : 'Username'}
						</span>
						<small className="DashboardUserProfile__info__useremail">
							{user ? user.email : 'useremail@email.com'}
						</small>
					</div>
					<div className="DashboardUserProfile__options">
						<ThreeDotsOptionsMenu
							toggleMenu={toggleMenu}
							setToggleMenu={setToggleMenu}
							outsideClickRef={clickOutsideMenuRef}
							menuContent={
								<>
									<a
										href="/user-profile"
										className="ThreeDotsOptionsMenu__item">
										<NobleIcon name="config" />
										User Profile
									</a>
									<a href="/logout" className="ThreeDotsOptionsMenu__item">
										<NobleIcon name="logOut" />
										Log Out
									</a>
								</>
							}
						/>
					</div>
				</div>
			)}
		</div>
	);
}

export default DashboardUserProfile;
