import React, { useContext, useEffect, useRef, useState } from 'react';
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DashboardLogoPNG from '../../../assets/images/logo_dashboard.png';
import DashboardLogo from '../../../assets/images/logo_dashboard.webp';
import { AppContext } from '../../../context/AppContext';
import ImgWithFallback from '../../img';
import NobleIcon from '../../nobleIcon';
import DashboardNavigation from '../dashboardNavigation';
import DashboardUserProfile from '../dashboardUserProfile';
import './dashboardLayout.scss';

function DashboardLayout({
	children,
	layoutClass = '',
	layoutTitle = '',
	contentTotal = undefined,
	contentBtnExport = undefined,
	contentSearch = undefined,
	approvalsLeft = undefined,
	filterTotal = undefined,
}) {
	const [toggleMenu, setToggleMenu] = useState(false);
	const { screenDimensions } = useContext(AppContext);
	const [active, setActive] = useState(true);
	const [isDesktop, setIsDesktop] = useState(false);

	const clickOutsideMenuRef = useRef();

	useEffect(() => {
		if (screenDimensions >= 1180) {
			setIsDesktop(true);
			setActive(false);
		} else {
			setIsDesktop(false);
			setActive(true);
		}
	}, [screenDimensions]);

	const toggleMenuFunction = () => setToggleMenu(!toggleMenu);
	const activateMenu = () => setActive(!active);

	return (
		<div className={'DashboardLayout ' + layoutClass}>
			{!isDesktop && !toggleMenu && (
				<div className="DashboardLayout__mobileHeader">
					<NobleIcon
						iconWrapperClass="DashboardLayout__mobileMenuIcon"
						name="mobileMenuIcon"
						onClick={toggleMenuFunction}
					/>
					<ImgWithFallback
						wrapperClassName="DashboardLayout__logo"
						img={DashboardLogo}
						fallbackImg={DashboardLogoPNG}
					/>
				</div>
			)}

			{!isDesktop && toggleMenu && active && (
				<NobleIcon
					iconWrapperClass="DashboardLayout__close"
					onClick={toggleMenuFunction}
					name="close"
				/>
			)}

			{!isDesktop && toggleMenu && (
				<div className="DashboardLayout__menu">
					<ImgWithFallback
						wrapperClassName="DashboardLayout__menu__logo"
						img={DashboardLogo}
						fallbackImg={DashboardLogoPNG}
					/>
					<DashboardNavigation active={active} />
					<DashboardUserProfile
						outsideClickRef={clickOutsideMenuRef}
						active={active}
					/>
				</div>
			)}

			{isDesktop && (
				<div
					className="DashboardLayout__menu"
					style={active ? { width: '300px' } : { width: '60px' }}>
					{active ? (
						<div className="DashboardLayout__desktopHeader">
							<ImgWithFallback
								wrapperClassName="DashboardLayout__menu__logo"
								img={DashboardLogo}
								fallbackImg={DashboardLogoPNG}
							/>
							<div
								className="DashboardLayout__menu__icon"
								onClick={activateMenu}>
								<NobleIcon name="leftArrow" />
							</div>
						</div>
					) : (
						<div className="DashboardLayout__menu__icon" onClick={activateMenu}>
							<NobleIcon name="rightArrow" />
						</div>
					)}

					<DashboardNavigation active={active} />
					<DashboardUserProfile
						outsideClickRef={clickOutsideMenuRef}
						active={active}
					/>
				</div>
			)}

			<div className="DashboardLayout__content">
				<div className="DashboardLayout__content__header">
					<h1 className="DashboardLayout__content__header__title">
						{layoutTitle}
					</h1>
					<div className="DashboardLayout__content__header__two">
						{contentTotal && !filterTotal &&(
							<span className="DashboardLayout__content__header__two__total">
								{contentTotal} total {layoutTitle.toLocaleLowerCase()}
							</span>
						)}
						{contentTotal && filterTotal && (
							<span className="DashboardLayout__content__header__two__total">
								{filterTotal}/{contentTotal} total 
							</span>
						)}
						{typeof approvalsLeft === 'number' && (
							<div className="DashboardLayout__content__header__two__division"></div>
						)}
						{typeof approvalsLeft === 'number' && (
							<span className="DashboardLayout__content__header__two__total">
								{approvalsLeft} approvals left
							</span>
						)}
						{contentBtnExport && contentBtnExport}
						{contentSearch && contentSearch}
					</div>
				</div>
				{children}
			</div>
			<ToastContainer transition={Slide} />
		</div>
	);
}

export default DashboardLayout;
