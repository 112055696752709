import React, { useState } from 'react';
import cn from 'classnames';
import './inputField.scss';
import { IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

function InputField({
	displayLabel,
	id,
	inputError = false,
	type = 'text',
	value,
	...rest
}) {
	const [showPassword, setShowPassword] = useState(false);
	const handlePasswordVisibility = () => setShowPassword(!showPassword);
	return (
		<div className="inputField">
			{displayLabel && (
				<label className="inputField__label" htmlFor={id}>
					{displayLabel}
				</label>
			)}
			<input
				id={id}
				className={cn([
					'inputField__input',
					{ 'inputField__input-error': inputError },
				])}
				type={showPassword ? 'text' : type}
				defaultValue={value ? value : ''}
				value={value && value}
				{...rest}
			/>
			{id === 'password' && (
				<IconButton
					className="inputField__togglePasswordVisibility"
					onClick={handlePasswordVisibility}
					aria-label="toggle password visibility">
					{showPassword ? <Visibility /> : <VisibilityOff />}
				</IconButton>
			)}
		</div>
	);
}

export default InputField;
