import React from 'react';
import Select from 'react-select';
import NobleIcon from 'components/nobleIcon';
import { defaultSelectStyles } from './constants';
import './inputSelect.scss';

function nobleSelect({ displayLabel, id, ...rest }) {
	return (
		<div className="inputSelect">
			{displayLabel && (
				<label className="inputSelect__label" htmlFor={id}>
					{displayLabel}
				</label>
			)}
			<Select
				className="NobleSelect"
				placeholder="All Membership tiers"
				styles={defaultSelectStyles}
				components={{
					IndicatorSeparator: () => false,
					DropdownIndicator: () => (
						<NobleIcon name="arrowDown" iconWrapperClass="NobleIcon__select" />
					),
				}}
				theme={(theme) => ({
					...theme,
					borderRadius: 0,
					colors: {
						...theme.colors,
						neutral90: '#939696',
						neutral80: '#939696',
						neutra170: '#939696',
						neutral60: '#939696',
						neutral50: '#939696',
						neutral40: '#454A4B',
						neutral30: '#F7F7F7',
						neutral20: '#939696',
						danger: '#FF8282',
						neutral0: '#2B3033',
						primary: '#F7F7F7',
						primary25: '#939696',
					},
				})}
				{...rest}
			/>
		</div>
	);
}

export default nobleSelect;
