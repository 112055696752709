import React, { useContext } from 'react';
import ReactModal from 'react-modal';
import { AppContext } from '../../../context/AppContext';
import NobleIcon from '../../nobleIcon';
import './nobelPopUpModal.scss';

function NobelPopUpModal({
	isOpen,
	onRequestClose,
	contentLabel,
	className = '',
	children,
}) {
	const { rootEl } = useContext(AppContext);

	return (
		<ReactModal
			appElement={rootEl}
			isOpen={isOpen}
			onRequestClose={onRequestClose}
			contentLabel={contentLabel}
			className={'NobelPopUpModal ' + className}
			style={{
				overlay: {
					backgroundColor: 'rgba(36, 41, 44, 0.75)',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				},
				content: {
					width: '80%',
					maxWidth: '630px',
					backgroundColor: 'var(--secondaryBlack)',
					position: 'relative',
				},
			}}>
			<NobleIcon
				iconWrapperClass="NobelPopUpModal__close"
				onClick={onRequestClose}
				name="close"
			/>
			{children}
		</ReactModal>
	);
}

export default NobelPopUpModal;
