import { axiosRequest } from '../../axiosRequest';

const adapterResponse = ({ data, success }) => {
	if (success === false) return { error: true, data };
	const totalApps = {
		total: data?.applications?.total_applications,
		new: data?.applications?.new_applications,
		waitlist: data?.applications?.waitlist_applications,
		rejected: data?.applications?.rejected_applications,
		approved: data?.applications?.approved_applications,
		approvals_left: data?.applications?.admin_approvals_left,
	};

	return totalApps;
};

export const getTotalApplications = async () => {
	var options = {
		method: 'get',
		url: 'dashboard/applications/total',
		tokenFailedReload: true
	};

	const response = await axiosRequest(options);
	const totalApps = adapterResponse(response);

	return totalApps;
};
