import { useContext } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import AccessDenied from '../../pages/AccessDenied';
import Admins from '../../pages/Admins';
import Applications from '../../pages/Applications';
import CreatePasswordAdmin from '../../pages/CreatePasswordAdmin';
import ForgotPassword from '../../pages/ForgotPassword';
import Home from '../../pages/Home';
import Login from '../../pages/Login';
import Logout from '../../pages/Logout';
import Members from '../../pages/Members';
import PaymentForMembership from '../../pages/PayForMembership';
import ResetPassword from '../../pages/ResetPassword';
import UserProfile from '../../pages/UserProfile';
import VerifyMembership from '../../pages/VerifyMembership';
import {
	AuthContext
} from '../../context';
import useTokenCheck from 'hooks/useTokenCheck';

export const sitemap = createBrowserRouter([
	{
		path: '/',
		element: <Home />,
		errorElement: <AccessDenied />,
	},
	{
		path: '/access-denied',
		element: <AccessDenied />,
		errorElement: <AccessDenied />,
	},
	{
		path: '/login',
		element: <Login />,
		errorElement: <AccessDenied />,
	},
	{
		path: '/forgot-password',
		element: <ForgotPassword />,
		errorElement: <ForgotPassword />,
	},
	{
		path: '/reset-password/:code',
		element: <ResetPassword />,
		errorElement: <ResetPassword />,
	},
	{
		path: '/applications',
		element: <Applications />,
		errorElement: <AccessDenied />,
	},
	{
		path: '/members',
		element: <Members />,
		errorElement: <AccessDenied />,
	},
	{
		path: '/admins',
		element: <Admins />,
		errorElement: <AccessDenied />,
	},
	{
		path: '/user-profile',
		element: <UserProfile />,
		errorElement: <AccessDenied />,
	},
	{
		path: '/logout',
		element: <Logout />,
		errorElement: <AccessDenied />,
	},
	{
		path: '/create-password/:code',
		element: <CreatePasswordAdmin />,
		errorElement: <AccessDenied />,
	},
	{
		path: '/membership/:hash',
		element: <PaymentForMembership />,
		errorElement: <AccessDenied />,
	},
	{
		path: '/member/:hash',
		element: <VerifyMembership />,
		errorElement: <AccessDenied />
	}
]);

export default function Routing(props) {
	const { cookieLoaded } =
		useContext(AuthContext);
	useTokenCheck();
	if (!cookieLoaded) return <></>;
	return <RouterProvider router={sitemap} {...props} />;
}
