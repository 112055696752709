export const stripeOptions = {
	locale: 'en',
	fonts: [{
		src: "url('https://noble33-production.s3.us-east-1.amazonaws.com/assets/fonts/sofia-pro/sofiapro-light.otf')",
		family: 'sofiapro-light'
	}]
};

export const stripeInputStyles = {
	style: {
		base: {
			color: '#cccccc',
			fontFamily: `"sofiapro-light", sans-serif`,
			fontSmoothing: "antialiased",
			fontSize: '16px',
			"::placeholder": {
				color: '#6d7475',
			}
		},
		invalid: {
			iconColor: "#ff7373",
			color: "#ff7373"
		}
	},
};
export const stripeNumbersOptions = {
	...stripeInputStyles,
	placeholder: "•••• •••• •••• ••••"
}
export const stripeExpiryOptions = {
	...stripeInputStyles,
	placeholder: "MM/YY"
}
export const stripeCVCOptions = {
	...stripeInputStyles,
	placeholder: "***"
}