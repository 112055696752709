import { useEffect, useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { getUser } from '../services/api/user/getUser';

export default function useTokenCheck() {
	const { accessToken, user, setUser } = useContext(AuthContext);

	useEffect(() => {
		if (accessToken && !user) {
			async function getUserData() {
				const user = await getUser();
				if (!user.error) {
					setUser(user.information);
				} else {
					window.location = '/logout';
				}
			}
			getUserData();
		}
	}, [accessToken]);
}
