import React, { useCallback, useContext, useEffect, useState } from 'react';

import NobelDashboardModal from '../nobelDashboardModal';
import MemberInfo from './memberInfo';
import { AppContext } from '../../../context';

import './userMemberModal.scss';
import EditMemberInfo from './editMemberInfo';
import { getMemberById } from '../../../services/api/members/getMember';
import { getDiningHistoryByid } from '../../../services/api/members/getDiningHistory';
import Loader from "../../../components/loader";
import EditSpouseInfo from './editSpouseInfo';
import AddInvestors from './addInvestors';

function UserMemberModal(props) {
	const {
		isOpen,
		onRequestClose,
		action,
		clickedItemId,
		setActionItem,
		className,
		setClickedItem,
		isModalOpen
	} = props;

	const { member, setMember } = useContext(AppContext);

	const [loadingClickedItem, setLoadingClickedItem] = useState(false);

	const getMember = useCallback(() => {
		if (clickedItemId && !member) {
			(async () => {
				isOpen && setLoadingClickedItem(true);
				const member = await getMemberById(clickedItemId.id, clickedItemId.is_spouse);
				const dinningHistory = await getDiningHistoryByid(clickedItemId.id, clickedItemId.is_spouse);
				if (!member.error) {
					let memberInfo = {
						...member,
						dinning_history: !dinningHistory.error ? dinningHistory : [],
					};
					setMember(memberInfo);
				} else {
					console.log('ERROR: ', member.data);
				}
				setLoadingClickedItem(false);
			})();
		}

		if (!isOpen && !isModalOpen) {
			setMember(null);
			setClickedItem(null);
		}
	}, [
		isOpen,
		setLoadingClickedItem,
		member,
		setMember,
		clickedItemId,
		setClickedItem,
		isModalOpen,
	]);

	useEffect(() => {
		getMember();
	}, [getMember]);

	return (
		<NobelDashboardModal isOpen={isOpen} onRequestClose={onRequestClose}>
			{
				loadingClickedItem && (
					<Loader />
				)
			}
			{
				!loadingClickedItem && (
					<div className={'UserMemberModal' + className}>
							{action === 'info' && (
								<>
									<h3 className="UserMemberModal__title">{ member && member?.is_spouse ? 'Member Spouse' : 'Member' } Info</h3>
									<MemberInfo
										setAction={setActionItem}
										onRequestClose={onRequestClose}
									/>
								</>
							)}
							{action === 'edit-member' && (
								<>
									<h3 className="UserMemberModal__title">Edit Member Info</h3>
									<EditMemberInfo onRequestClose={onRequestClose} />
								</>
							)}
							{action === 'edit-spouse' && (
								<>
									<h3 className="UserMemberModal__title">Edit Member Spouse Info</h3>
									<EditSpouseInfo onRequestClose={onRequestClose} />
								</>
							)}
                     {action === 'add-investor' && (
                        <>
                           <h3 className='UserMemberModal__title'>Add Member</h3>
                           <AddInvestors onRequestClose={onRequestClose} />
                        </>
                     )}
					</div>
				)
			}

		</NobelDashboardModal>
	);
}

export default UserMemberModal;
