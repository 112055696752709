export const STATES = [
	{ state: 'Alabama', id: 'AL', zip: [[35004, 36925]] },
	{ state: 'Alaska', id: 'AK', zip: [[99501, 99950]] },
	{ state: 'Arizona', id: 'AZ', zip: [[85001, 86556]] },
	{
		state: 'Arkansas',
		id: 'AR',
		zip: [
			[71601, 72959],
			[75502, 75502],
		],
	},
	{ state: 'California', id: 'CA', zip: [[90001, 96162]] },
	{ state: 'Colorado', id: 'CO', zip: [[80001, 81658]] },
	{
		state: 'Connecticut',
		id: 'CT',
		zip: [
			[6001, 6389],
			[6401, 6928],
		],
	},
	{ state: 'Delaware', id: 'DE', zip: [[19701, 19980]] },
	{ state: 'Florida', id: 'FL', zip: [[32004, 34997]] },
	{
		state: 'Georgia',
		id: 'GA',
		zip: [
			[30001, 31999],
			[39901, 39901],
		],
	},
	{ state: 'Hawaii', id: 'HI', zip: [[96701, 96898]] },
	{ state: 'Idaho', id: 'ID', zip: [[83201, 83876]] },
	{ state: 'Illinois', id: 'IL', zip: [[60001, 62999]] },
	{ state: 'Indiana', id: 'IN', zip: [[46001, 47997]] },
	{
		state: 'Iowa',
		id: 'IA',
		zip: [
			[50001, 52809],
			[68119, 68120],
		],
	},
	{ state: 'Kansas', id: 'KS', zip: [[66002, 67954]] },
	{ state: 'Kentucky', id: 'KY', zip: [[40003, 42788]] },
	{
		state: 'Louisiana',
		id: 'LA',
		zip: [
			[70001, 71232],
			[71234, 71497],
		],
	},
	{ state: 'Maine', id: 'ME', zip: [[3901, 4992]] },
	{
		state: 'Maryland',
		id: 'MD',
		zip: [
			[20331, 20331],
			[20335, 20797],
			[20812, 21930],
		],
	},
	{
		state: 'Massachusetts',
		id: 'MA',
		zip: [
			[1001, 2791],
			[5501, 5544],
		],
	},
	{ state: 'Michigan', id: 'MI', zip: [[48001, 49971]] },
	{ state: 'Minnesota', id: 'MN', zip: [[55001, 56763]] },
	{
		state: 'Mississippi',
		id: 'MS',
		zip: [
			[38601, 39776],
			[71233, 71233],
		],
	},
	{ state: 'Missouri', id: 'MO', zip: [[63001, 65899]] },
	{ state: 'Montana', id: 'MT', zip: [[59001, 59937]] },
	{
		state: 'Nebraska',
		id: 'NE',
		zip: [
			[68001, 68118],
			[68122, 69367],
		],
	},
	{ state: 'Nevada', id: 'NV', zip: [[88901, 89883]] },
	{ state: 'New Hampshire', id: 'NH', zip: [[3031, 3897]] },
	{ state: 'New Jersey', id: 'NJ', zip: [[7001, 8989]] },
	{ state: 'New Mexico', id: 'NM', zip: [[87001, 88441]] },
	{
		state: 'New York',
		id: 'NY',
		zip: [
			[6390, 6390],
			[10001, 14975],
		],
	},
	{ state: 'North Carolina', id: 'NC', zip: [[27006, 28909]] },
	{ state: 'North Dakota', id: 'ND', zip: [[58001, 58856]] },
	{ state: 'Ohio', id: 'OH', zip: [[43001, 45999]] },
	{
		state: 'Oklahoma',
		id: 'OK',
		zip: [
			[73001, 73199],
			[73401, 74966],
		],
	},
	{ state: 'Oregon', id: 'OR', zip: [[97001, 97920]] },
	{ state: 'Pennsylvania', id: 'PA', zip: [[15001, 19640]] },
	{ state: 'Rhode Island', id: 'RI', zip: [[2801, 2940]] },
	{ state: 'South Carolina', id: 'SC', zip: [[29001, 29948]] },
	{ state: 'South Dakota', id: 'SD', zip: [[57001, 57799]] },
	{ state: 'Tennessee', id: 'TN', zip: [[37010, 38589]] },
	{
		state: 'Texas',
		id: 'TX',
		zip: [
			[73301, 73301],
			[75001, 75501],
			[75503, 79999],
			[88510, 88589],
		],
	},
	{ state: 'Utah', id: 'UT', zip: [[84001, 84784]] },
	{
		state: 'Vermont',
		id: 'VT',
		zip: [
			[5001, 5495],
			[5601, 5907],
		],
	},
	{
		state: 'Virginia',
		id: 'VA',
		zip: [
			[20040, 20167],
			[22001, 24658],
		],
	},
	{ state: 'Washington', id: 'WA', zip: [[98001, 99403]] },
	{ state: 'West Virginia', id: 'WV', zip: [[24701, 26886]] },
	{ state: 'Wisconsin', id: 'WI', zip: [[53001, 54990]] },
	{ state: 'Wyoming', id: 'WY', zip: [[82001, 83128]] },
];

export const deleteMemberMessage = 'Are you sure you want to delete this member? This action cannot be undone and will permanently remove the member’s information from the dashboard. Please confirm to proceed with deletion.';

export const deleteMemberWarningMessage = 'WARNING - This member has a valid subcription active. This action cannot be undone and will permanently remove the member’s information from the dashboard. Please confirm to proceed with deletion.';
export const deleteAdminMessage = 'Are you sure you want to delete this admin? This action cannot be undone and will permanently remove the admin’s information from the dashboard. Please confirm to proceed with deletion.'

export const cancelMembershipMessage = 'Are you sure you want to cancel this person membership?';

export const somethingWentWrong = "Something went wrong, please try again."

export const deleteApplicationWarningMessage = 'Are you sure you want to delete this application? This action cannot be undone and the user will need to submit a new application to be considered for membership.';