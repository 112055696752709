import moment from 'moment';
import { axiosRequest } from '../../axiosRequest';
import { deformatPhoneNumber } from '../../../helpers/formattedPhoneNumber';

// url dashboard/spouse/:spouseId (put)

const adapterUpdateDataSpouseById = (prevData) => {
	let data = {};
	const oldMemberKeys = Object.keys(prevData);
	for (let i = 0; i < oldMemberKeys.length; i++) {
		const key = oldMemberKeys[i];
		if (prevData[key]?.change) {
			data[key] = {
				value: prevData[key].value,
				valid: prevData[key].valid,
			};
		}
	}

	let dataUpdate = {};
	const keysMember = Object.keys(data);
	for (let i = 0; i < keysMember.length; i++) {
		const key = keysMember[i];
		const value = data[key].value;

		if (
			(value !== undefined ||
				(typeof value === 'string' && value.trim() !== '')) &&
			data[key].valid !== undefined
		) {
			if (key === 'phoneNumber') {
				dataUpdate[key] = deformatPhoneNumber(value);
			} else if (key === 'birthDate') {
				dataUpdate[key] = moment(value).format('YYYY-MM-DD');
			} else {
				dataUpdate[key] = value;
			}
		}
	}

	return dataUpdate;
};

const adapterUpdatedSpouseResponse = ({ data, success }) => {
	if (success === false) return { error: true, data };

	return data;
};

export const updateSpouseById = async (id, formUpdateMember) => {
	const dataAdapter = adapterUpdateDataSpouseById(formUpdateMember);

	const options = {
		method: 'put',
		url: 'dashboard/spouse',
		params: id,
		information: dataAdapter,
	};

	const response = await axiosRequest(options);
	const updatedSpouse = adapterUpdatedSpouseResponse(response);

	return updatedSpouse;
};
