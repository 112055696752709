import { axiosRequest } from '../../axiosRequest';

import moment from 'moment';

// url dashboard/members
const adapterMembersResponse = ({ data, success }) => {
	if (success === false) return { error: true, data };

	const members = data.members.map((m) => {
		let member = {
			...m,
			tier: {
				...m?.tier,
				type: m?.tier?.type.replace('Member', ''),
			},
			birth_date:
				m?.birth_date &&
				moment(m?.birth_date, 'YYYY-MM-DD').format('MM/DD/YYYY'),
			member_since:
				m?.member_since &&
				moment(m?.member_since, 'YYYY-MM-DD').format('MM/DD/YYYY'),
			subscription: {
				...m?.subscription,
				canceled_at:
					m?.subscription?.canceled_at &&
					moment(m?.subscription?.canceled_at, 'YYYY-MM-DD').format(
						'MM/DD/YYYY',
					),
			},
		};
		return member;
	});

	return members;
};

export const getMemberList = async (active) => {
	let options = {
		method: 'get',
		url: 'dashboard/members',
		query: {
			active: active === 'active' ? 'true' : 'false',
		},
	};

	const response = await axiosRequest(options);
	const members = adapterMembersResponse(response);

	return members;
};
