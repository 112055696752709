import React from 'react';
import cn from 'classnames';
import './optionButton.scss';

function OptionButton({ active = false, children, ...rest }) {
	return (
		<button className={cn(['OptionButton', { active: active }])} {...rest}>
			{children}
		</button>
	);
}

export default OptionButton;
