import { axiosRequest } from '../../axiosRequest';

import moment from 'moment';
// url dashboard/admins/:adminId

const adapterAdminByIdResponse = ({ success, data }) => {
	if (success === false) return { error: true, data };

	const admin = {
		...data.admin,
		birth_date: moment(data.member?.birth_date).format('MM/DD/YYYY'),
	};

	return admin;
};

export const getAdminById = async (id) => {
	const options = {
		method: 'get',
		url: '/dashboard/admin',
		params: id,
	};

	const response = await axiosRequest(options);
	const adminById = adapterAdminByIdResponse(response);

	return adminById;
};
