import moment from 'moment';
import { axiosRequest } from 'services/axiosRequest';

const adapter = ({ data, success }) => {
	if (success === false) return { error: true, data };

   const member = {
		...data.member,
		birth_date:
			data.member?.birth_date &&
			moment(data.member?.birth_date, 'YYYY-MM-DD').format('MM/DD/YYYY'),
	};

	return member;
};

export const getVerifyMembership = async (hash) => {
	let options = {
		method: 'get',
		url: 'dashboard/member/verify-membership',
		params: hash,
	};

	const response = await axiosRequest(options);

	const member = adapter(response);

	return member;
};
