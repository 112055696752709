import React, { useCallback, useContext, useEffect, useState } from 'react';
import DashboardLayout from '../../components/dashboard/dashboardLayout';
import Button from '../../components/button';
import InputSearch from '../../components/inputs/Search';
import AdminList from './adminList';
import UserAdminModal from '../../components/modals/userAdminModal';
import useRefesh from '../../hooks/useRefresh';
import Loader from "../../components/loader";

import './admins.scss';
import { AppContext, AuthContext } from '../../context';
import { getAdminsList } from '../../services/api/admins/getAdmins';
import { userHasPermission } from 'helpers/hasPermission';
import { PERMISSIONS } from 'helpers/constants';
import { useNavigate } from 'react-router-dom';

function Admins() {
	const [isOpen, setIsOpen] = useState(false);

	const [section, setSection] = useState(null);

	const [clickedItemId, setClickedItemId] = useState(null);

	const [search, setSearch] = useState('');

	const { admins, setAdmins, setLoading, loading } = useContext(AppContext);
	const { user } = useContext(AuthContext);

	const [refetch, setRefetch] = useState(false);

	const navigate = useNavigate();
	useEffect(() => {
		if (user && !userHasPermission(user, PERMISSIONS.ACCESS_TO_ADMINS_PAGE)) {
			if (userHasPermission(user, PERMISSIONS.ACCESS_TO_MEMBERS_PAGE)) navigate('/members');
			if (!userHasPermission(user, PERMISSIONS.ACCESS_TO_MEMBERS_PAGE)) navigate('/access-denied');
		}
	},[user, navigate])

	const openAdminHandler = (e) => {
		e.preventDefault();
		setSection('add-admin');
		setIsOpen(true);
	};
	const closeHandler = () => {
		setIsOpen(!isOpen);
	};

	useRefesh();

	const getAdmins = useCallback(() => {
		if (!admins || refetch) {
			(async () => {
				setLoading(true);
				const adminList = await getAdminsList();
				if (!adminList.error) {
					setAdmins(adminList);
				} else {
					console.log('ERROR: ', adminList.data);
				}
				setLoading(false);
				setRefetch(false)
			})();
		}
	}, [admins, setAdmins, setLoading, refetch, setRefetch]);

	useEffect(() => {
		getAdmins();
	}, [getAdmins]);

	let data = { nodes: admins ? admins.admin_list : [] };

	data = {
		nodes: data.nodes.filter((item) => {
			const fullNameMatch = item.full_name
				.toLowerCase()
				.includes(search.toLocaleLowerCase());
			const birthDateMatch = item?.birth_date
				?.toLowerCase()
				.includes(search.toLocaleLowerCase());
			const emailMatch = item.email
				.toLowerCase()
				.includes(search.toLocaleLowerCase());
			const roleMatch = item?.role.name
				.toLowerCase()
				.includes(search.toLocaleLowerCase());
			return fullNameMatch || birthDateMatch || emailMatch || roleMatch;
		}),
	};

	const handleSearch = (event) => {
		setSearch(event.target.value);
	};

	return (
		<DashboardLayout
			layoutClass="Admins"
			layoutTitle="Admins"
			contentTotal={admins?.total_admin}>
			<div className="Admins__menu">
				<div className="Admins__menu__add">
					<Button
						displayLabel="Add Admin"
						size="full"
						buttonValidation={!isOpen}
						onClick={openAdminHandler}
					/>
				</div>
				<div className="Admins__menu__search">
					<InputSearch
						id="search"
						type="search"
						name="search"
						placeholder="Search"
						value={search}
						onChange={(e) => handleSearch(e)}
					/>
				</div>
			</div>
			<AdminList
				data={data}
				setSection={setSection}
				setIsOpen={setIsOpen}
				setClickedItemId={setClickedItemId}
				setRefetch={setRefetch}
			/>
			<UserAdminModal
				isOpen={isOpen}
				onRequestClose={closeHandler}
				section={section}
				clickedItemId={clickedItemId}
				setClickedItemId={setClickedItemId}
			/>
			{loading && <Loader />}
		</DashboardLayout>
	);
}

export default Admins;
