import Checkmark from '../../../assets/images/icons/checkmark.svg'
import './styles.scss';
export default function ThankYou(props) {
    const { hash } = props;
    return (
        <div className='ThankYou'>
            <div className='ThankYou__message'>
                <img src={Checkmark} alt="Payment Successful!" loading='eager' />
                <h2>PAYMENT SUCCESSFUL!</h2>
                <p>Thank you for your subscription payment!</p>
                <p>Your membership will be automatically assigned shortly.</p>
            </div>
            <div className='ThankYou__options'>
                <a href={`https://noble33.app.link/signup?hash=${hash}`} className="PaymentForMembership__btn PaymentForMembership__btn--alt"><span className="button__text">Go to the app</span></a>
            </div>
        </div>
    );
}
