import axios from './axios';

export const axiosRequest = async ({
	method,
	url,
	params,
	query,
	information = {},
	isJson,
	tokenFailedReload = false
}) => {
	try {
		if (!url) return;
		let options = {};
		let route = url;

		if (params) route = route + `/${params}`;
		if (query) {			
			options.params = query;
		}
		if (isJson)
			options.headers = {
				'Content-Type': 'application/json',
			};

		var response = null;

		switch (method) {
			case 'get':
				response = await axios[method](route, options);
				break;
			case 'delete':
				response = await axios[method](route, options);
				break;
			default:
				response = await axios[method](route, information, options);
		}

		const { data, status } = response;
		if (status > 199 && status < 300) {
			// Satisfactory answers (200–299)
			if (data.response_code === 0) {
				return { success: true, data };
			}
		} else if (status > 299 && status < 400) {
			// Redirections (300–399),
			if (data.response_code === 0) {
				return { success: false, data };
			}
		} else if (status > 399 && status < 500) {
			// Client errors 400–499),
			// Reload in case of token issues
			if(tokenFailedReload && status === 401 ) window.location.reload();
			if (data.response_code === 0) {
				return { success: false, data };
			}
		} else if (status > 499 && status < 600) {
			// Server (api) errors (500–599).
			if (data.response_code === 0) {
				return { success: false, data };
			}
		}

		return { success: false, data };
	} catch (error) {
		if (error.response) {
			return { success: false, data: error.message };
		} else if (error.request) {
			return { success: false, data: error.message };
		} else {
			return { success: false, data: error.message };
		}
	}
};
