import { axiosRequest } from '../../axiosRequest';

// url dashboard/validate-email/:resetCode
const adapterAdmiEmailnByResetCodeResponse = ({ success, data }) => {
	if (success === false) return { error: true, data };

	const { email } = data;

	return email;
};

export const getAdminEmailByResetCode = async (reset) => {
	const options = {
		method: 'get',
		url: '/dashboard/validate-email',
		params: reset,
	};

	const response = await axiosRequest(options);
	const email = adapterAdmiEmailnByResetCodeResponse(response);

	return email;
};
