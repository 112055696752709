import { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useNavigate } from 'react-router-dom';
// Data
import { getMembership } from '../../services/api/stripe/getMembership';
// Stripe
import { stripeOptions } from './stripeOptions';
// Context
import { AppContext } from '../../context/AppContext';
// Components
import LeftContentLayout from '../../components/leftContentLayout';
import CheckoutForm from './checkoutForm';
import ThankYou from './thankyou';
import Loader from '../../components/loader';
// Styles
import './styles.scss';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY);

export default function PaymentForMembership() {
	const navigate = useNavigate();
	const { hash } = useParams();
	const { setLoading } = useContext(AppContext);
	const [isLoading, setIsLoading] = useState(true);
	const [tier, setTier] = useState({});
	const [successfullPayment, setSuccessfullPayment] = useState(false);
	const [paymentIntent, setPaymentIntent] = useState(false);
	const [hasSpouse, setHasSpouse] = useState(false);
	const [hasSubscription, setHasSubscription] = useState(false);
	const [status, setStatus] = useState(false);

	const [isPayment, setIsPayment] = useState(false);

	const [isRequestSuccess, setIsRequestSuccess] = useState(false);

	const fetchMembership = useCallback(async () => {
		setLoading(true);
		try {
			const request = await getMembership({ hash });
			const { response_description } = request;
			if (response_description === 'Success') {
				if (request.status.name !== 'active') setIsPayment(true);
				request?.status && setStatus(request.status);
				request?.tier && setTier(request.tier);
				request?.with_spouse && setHasSpouse(request.with_spouse);
				request?.has_subscription && setHasSubscription(request.has_subscription);				
			} else throw new Error('Get membership error');
		} catch (error) {
			navigate('/login');
		}
		setLoading(false);
		setIsLoading(false);
		setIsRequestSuccess(true);
	}, [hash, navigate, setLoading]);

	useEffect(() => {
		if (isRequestSuccess === false) {
			fetchMembership();
		}
	}, [fetchMembership, isRequestSuccess]);

	useEffect(() => {
		if (status?.name === 'active') {
			if (!status?.description?.type || !status?.description) {
				setSuccessfullPayment(true);
			}
		}
	}, [status]);

	// TODO: Define after payment handler
	const handleSuccess = () => {
		setSuccessfullPayment(true);
		setPaymentIntent(paymentIntent);
	};
	return (
		<LeftContentLayout
			layoutTitle="Secure Payment"
			layoutClass="PaymentForMembership">
			{isLoading && <Loader />}

			{isPayment && !isLoading && !successfullPayment && (
				<>
					<Elements stripe={stripePromise} options={stripeOptions}>
						<CheckoutForm
							handleSuccess={handleSuccess}
							tier={tier}
							hasSpouse={hasSpouse}
							hasSubscription={hasSubscription}
						/>
					</Elements>
				</>
			)}

			{!isLoading && successfullPayment && (
				<ThankYou paymentIntent={paymentIntent} hash={hash} />
			)}
		</LeftContentLayout>
	);
}
