import React, { useState } from 'react';
import cn from 'classnames';
import UserApplicationModal from '../../../components/modals/userApplicationModal';
import './applicationsList.scss';

function ApplicationsList({ applications, ...rest }) {
	const [clickedItem, setClickedItem] = useState(null);
	const [isOpen, setIsOpen] = useState(false);
	const openApplicationHandler = (application) => {
		setClickedItem(application.id);
		setIsOpen(true);
	};
	const closeHandler = () => {
		setIsOpen(!isOpen);
		setClickedItem(null);
	};
	return (
		<div className="ApplicationsList" {...rest}>
			{applications &&
				applications.map((application) => (
					<div
						key={'applicationKey_' + application.id}
						className={cn(['ApplicationsList__item'], {
							active: clickedItem === application.id,
						})}
						onClick={() => openApplicationHandler(application)}>
						<div className="ApplicationsList__item--name">
							<h4>{application.fullname}</h4>
							<small>
								{application.tier_type}
								{application.referred_by && <span>Referral</span>}
							</small>
						</div>
						<div className="ApplicationsList__item--date">
							<h4>{application.update_status_date}</h4>
							<small>{application.application_date}</small>
						</div>
					</div>
				))}
			{!applications[0] && (
				<div className="ApplicationsList__noResults">
					No applications for selected filters
				</div>
			)}
			<UserApplicationModal
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				onRequestClose={closeHandler}
				applications={applications}
				clickedItemId={clickedItem}
			/>
		</div>
	);
}

export default ApplicationsList;
