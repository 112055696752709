import InputField from '../../../../inputField';
import NobleIcon from '../../../../nobleIcon';

import './inputReferredBy.scss';

function InputReferredBy({ ...rest }) {
	return (
		<div className="InputReferredBy">
			<InputField {...rest} />
			<div className="InputReferredBy__icon">
				<NobleIcon name="user" />
			</div>
		</div>
	);
}

export default InputReferredBy;
